<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg">
    <form *ngIf="ulFormLoaded" [formGroup]="ulForm" (ngSubmit)="saveULForm();">
        <div class="grid pr-4">
            <div class="col-6">
                <covered-form-text id="txtNm" placeholder="Name" formControlName="name"
                    [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-6">
                <covered-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId"
                    [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-6">
                <covered-form-text id="txtCss" placeholder="Css Class" formControlName="css"></covered-form-text>
            </div>
            <div class="col-3">
                <covered-input-number id="txtLimit" placeholder="Character Limit" formControlName="charLimit"
                    mode="decimal" [useGrouping]="false"></covered-input-number>
            </div>
            <div class="col-3 align-self-center">
                <p-checkbox [formControl]="$any(ulForm.controls['required'])" [binary]="true"
                    label="Required"></p-checkbox>
            </div>
            <div class="col-12 flex">
                <div class="w-2 align-self-center text-align-end">
                    <span class="cursor-pointer cds-text-color" (click)="addULItems()">Add List Items</span>
                </div>
                <div class="w-1 pl-2">
                    <covered-dropdown id="ddAddNum" placeholder="Num" [items]="addItemsCount" optionLabel="text"
                        optionValue="value" formControlName="addItmCnt">
                    </covered-dropdown>
                </div>
            </div>
            <div class="col-12 p-5">
                <table>
                    <ng-container formArrayName="listItems">
                        <tr *ngFor="let li of ulForm.controls.listItems.controls; index as i">
                            <ng-container [formGroupName]="i">
                                <td>LI #{{li.value.id}}</td>
                                <td>
                                    <covered-form-text id="txtLITxt{{i}}" formControlName="text"></covered-form-text>
                                </td>
                                <td class="pl-2">
                                    <i class="cursor-pointer pi pi-trash" (click)="deleteULItem(i)"></i>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </table>
            </div>
            <div class="col-12 flex flex-row-reverse">
                <covered-button id="btnS" label="Save" buttonType="submit"></covered-button>
                <covered-button id="btnCncl" label="Cancel" class="px-4 outline"
                    (buttonClick)="cancelForm()"></covered-button>
            </div>
        </div>
    </form>
</div>