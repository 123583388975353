import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CampaignNotificationsComponent } from './pages/campaign/campaign-notifications/campaign-notifications.component';
import { CampaignPageComponent } from './pages/campaign-page/campaign-page.component';
import { ComplianceDashboardComponent } from './pages/compliance/compliance-dashboard/compliance-dashboard.component';
import { CompliancePaymentsComponent } from './pages/compliance/compliance-payments/compliance-payments.component';
import { ComplianceStatementsComponent } from './pages/compliance/compliance-statements/compliance-statements.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { DecisionBlackListComponent } from './pages/decision/decision-black-list/decision-black-list.component';
import { DecisionCustomFieldsComponent } from './pages/decision/decision-custom-fields/decision-custom-fields.component';
import { DecisionDashboardComponent } from './pages/decision/decision-dashboard/decision-dashboard.component';
import { DecisionLineMatrixComponent } from './pages/decision/decision-line-matrix/decision-line-matrix.component';
import { DecisionOfferMatrixComponent } from './pages/decision/decision-offer-matrix/decision-offer-matrix.component';
import { DecisionReasonsComponent } from './pages/decision/decision-reasons/decision-reasons.component';
import { DecisionRulesComponent } from './pages/decision/decision-rules/decision-rules.component';
import { DecisionRulesetsComponent } from './pages/decision/decision-rulesets/decision-rulesets.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { OriginatorAddComponent } from './pages/originator/originator-add/originator-add.component';
import { OriginatorDashboardComponent } from './pages/originator/originator-dashboard/originator-dashboard.component';
import { OriginatorListComponent } from './pages/originator/originator-list/originator-list.component';
import { PartnerDashboardComponent } from './pages/partner/partner-dashboard/partner-dashboard.component';
import { PartnerDocsComponent } from './pages/partner/partner-docs/partner-docs.component';
import { PartnerFaqComponent } from './pages/partner/partner-faq/partner-faq.component';
import { PartnerInstructionsComponent } from './pages/partner/partner-instructions/partner-instructions.component';
import { PartnerLeadComponent } from './pages/partner/partner-lead/partner-lead.component';
import { ProviderDashboardComponent } from './pages/provider/provider-dashboard/provider-dashboard.component';
import { ProviderListPageComponent } from './pages/provider/provider-list-page/provider-list-page.component';
import { ReportPageComponent } from './pages/report-page/report-page.component';
import { SettlementReportPageComponent } from './pages/reports/settlement-report-page/settlement-report-page.component';
import { SystemPageComponent } from './pages/system-page/system-page.component';
import { UserDashboardComponent } from './pages/users/user-dashboard/user-dashboard.component';
import { UserRolesComponent } from './pages/users/user-roles/user-roles.component';
import { CampaignAlertsComponent } from './pages/campaign/campaign-alerts/campaign-alerts.component';
import { ProviderPricingComponent } from './pages/provider/provider-pricing/provider-pricing.component';
import { ProviderOfferLookupComponent } from './pages/provider/provider-offer-lookup/provider-offer-lookup.component';
import { NewPredefinedLeadComponent } from './pages/partner/new-predefined-lead/new-predefined-lead.component';
import { CampaignPagesComponent } from './pages/campaign/campaign-pages/campaign-pages.component';
import { CampaignSettingsComponent } from './pages/campaign/campaign-settings/campaign-settings.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { CampaignVersionsComponent } from './pages/campaign/campaign-versions/campaign-versions.component';
import { ProviderContentComponent } from './pages/provider/provider-content/provider-content.component';
import { EditDecisionLineMatrixComponent } from './pages/decision/edit-decision-line-matrix/edit-decision-line-matrix.component';
import { TestCssComponent } from './pages/test-css/test-css.component';
import { CampaignPageDataComponent } from './pages/campaign/campaign-page-data/campaign-page-data.component';
import { UserEditComponent } from './pages/users/user-edit/user-edit.component';
import { CreateUserPageComponent } from './pages/users/create-user-page/create-user-page.component';
import { UserRoleEditComponent } from './pages/users/user-role-edit/user-role-edit.component';
import { RoleEntitiesComponent } from './pages/users/role-entities/role-entities.component';
import { PastSettlementsComponent } from './pages/reports/past-settlements/past-settlements.component';
import { SettlementSummaryComponent } from './pages/reports/settlement-summary/settlement-summary.component';
import { CampaignSeedComponent } from './pages/campaign/campaign-seed/campaign-seed.component';
import { UserAccessGuard } from './guards/user-access.guard';

const routes: Routes = [
  { path: '', title: 'Covered Care Admin', component: HomePageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'not-authorized', title: 'Not Authorized', component: NotAuthorizedComponent, pathMatch: 'full' },
  { path: 'home', title: 'Covered Care Admin Home', component: HomePageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'provider', children: [
    { path: 'dashboard', title: 'Provider Dashboard', component: ProviderDashboardComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'list', title: 'List Providers', component: ProviderListPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'add', title: 'Add Provider', component: ProviderListPageComponent, data: {action: 'add'}, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'offer-lookup', title: 'Offer Lookup', component: ProviderOfferLookupComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'pricing', title: 'Provider Pricing', component: ProviderPricingComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'content', title: 'Provider Content', component: ProviderContentComponent, pathMatch: 'full', canActivate: [MsalGuard] }
  ] },
  { path: 'originator', children: [
    { path: 'dashboard', title: 'Originator Dashboard', component: OriginatorDashboardComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'list', title: 'Originator List', component: OriginatorListComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'add', title: 'Add Originator', component: OriginatorAddComponent, pathMatch: 'full', canActivate: [MsalGuard] }
  ] },
  { path: 'campaign', children: [
    { path: '', title: 'Campaigns', component: CampaignPageComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 5 } },
    { path: 'settings', title: 'Campaign Settings', component: CampaignSettingsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 5 } },
    { path: 'notifications', title: 'Campaign Notifications', component: CampaignNotificationsComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'alerts', title: 'Campaign Alerts', component: CampaignAlertsComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'pages', title: 'Campaign Pages', component: CampaignPagesComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'page/data', title: 'Campaign Page Data', component: CampaignPageDataComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'versions', title: 'Campaign Versions', component: CampaignVersionsComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'seed', title: 'Campaign Seeds', component: CampaignSeedComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 46 } }
  ] },
  { path: 'decision', children: [
    { path: 'dashboard', title: 'Decision Dashboard', component: DecisionDashboardComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 15 } },
    { path: 'rulesets', title: 'Decision Rulesets', component: DecisionRulesetsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 16 } },
    { path: 'rules', title: 'Decision Rules', component: DecisionRulesComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 17 } },
    { path: 'reasons', title: 'Decision Reasons', component: DecisionReasonsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 18 } },
    { path: 'line-matrixes', title: 'Decision Line Matrices', component: DecisionLineMatrixComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 19 } },
    { path: 'line-matrixes/edit', title: 'Decision Line Matrices', component: EditDecisionLineMatrixComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 19 } },
    { path: 'line-matrixes/edit/:matrixGuid', title: 'Decision Line Matrices', component: EditDecisionLineMatrixComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 19 } },
    { path: 'offer-matrixes', title: 'Decision Offer Matrices', component: DecisionOfferMatrixComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'black-list', title: 'Decision Black List', component: DecisionBlackListComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 20 } },
    { path: 'custom-fields', title: 'Decision Custom Fields', component: DecisionCustomFieldsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 35 } },
  ]},
  { path: 'report', children: [
    { path: '', title: 'Report', component: ReportPageComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 6 } },
    { path: 'settlement/:type', title: 'Report Settlement', component: SettlementReportPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'settlement-listing', title: 'Report Settlement', component: PastSettlementsComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'settlement-summary', title: 'Report Settlement Summary', component: SettlementSummaryComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 26 } },
  ]},
  { path: 'compliance', children: [ 
    { path: 'dashboard', title: 'Compliance Dashboard', component: ComplianceDashboardComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 7 } },
    { path: 'payments', title: 'Compliance Payments', component: CompliancePaymentsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 31 } },
    { path: 'statements', title: 'Compliance Statements', component: ComplianceStatementsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 32 } },
  ]},
  { path: 'users', children: [ 
    { path: 'dashboard', title: 'Users Dashboard', component: UserDashboardComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 12 } },
    { path: 'roles', title: 'User Roles', component: UserRolesComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 3 } },
    { path: 'edit-role/:userTypeId', title: 'Users Role Edit', component: UserRoleEditComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 4 } },
    { path: 'edit-role', title: 'Users Role Edit', component: UserRoleEditComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 4 } },
    { path: 'edit/:userGuid', title: 'User Edit', component: UserEditComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 11 } },
    { path: 'add', component: CreateUserPageComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 11 } },
    { path: 'role-entities', title: 'User Add', component: RoleEntitiesComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 36 } },
  ]},
  { path: 'system', title: 'System', component: SystemPageComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'partner', children: [
    { path: 'dashboard', title: 'Partner Dashboard', component: PartnerDashboardComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 39 } },
    { path: 'instructions', title: 'Partner Instructions', component: PartnerInstructionsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 40 } },
    { path: 'lead', title: 'Partner Lead', component: PartnerLeadComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 41 } },
    { path: 'edit-predefined', title: 'Partner Predefined Edit', component: NewPredefinedLeadComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'edit-predefined/:predefinedLeadId', title: 'Partner Predefined Edit', component: NewPredefinedLeadComponent, pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'docs', title: 'Partner Docs', component: PartnerDocsComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 42 } },
    { path: 'faq', title: 'Partner FAQ', component: PartnerFaqComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 43 } },
  ]},
  { path: 'dashboard', title: 'Dashboard', component: DashboardPageComponent, pathMatch: 'full', canActivate: [MsalGuard, UserAccessGuard], data: { pageId: 9 } },
  { path: 'css', title: 'CSS Test', component: TestCssComponent, pathMatch: 'full', canActivate: [MsalGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
