<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">Unable to load Providers.
</div>

<div *ngIf="!loading && !hasError && showList">

    <span class="text-2xl font-medium">Provider List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 flex flex-row-reverse">
                    <covered-button id="btnNewProvider" class="m-2" (buttonClick)="goToAddProvider()">
                        <span class="px-4">Add Provider</span>
                    </covered-button>
                </div>
                <div class="col-12 pt-4">
                    <p-table #dt [value]="providers" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                        [globalFilterFields]="filterFields" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
                        [(first)]="first" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rows]="100" [rowHover]="true" [columns]="columns">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <div class="flex justify-content-start">
                                    <covered-button id="btnExport" label="Download CSV" class="outlined"
                                        (buttonClick)="dt.paginator=false;dt.exportCSV();dt.paginator=true;"
                                        [disabled]="!providers.length">
                                    </covered-button>
                                </div>
                                <div class="flex flex-grow-1 justify-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" aria-hidden="true"></i>
                                        <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                            (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                                    <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                                        class="font-medium" scope="col">{{ col.header }}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                                </ng-template>
                                <th scope="col"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.customerName}}</td>
                                <td>{{rowData.template}}</td>
                                <td>{{rowData.relationship}}</td>
                                <td>{{rowData.website}}</td>
                                <td>{{rowData.addDate | date: 'MM/dd/yyyy hh:mm:ss a' }}</td>
                                <td>
                                    <p-badge [severity]="rowData.clarityLive ? 'success' : 'danger'"></p-badge>
                                </td>
                                <td>
                                    <div class="flex">
                                        <i class="pi pi-pencil cursor-pointer mr-4" title="Edit" aria-hidden="true" (click)="editProvider(rowData)"></i>
                                        <!-- <i class="pi pi-calendar cursor-pointer" title="Calendar" aria-hidden="true" (click)="providerCalendar(rowData)"></i> -->
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="7">No Providers found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

</div>


<div *ngIf="!loading && !hasError && showEdit">

    <span class="text-2xl font-medium">Provider List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card w-screen-fit p-4">
            <p-tabMenu [model]="tabMenu"></p-tabMenu>
            <div *ngIf="showProvider"><app-provider-info [provider]="selectedProvider"></app-provider-info></div>
            <div *ngIf="showBranding"><app-provider-branding [provider]="selectedProvider"></app-provider-branding></div>
            <div *ngIf="showSettlement"><app-provider-settlement [provider]="selectedProvider"></app-provider-settlement></div>
            <div *ngIf="showLocation"><app-provider-location [provider]="selectedProvider"></app-provider-location></div>
            <div *ngIf="showOffer"><app-provider-offer [provider]="selectedProvider"></app-provider-offer></div>
            <div *ngIf="showProcedure"><app-provider-procedure [provider]="selectedProvider"></app-provider-procedure></div>
            <div *ngIf="showCommunication"><app-provider-communication [provider]="selectedProvider"></app-provider-communication></div>
            <div *ngIf="showLoan"><app-provider-loan [provider]="selectedProvider"></app-provider-loan></div>
            <div *ngIf="showProcessor"><app-provider-processor [provider]="selectedProvider"></app-provider-processor></div>
            <div *ngIf="showOther"><app-provider-other [provider]="selectedProvider"></app-provider-other></div>            
        </div>
    </div>
</div>