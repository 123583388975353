import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { PortfolioItem } from 'src/app/models/customer-item';
import { DERule, DERuleBase, DERuleDataSource, DERuleDataSourceField, DERuleField, DERuleFunction, DERuleLookups, DERulePost, DERulesDataSources, DERuleSimple } from 'src/app/models/decision/rule';
import { DEReason } from 'src/app/models/decision/rule-reason';
import { DERuleFormGroup, DERuleRulesFormGroup, DERuleSetVarFormGroup, DERulesItemFormGroup } from 'src/app/models/form-groups';
import { AdminQueryParams } from 'src/app/models/query-params';
import { ApiService } from 'src/app/services/api.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { NavService, NavSettings } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-decision-rules',
  templateUrl: './decision-rules.component.html',
  styleUrls: ['./decision-rules.component.scss']
})
export class DecisionRulesComponent implements OnInit, AfterViewInit, OnDestroy {

  editPageId: number = 37;
  hasEditAccess: boolean = false;

  editFeatureCode: string = 'DECISION_ENGINE_RULE_EDIT';
  hasEditFeature: boolean = false;

  queryParams: AdminQueryParams[] = [];
  isInventory: boolean = true;
  editMode: boolean = true;
  isBreakRule: boolean = false;
  ruleSetGuid: string | null = null;
  subscriptions: Subscription[] = [];
  loading: boolean = true;
  hasError: boolean = false;
  showRules: boolean = false;
  showRuleEdit: boolean = false;
  showAddTag: boolean = false;
  lookups: DERuleLookups = {} as DERuleLookups;
  dataSourceFields: DERuleDataSourceField[] = [];
  ruleFieldSuggs: DERuleDataSourceField[] = [];
  ruleReasons: DEReason[] = [];
  ruleFields: DERuleField[] = [];
  resumeRules: DERuleSimple[] = [];

  tagDataSource: DERuleDataSource | null = null;
  tagDataField: DERuleDataSourceField | null = null;

  portfolio: PortfolioItem | null = null;
  campaign: Campaign | null = null;
  leftNavExpanded: boolean = false;
  bcItems: MenuItem[] = [];

  rules: DERule[] = [];
  first: number = 0;
  selectedRule: DERule | null = null;
  ruleFormLoaded: boolean = false;
  ruleForm: FormGroup<DERuleFormGroup> = {} as FormGroup;
  showComments: boolean = false;
  taggedFormControl: FormControl<any> = {} as FormControl;

  availOptions: any[] = [{ label: 'Available for Rule Sets', value: true }, { label: 'Unavailable for Rule Sets', value: false }];
  activeOptions: any[] = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }];

  private readonly pageBaseBc: string = "Decision Engine Inventory";
  private readonly pageAddBc: string = "Decision Engine Rule Add";
  private readonly pageEditBc: string = "Decision Engine Rule Edit";
  private readonly pageNavId: string = "DecisionEngine.Rules";

  private parser = new DOMParser();
  deRulesConfirmKey: string = 'DERulesConfirmKey';
  ruleEditTitle: string = this.pageAddBc;
  ruleEditCardTitle: string = 'Add';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private portfolioService: PortfolioService,
    private campaignService: CampaignService,
    private toastService: ToastService,
    private navService: NavService,
    private confirmService: ConfirmationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private breadCrumbService: BreadcrumbService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params && params.data) {
          let decoded = JSON.parse(atob(params.data)) as AdminQueryParams[];
          if (decoded && decoded.length > 0) {
            this.queryParams = decoded;
          }
        }
      }),
      this.navService.leftNavExpanded$.subscribe((isExpanded: boolean) => {
        this.leftNavExpanded = isExpanded;
      }),
      this.navService.leftNaveSelection$.subscribe((navObj: NavSettings) => {
        if (navObj.navId && navObj.navId == this.pageNavId) {
          this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
          window.scrollTo(0, 0);
        }
      }),
      this.portfolioService.portfolio$.subscribe(p => {
        this.portfolio = p;
        this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        window.scrollTo(0, 0);
        this.getCustomerLookups();
        this.getCustomerRuleReasons();
      }),
      this.campaignService.selectedCampaign$.subscribe(c => {
        this.campaign = c;
        this.getRuleFields();
      })
    );
      this.hasEditAccess = this.userService.hasPageAccess(this.editPageId);
      this.hasEditFeature = this.userService.hasWrite(this.editFeatureCode);

    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'Decision Engine', routerLink: ['/decision/dashboard'], command: () => { this.navService.setLeftNavSelection('DecisionEngine.Dashboard'); } },
      { label: this.pageBaseBc }
    ];
    this.loading = false;
    this.showRules = true;
  }

  ngAfterViewInit(): void {
    this.navService.setLeftNavSelection(this.pageNavId, null, false);
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  runQueryParams() {
    let ruleParam = this.queryParams.pop();
    if (ruleParam) {
      if (ruleParam.providerGuid != this.portfolio?.customerGuid) {
        this.queryParams = [];
        return;
      }
      if (ruleParam.page != this.route.component?.name) {
        this.queryParams.push(ruleParam);
        return;
      }
      if (ruleParam.data && ruleParam.data.ruleGuid) {
        this.getInventoryItem(ruleParam.data.ruleGuid)
        .then((ruleBase: DERuleBase) => {
          if (ruleBase == null) this.addRule();
          this.editMode = ruleParam?.data.editMode ?? true;
          this.isInventory = true;
          if (ruleParam?.data.ruleSetGuid) {
            this.ruleSetGuid = ruleParam.data.ruleSetGuid;
            this.isInventory = false;
            this.queryParams.push(ruleParam);
            let rule = this.convertBaseRule(ruleBase);
            this.editRule(rule);
          }
        }, (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Rule. See log for details.'
          }, 'center');
          console.error(err);
        });
      }
      else {    //    NEW RULE
        this.editMode = ruleParam?.data.editMode ?? true;
        this.isInventory = true;
        if (ruleParam?.data.ruleSetGuid) {
          this.ruleSetGuid = ruleParam.data.ruleSetGuid;
          this.isInventory = false;
          this.queryParams.push(ruleParam);
        }
        this.addRule();
      }
    }
  }

  convertBaseRule(rb: DERuleBase): DERule {
    let newRule: DERule = {...rb,
      ruleReasonName: '',
      ruleCategoryName: '',
      decisionType: '',
      rulesetsCount: null,
      rulesetsActiveCount: null,
      createDateUS: ''
    };
    return newRule;
  }

  getInventoryItem(ruleGuid: string) {
    return this.apiService.get(`decision/rule/${ruleGuid}/${this.portfolio?.customerGuid}`).toPromise();
  }

  getCustomerLookups() {
    let url: string = `decision/rule/lookups/${this.portfolio?.customerGuid}`;
    if (this.selectedRule) {
      url += `/${this.selectedRule.ruleGUID}`;
    }
    let lookSub = this.apiService.get(url)
      .subscribe({
        next: (lookups: DERuleLookups) => {
          this.lookups = lookups;
          this.lookups.categories.unshift({
            ruleCategoryID: 0,
            ruleCategoryName: 'Add New',
            customerID: -1
          });
          this.lookups.functions.unshift({
            functionID: -1,
            functionName: 'No Function',
            sqlCall: '',
            dataSourceID: null,
            applyToValue: false,
            resultDataTypeID: 0,
          });
          this.lookups.separatorColors.unshift({
            ruleColorID: 0,
            colorName: 'Default Color',
            backgroundColor: '',
            textColor: '',
            colorDescription: 'Default Color'
          });

          this.getCustomerRules();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get lookups for Customer. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { lookSub.unsubscribe(); }
      });
  }

  getRuleFields(attempts: number = 0) {
    if (!this.portfolio || !this.campaign) {
      attempts++;
      setTimeout(() => {
        this.getRuleFields(attempts);
      }, 100);
      return;
    }
    else if (attempts > 10) {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Provider and Campaign need to be selected in order to get Rule Fields.'
      }, 'center');
      return;
    }
    let url: string = `decision/rule/fields/${this.portfolio?.customerGuid}/${this.campaign?.campaignGuid}/-1/1`;

    let fieldSub = this.apiService.get(url)
      .subscribe({
        next: (fields: DERuleField[]) => {
          this.ruleFields = fields;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Rule Fields for Customer. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { fieldSub.unsubscribe(); }
      });
  }

  getCustomerRuleReasons() {
    let reasSub = this.apiService.get(`decision/reasons/${this.portfolio?.customerGuid}/-1`)
      .subscribe({
        next: (reasons: DEReason[]) => {
          this.ruleReasons = reasons.sort((a, b) => { return a.ruleReasonName.toLowerCase() > b.ruleReasonName.toLowerCase() ? 1 : -1; });;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Rule Reasons for Customer. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { reasSub.unsubscribe(); }
      });
  }

  getCustomerRules() {
    this.loading = true;
    let ruleSub = this.apiService.get(`decision/rule/list/${this.portfolio?.customerGuid}/-1`)
      .subscribe({
        next: (rules: DERule[]) => {
          this.rules = rules;
          if (this.queryParams.length > 0) {
            this.runQueryParams();
          }
          this.loading = false;
        },
        error: (err: any) => {
          this.rules = [];
          this.loading = false;
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer rule inventory. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { ruleSub.unsubscribe(); }
      });
  }

  getResumeRules() {
    let url: string = `decision/rule/resume/${this.portfolio?.customerGuid}/${this.selectedRule?.ruleGUID ?? uuidv4()}/${this.ruleSetGuid ?? uuidv4()}`;
    let resumeSub = this.apiService.get(url)
      .subscribe({
        next: (rules: DERuleSimple[]) => {
          this.resumeRules = rules;
          if (this.selectedRule) {
            this.resumeRules.unshift({
              ruleID: this.selectedRule.ruleID,
              ruleName: this.selectedRule.ruleName
            });
          }
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Resume Rules for Customer. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { resumeSub.unsubscribe(); }
      });
  }

  addRule() {
    this.prepRuleForm(this.pageAddBc);
    this.ruleFormLoaded = true;
    this.selectedRule = null;
    this.isBreakRule = this.ruleForm.value.isBreakRule ?? false;
    this.showRules = false;
    this.showRuleEdit = true;
  }

  editRule(rule: DERule) {
    this.selectedRule = rule;
    this.prepRuleForm(this.pageEditBc);
    this.patchRuleForm(rule);
    this.isBreakRule = this.ruleForm.value.isBreakRule ?? false;
    this.ruleFormLoaded = true;
    this.showRules = false;
    this.showRuleEdit = true;
    window.scrollTo(0, 0);
  }

  prepRuleForm(label: string) {
    this.getResumeRules();
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => {
        this.ruleFormLoaded = false;
        this.showRules = true;
        this.showRuleEdit = false;
        this.breadCrumbService.reduceBcList(this.bcItems, prevItem?.label);
      };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: label });
    this.ruleEditTitle = label;
    this.ruleEditCardTitle = label.replace('Decision Engine Rule ', '');
    this.createRuleForm();
  }

  createRuleForm() {
    this.ruleForm = this.formBuilder.group<DERuleFormGroup>({
      available: new FormControl<boolean>(false, { nonNullable: true }),
      active: new FormControl<boolean>(false, { nonNullable: true }),
      isBreakRule: new FormControl<boolean>(false, { nonNullable: true }),
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      category: new FormControl<number | null>(null, { nonNullable: true, validators: [Validators.required] }),
      categoryName: new FormControl<string>('', { nonNullable: true }),
      ruleReason: new FormControl<number | null>(null, { nonNullable: true }),
      comments: new FormControl<string | null>(null, { nonNullable: true }),
      description: new FormControl<string | null>(null, { nonNullable: true }),
      ruleColor: new FormControl<number | null>(null, { nonNullable: true }),
      retry: new FormControl<boolean>(false, { nonNullable: true }),
      retryInterval: new FormControl<number | null>(500, { nonNullable: true }),
      retryTimeout: new FormControl<number | null>(60, { nonNullable: true }),
      resumeRuleId: new FormControl<number | null>(null, { nonNullable: true }),
      successActionId: new FormControl<number | null>(null, { nonNullable: true }),
      successGoToId: new FormControl<number | null>(null, { nonNullable: true }),
      successTriggerId: new FormControl<number | null>(null, { nonNullable: true }),
      failActionId: new FormControl<number | null>(null, { nonNullable: true }),
      failGoToId: new FormControl<number | null>(null, { nonNullable: true }),
      failTriggerId: new FormControl<number | null>(null, { nonNullable: true }),
      successVars: this.formBuilder.array([
        this.formBuilder.group<DERuleSetVarFormGroup>({
          fieldId: new FormControl<number | null>(null, { nonNullable: true }),
          fieldValue: new FormControl<string>('', { nonNullable: true })
        })
      ]),
      failVars: this.formBuilder.array([
        this.formBuilder.group<DERuleSetVarFormGroup>({
          fieldId: new FormControl<number | null>(null, { nonNullable: true }),
          fieldValue: new FormControl<string>('', { nonNullable: true })
        })
      ]),
      rules: this.formBuilder.array([
        this.formBuilder.group<DERuleRulesFormGroup>({
          rule: this.formBuilder.group<DERulesItemFormGroup>({
            expression: new FormControl<string>('IF', { nonNullable: true }),
            dataSource: new FormControl<number | null>(null, { nonNullable: true }),
            dataField: new FormControl<DERuleDataSourceField | null>(null, { nonNullable: true }),
            function: new FormControl<number | null>(-1, { nonNullable: true }),
            dataType: new FormControl<number | null>(null, { nonNullable: true }),
            criteria: new FormControl<number | null>(null, { nonNullable: true }),
            value: new FormControl<string>('', { nonNullable: true })
          }),
          ors: this.formBuilder.array([
            this.formBuilder.group<DERulesItemFormGroup>({
              expression: new FormControl<string>('or', { nonNullable: true }),
              dataSource: new FormControl<number | null>(null, { nonNullable: true }),
              dataField: new FormControl<DERuleDataSourceField | null>(null, { nonNullable: true }),
              function: new FormControl<number | null>(-1, { nonNullable: true }),
              dataType: new FormControl<number | null>(null, { nonNullable: true }),
              criteria: new FormControl<number | null>(null, { nonNullable: true }),
              value: new FormControl<string>('', { nonNullable: true })
            })
          ])
        })
      ])
    });

    this.ruleForm.controls.rules.at(0).controls.ors.removeAt(0);
    this.showComments = false;
  }

  patchRuleForm(rule: DERule) {
    this.ruleForm.patchValue({
      available: rule.ruleSetAvailable ?? false,
      active: rule.ruleActive,
      isBreakRule: rule.isBreakRule,
      name: rule.ruleName,
      category: rule.ruleCategoryID,
      categoryName: rule.ruleCategoryName,
      ruleReason: rule.ruleReasonID,
      comments: rule.ruleComments,
      description: rule.ruleDescription,
      ruleColor: rule.ruleColorID,
      retry: rule.retry,
      retryInterval: rule.retryInterval,
      retryTimeout: rule.retryTimeout,
      successActionId: rule.successRuleActionID,
      successGoToId: rule.successGoToRuleID,
      successTriggerId: rule.successRuleTriggerID,
      failActionId: rule.failRuleActionID,
      failGoToId: rule.failGoToRuleID,
      failTriggerId: rule.failRuleTriggerID,
      resumeRuleId: rule.resumeRuleID
    });


    if (rule.successSetVariables != null) {
      this.ruleForm.controls.successVars.clear();
      const successVars: XMLDocument = this.parser.parseFromString(rule.successSetVariables, 'application/xml');
      successVars.querySelectorAll('set').forEach(val => {
        let fid = val.attributes.getNamedItem('fid');
        let fn = val.attributes.getNamedItem('fn');
        let fVal = val.innerHTML;
        this.ruleForm.controls.successVars.push(
          this.formBuilder.group<DERuleSetVarFormGroup>({
            fieldId: new FormControl<number | null>(fid ? +fid.value : null, { nonNullable: true }),
            fieldValue: new FormControl<string>(fVal ?? '', { nonNullable: true })
          })
        );
      });
    }

    if (rule.failSetVariables != null) {
      this.ruleForm.controls.failVars.clear();
      const failVars: XMLDocument = this.parser.parseFromString(rule.failSetVariables, 'application/xml');
      failVars.querySelectorAll('set').forEach(val => {
        let fid = val.attributes.getNamedItem('fid');
        let fn = val.attributes.getNamedItem('fn');
        let fVal = val.innerHTML;
        this.ruleForm.controls.failVars.push(
          this.formBuilder.group<DERuleSetVarFormGroup>({
            fieldId: new FormControl<number | null>(fid ? +fid.value : null, { nonNullable: true }),
            fieldValue: new FormControl<string>(fVal ?? '', { nonNullable: true })
          })
        );
      });
    }

    if (rule.ruleExpression != null) {
      this.ruleForm.controls.rules.clear();
      const ruleExp: XMLDocument = this.parser.parseFromString(rule.ruleExpression, 'application/xml');
      ruleExp.querySelectorAll('and').forEach(and => {
        let ors = and.querySelectorAll('or');
        if (ors.length >= 1) {
          let ifRule = ors[0];
          let dsId = ifRule.attributes.getNamedItem('dsID')?.value;
          let fieldId = ifRule.attributes.getNamedItem('fieldID')?.value;
          let field = this.lookups.dataSourceFields.find(f => f.fieldID == (fieldId ? +fieldId : 0));
          let fnId = ifRule.attributes.getNamedItem('fnID')?.value;
          let dtId = ifRule.attributes.getNamedItem('fdtID')?.value;
          let oId = ifRule.attributes.getNamedItem('oID')?.value;
          let newRule = this.formBuilder.group<DERuleRulesFormGroup>({
            rule: this.formBuilder.group<DERulesItemFormGroup>({
              expression: new FormControl<string>('', { nonNullable: true }),
              dataSource: new FormControl<number | null>(dsId ? +dsId : null, { nonNullable: true }),
              dataField: new FormControl<DERuleDataSourceField | null>(field ?? null, { nonNullable: true }),
              function: new FormControl<number | null>(fnId ? +fnId : -1, { nonNullable: true }),
              dataType: new FormControl<number | null>(dtId ? +dtId : null, { nonNullable: true }),
              criteria: new FormControl<number | null>(oId ? +oId : null, { nonNullable: true }),
              value: new FormControl<string>(ifRule.innerHTML, { nonNullable: true })
            }),
            ors: this.formBuilder.array([
              this.formBuilder.group<DERulesItemFormGroup>({
                expression: new FormControl<string>('or', { nonNullable: true }),
                dataSource: new FormControl<number | null>(null, { nonNullable: true }),
                dataField: new FormControl<DERuleDataSourceField | null>(null, { nonNullable: true }),
                function: new FormControl<number | null>(-1, { nonNullable: true }),
                dataType: new FormControl<number | null>(null, { nonNullable: true }),
                criteria: new FormControl<number | null>(null, { nonNullable: true }),
                value: new FormControl<string>('', { nonNullable: true })
              })
            ])
          });

          newRule.controls.ors.clear();
          for (let i = 1; i < ors.length; i++) {
            let orRule = ors[i];
            let ordsId = orRule.attributes.getNamedItem('dsID')?.value;
            let orfieldId = orRule.attributes.getNamedItem('fieldID')?.value;
            let orfield = this.lookups.dataSourceFields.find(f => f.fieldID == (orfieldId ? +orfieldId : 0));
            let orfnId = orRule.attributes.getNamedItem('fnID')?.value;
            let ordtId = orRule.attributes.getNamedItem('fdtID')?.value;
            let oroId = orRule.attributes.getNamedItem('oID')?.value;
            newRule.controls.ors.push(
              this.formBuilder.group<DERulesItemFormGroup>({
                expression: new FormControl<string>('or', { nonNullable: true }),
                dataSource: new FormControl<number | null>(ordsId ? +ordsId : null, { nonNullable: true }),
                dataField: new FormControl<DERuleDataSourceField | null>(orfield ?? null, { nonNullable: true }),
                function: new FormControl<number | null>(orfnId ? +orfnId : -1, { nonNullable: true }),
                dataType: new FormControl<number | null>(ordtId ? +ordtId : null, { nonNullable: true }),
                criteria: new FormControl<number | null>(oroId ? +oroId : null, { nonNullable: true }),
                value: new FormControl<string>(orRule.innerHTML, { nonNullable: true })
              }))
          }
          this.ruleForm.controls.rules.push(newRule);
        }
      });
    }

    this.showComments = rule.ruleComments != null && rule.ruleComments.length > 0;
  }

  addSetVarRow(arr: FormArray) {
    arr.push(this.formBuilder.group<DERuleSetVarFormGroup>({
      fieldId: new FormControl<number | null>(null, { nonNullable: true }),
      fieldValue: new FormControl<string>('', { nonNullable: true })
    })
    );
  }

  removeSetVarRow(arr: FormArray) {
    arr.removeAt(arr.length - 1);
  }

  showAddSetVar(arr: FormArray, i: number) {
    return i == arr.length - 1;
  }

  showDelSetVar(arr: FormArray, i: number) {
    return i == arr.length - 1 && arr.length > 1;
  }

  categorySelected(categoryId: number) {
    if (categoryId == 0) {
      this.ruleForm.controls.categoryName.setValidators([Validators.required]);
      this.ruleForm.controls.categoryName.reset();
    }
    else {
      this.ruleForm.controls.categoryName.clearValidators();
      this.ruleForm.controls.categoryName.reset();
    }
    this.ruleForm.updateValueAndValidity();
  }

  addTag(ctrl: FormControl) {
    this.taggedFormControl = ctrl;
    this.showAddTag = true;
  }

  getTagFields(dataSource: DERuleDataSource) {
    this.dataSourceFields = [];
    this.dataSourceFields = this.lookups.dataSourceFields.filter(d => d.dataSourceID == dataSource.dataSourceID);
  }

  saveTag() {
    this.taggedFormControl.setValue(`{![${this.tagDataSource?.dataSourceName}].[${this.tagDataField?.fieldName}]}`);
    this.showAddTag = false;
  }

  closeAddTag() {
    this.tagDataField = null;
    this.tagDataSource = null;
    this.showAddTag = false;
  }

  addNewRule() {
    this.ruleForm.controls.rules.push(
      this.formBuilder.group<DERuleRulesFormGroup>({
        rule: this.formBuilder.group<DERulesItemFormGroup>({
          expression: new FormControl<string>('and', { nonNullable: true }),
          dataSource: new FormControl<number | null>(null, { nonNullable: true }),
          dataField: new FormControl<DERuleDataSourceField | null>(null, { nonNullable: true }),
          function: new FormControl<number | null>(-1, { nonNullable: true }),
          dataType: new FormControl<number | null>(null, { nonNullable: true }),
          criteria: new FormControl<number | null>(null, { nonNullable: true }),
          value: new FormControl<string>('', { nonNullable: true })
        }),
        ors: this.formBuilder.array([
          this.formBuilder.group<DERulesItemFormGroup>({
            expression: new FormControl<string>('or', { nonNullable: true }),
            dataSource: new FormControl<number | null>(null, { nonNullable: true }),
            dataField: new FormControl<DERuleDataSourceField | null>(null, { nonNullable: true }),
            function: new FormControl<number | null>(-1, { nonNullable: true }),
            dataType: new FormControl<number | null>(null, { nonNullable: true }),
            criteria: new FormControl<number | null>(null, { nonNullable: true }),
            value: new FormControl<string>('', { nonNullable: true })
          })
        ])
      })
    );
    this.ruleForm.controls.rules.at(this.ruleForm.controls.rules.length - 1).controls.ors.removeAt(0);
  }

  searchDataFields = (search: any) => {
    this.ruleFieldSuggs = [];
    let targetId = search.originalEvent.target.id;
    let query = search.query.toLowerCase();
    let arrTgtId = targetId.replace('acRlDF', '').split('-');

    if (!arrTgtId || arrTgtId == null || arrTgtId.length == 0) {
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to determine Data Source for Field selection.'
      });
      return;
    }

    let rule = this.ruleForm.controls.rules.at(arrTgtId[0] as number);
    let dataSourceId = rule.controls.rule.controls.dataSource.value;

    if (arrTgtId.length == 2) {
      dataSourceId = rule.controls.ors.at(arrTgtId[1] as number).controls.dataSource.value;
    }

    if (!dataSourceId) {
      this.toastService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select a "Data Source" before searching for Field.'
      }, 'center');
      return;
    }

    if (query && query.length) {
      let tempResults = this.lookups.dataSourceFields.filter(d => d.dataSourceID == dataSourceId && d.fieldName.toLowerCase().includes(query));
      if (tempResults && tempResults.length) {
        this.ruleFieldSuggs = tempResults;
      }
    }
  }

  getDataRuleFunctions(level1: number, level2: number | null): DERuleFunction[] {
    let group = this.ruleForm.controls.rules.at(level1);
    let datasourceId = group.controls.rule.controls.dataSource.value;
    if (level2) {
      let sub = group.controls.ors.at(level2);
      datasourceId = sub.controls.dataSource.value;
    }
    return this.lookups.functions.filter(f => f.dataSourceID == null || f.dataSourceID == datasourceId);
  }

  addOr(ruleIndex: number) {
    this.ruleForm.controls.rules.at(ruleIndex).controls.ors.push(
      this.formBuilder.group<DERulesItemFormGroup>({
        expression: new FormControl<string>('or', { nonNullable: true }),
        dataSource: new FormControl<number | null>(null, { nonNullable: true }),
        dataField: new FormControl<DERuleDataSourceField | null>(null, { nonNullable: true }),
        function: new FormControl<number | null>(-1, { nonNullable: true }),
        dataType: new FormControl<number | null>(null, { nonNullable: true }),
        criteria: new FormControl<number | null>(null, { nonNullable: true }),
        value: new FormControl<string>('', { nonNullable: true })
      })
    );
  }

  delRule(level1: number, level2: number | null) {
    let rule = this.ruleForm.controls.rules.at(level1);
    if (level2 != null) {
      rule.controls.ors.removeAt(level2);
      return;
    }
    if (rule.controls.ors.length == 0) {
      this.ruleForm.controls.rules.removeAt(level1);
      if (this.ruleForm.controls.rules.length == 0) this.addNewRule();
      return;
    }

    let tempNewRule = rule.controls.ors.at(0);
    let tempNewOrsCtrl = rule.controls.ors;
    tempNewOrsCtrl.removeAt(0);
    let tempNewOrs = tempNewOrsCtrl.value;

    this.ruleForm.controls.rules.at(level1).controls.rule.patchValue({
      expression: level1 == 0 ? 'If' : 'and',
      dataSource: tempNewRule.value.dataSource,
      dataField: tempNewRule.value.dataField,
      function: tempNewRule.value.function,
      dataType: tempNewRule.value.dataType,
      criteria: tempNewRule.value.criteria,
      value: tempNewRule.value.value
    });
    this.ruleForm.controls.rules.at(level1).controls.ors.patchValue(tempNewOrs);
    this.ruleForm.updateValueAndValidity();
  }

  saveRule() {

    let rule: DERulePost = {} as DERulePost;
    rule.campaignID = this.selectedRule?.campaignID ?? -1;
    rule.ruleGUID = this.selectedRule ? this.selectedRule.ruleGUID : uuidv4();
    rule.ruleSetGuid = this.ruleSetGuid;
    rule.isBreakRule = this.isInventory ? false : this.ruleForm.value.isBreakRule ?? false;
    rule.ruleSetAvailable = this.isInventory ? this.ruleForm.value.available ?? false : false;
    rule.ruleActive = !this.isInventory ? this.ruleForm.value.active ?? false : true;
    rule.ruleName = this.ruleForm.value.name ?? '';
    rule.ruleReasonID = this.ruleForm.value.ruleReason ?? -1;
    rule.failGoToRuleID = !this.isInventory ? this.ruleForm.value.failGoToId ?? -1 : -1;
    rule.failRuleTriggerID = !this.isInventory ? this.ruleForm.value.failTriggerId ?? -1 : -1;
    rule.failSetVariables = this.createXMLDocument('fail');
    rule.successGoToRuleID = !this.isInventory ? this.ruleForm.value.successGoToId ?? -1 : -1;
    rule.successRuleTriggerID = !this.isInventory ? this.ruleForm.value.successTriggerId ?? -1 : -1;
    rule.successSetVariables = this.createXMLDocument('success');
    rule.ruleComments = this.ruleForm.value.comments ?? '';
    rule.retry = this.ruleForm.value.retry ?? false;
    rule.retryInterval = this.ruleForm.value.retryInterval ?? 0;
    rule.retryTimeout = this.ruleForm.value.retryTimeout ?? 0;
    rule.resumeRuleID = this.ruleForm.value.resumeRuleId ?? -1;
    rule.ruleCategoryName = this.ruleForm.value.category == 0 ? this.ruleForm.value.categoryName ?? '' : '';
    
    // isbreak rule
    let defaultExpression = '<conditions><and><or fieldID=\"\" fieldName=\"\" fnID=\"\" oID=\"\" dsID=\"1\" hidden=\"0\"></or></and></conditions>';
    rule.ruleExpression = this.ruleForm.value.isBreakRule ? defaultExpression : this.createXMLDocument('rule');
    rule.ruleDescription = this.ruleForm.value.isBreakRule ? this.ruleForm.value.description ?? '' : '';
    rule.failRuleActionID = this.ruleForm.value.isBreakRule ? 1 : !this.isInventory ? this.ruleForm.value.failActionId ?? -1 : -1;
    rule.successRuleActionID = this.ruleForm.value.isBreakRule ? 1 : !this.isInventory ? this.ruleForm.value.successActionId ?? -1 : -1;
    rule.ruleCategoryID = this.ruleForm.value.isBreakRule ? -1 : this.ruleForm.value.category ?? -1;
    rule.ruleColorID = this.ruleForm.value.isBreakRule ? -1 : this.ruleForm.value.ruleColor ?? -1;


    let postSub = this.apiService.post(`decision/rule/${this.portfolio?.customerGuid}`, rule)
    .subscribe({
      next: () => { 
        if (this.editMode && this.ruleSetGuid?.length) {
          let compName = this.route.component?.name ?? '';
          if (this.queryParams && this.queryParams.filter(p => p.page != compName).length > 0) {
            this.goToQueryParamPage();
            return;
          }
        }
        this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
        this.getCustomerRules();
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Rule successfully saved.'
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Rule. See logs for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { postSub.unsubscribe(); }
    });
  }

  createXMLDocument(type: string): string | null {
    if (type === 'fail' || type == 'success') {
      let list = type === 'success' ? this.ruleForm.value.successVars : this.ruleForm.value.failVars;
      if (!list || list.length == 0) return null;
      if (list && list.length == 1 && list[0].fieldId == null) return null;
      let docXML = document.implementation.createDocument('', 'fields');
      let fields = docXML.querySelector('fields');
      list.forEach(field => {
        let funcName = this.ruleFields.find(r => r.fieldID == field.fieldId);
        let childNode = docXML.createElement('set');
        childNode.setAttribute('fid', (field.fieldId ?? '').toString());
        childNode.setAttribute('fn', funcName ? funcName.fieldName : '');
        childNode.innerHTML = field.fieldValue ?? '';
        fields?.appendChild(childNode);
      });

      const serializer = new XMLSerializer();
      return serializer.serializeToString(docXML);
    }
    else if (type === 'rule') {
      let list = this.ruleForm.value.rules;
      if (!list || list.length == 0) return null;
      if (list && list.length == 1 && (!list[0].rule || !list[0].rule.dataSource)) return null;

      let docXML = document.implementation.createDocument('', 'conditions');
      let conditions = docXML.querySelector('conditions');
      list.forEach(and => {
        if (and.rule) {
          let childNode = docXML.createElement('and');
          let cNode = docXML.createElement('or');
          cNode.setAttribute('fieldID', and.rule.dataField?.fieldID.toString() ?? '');
          cNode.setAttribute('fieldName', and.rule.dataField?.fieldName ?? '');
          cNode.setAttribute('fnID', (and.rule.function && and.rule.function > 0) ? and.rule.function?.toString() : '');
          cNode.setAttribute('oID', and.rule.criteria?.toString() ?? '');
          cNode.setAttribute('dsID', and.rule.dataSource?.toString() ?? '');
          cNode.setAttribute('fdtID', and.rule.dataType?.toString() ?? '');
          cNode.innerHTML = and.rule.value ?? '';
          childNode.appendChild(cNode);

          if (and.ors && and.ors.length > 0) {
            and.ors.forEach(or => {
              let grandChildNode = docXML.createElement('or');
              grandChildNode.setAttribute('fieldID', or.dataField?.fieldID.toString() ?? '');
              grandChildNode.setAttribute('fieldName', or.dataField?.fieldName ?? '');
              grandChildNode.setAttribute('fnID', or.function?.toString() ?? '');
              grandChildNode.setAttribute('oID', or.criteria?.toString() ?? '');
              grandChildNode.setAttribute('dsID', or.dataSource?.toString() ?? '');
              grandChildNode.setAttribute('fdtID', or.dataType?.toString() ?? '');
              grandChildNode.innerHTML = or.value ?? '';
              childNode.appendChild(grandChildNode);
            });
          }
          conditions?.appendChild(childNode);
        }
      });
      const serializer = new XMLSerializer();
      return serializer.serializeToString(docXML);
    }
    else return null;
  }

  confirmDeleteRule(rule: DERule) {
    this.confirmService.confirm({
      key: this.deRulesConfirmKey,
      message: `<p>Are you sure you want to delete the Rule <strong>"${rule.ruleName}"?</strong></p><br/><strong class="text-base cds-error-text">NOTE: If you delete this rule, undo will no longer be available.</strong>`,
      header: 'Delete Rule',
      icon: 'pi pi-exclamation-circle de-copy-row-icon',
      accept: () => {
        this.deleteRule(rule);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Rule has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteRule(rule: DERule) {
    let body = {
      customerGuid: this.portfolio?.customerGuid,
      ruleGuid: rule.ruleGUID,
      deleteChildren: false
    };

    let delSub = this.apiService.post('decision/rule/delete', body)
      .subscribe({
        next: () => {
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rule has been removed.'
          });
          this.getCustomerRules();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to remove Rule. See log for details.'
          });
          console.error(err);
        },
        complete: () => { delSub.unsubscribe(); }
      });
  }

  cancelRule() {
    let compName = this.route.component?.name ?? '';
    if (this.queryParams && this.queryParams.filter(p => p.page != compName).length > 0) {
      this.goToQueryParamPage();
    }
    else {
      this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
      window.scrollTo(0, 0);
    }
  }

  goToQueryParamPage() {
    let compName = this.route.component?.name ?? '';
    let pageAndParams = this.queryParams.pop();
    if (pageAndParams && pageAndParams.page == compName) {
      this.goToQueryParamPage();
      return;
    }

    if (pageAndParams) {
      this.router.navigate([pageAndParams.route], {
        queryParams: {
          data: btoa(JSON.stringify([pageAndParams]))
        }
      });
    }
    else {
      this.breadCrumbService.findExecuteBcCommand(this.bcItems, this.pageBaseBc);
      window.scrollTo(0, 0);
    }
  }

  editReason() {
    let exists = this.queryParams.find(q => q.page === this.route.component?.name);
    if (exists) {
      let paramIndex = this.queryParams.indexOf(exists);
      exists.route = this.router.url.split('?')[0];
      exists.providerGuid = this.portfolio?.customerGuid ?? '';
      exists.data = {
        ruleGuid: this.selectedRule?.ruleGUID ?? "-1",
        isInventory: this.isInventory,
        editMode: this.editMode
      };
      this.queryParams.splice(paramIndex, 1, exists);
    }
    else {
      this.queryParams.push({
        page: this.route.component?.name ?? '',
        route: this.router.url.split('?')[0],
        providerGuid: this.portfolio?.customerGuid ?? '',
        campaignGuid: '',
        data: {
          ruleGuid: this.selectedRule?.ruleGUID ?? "-1",
          isInventory: this.isInventory,
          editMode: this.editMode
        }
      });
    }

    let ruleReason = this.ruleReasons.find(rr => rr.ruleReasonID == this.ruleForm.value.ruleReason);
    if (ruleReason) {
      this.queryParams.push({
        page: 'DecisionReasonsComponent',
        route: '/decison/reasons',
        providerGuid: this.portfolio?.customerGuid ?? '',
        campaignGuid: '',
        data: {
          ruleReasonGuid: ruleReason.ruleReasonGUID,
          isInventory: this.isInventory,
          editMode: this.editMode
        }
      });
    }

    this.router.navigate([`/decision/reasons`], {
      queryParams: {
        data: btoa(JSON.stringify(this.queryParams))
      }
    });
  }

  breakRuleChange(event: any) {
    this.isBreakRule = event.checked;
    this.ruleForm.controls.active.setValue(!this.isBreakRule);

    if (this.isBreakRule) {
      this.ruleForm.controls.category.clearValidators();
      this.ruleForm.controls.category.reset();
      this.ruleForm.controls.categoryName.clearValidators();
      this.ruleForm.controls.categoryName.reset();
    }
    else {
      this.ruleForm.controls.category.addValidators([Validators.required]);
      this.categorySelected(this.ruleForm.value.category ?? -1);
    }

    this.ruleForm.updateValueAndValidity();
  }

  getSampleColors() {
    let ruleColorId = this.ruleForm.value.ruleColor ?? 0;
    let retClass: string = '';
    switch (ruleColorId) {
      case 1:
        retClass = 'dark-grey-white';
        break;
      case 2:
        retClass = 'light-grey-red';
        break;
      default:
        break;
    }
    return retClass;
  }





  testFormValidity() {
    this.testValidators(this.ruleForm);
    console.log('Test Form Validity complete');
  }

  testValidators(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key) as FormControl | FormGroup | FormArray;
      if (control instanceof FormControl) {
        if (control.errors) {
          for (const err in control.errors) {
            console.log(`${key} has error: ${err}`);
          }
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.testValidators(control);
      } else {
        console.log("ignoring control: ", key)
      }
    });
  }
}