import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TableColumn } from '@covered/covered-ui';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { CSSTestFormGroup } from 'src/app/models/form-groups';
import { NavService } from 'src/app/services/nav-service.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-test-css',
  templateUrl: './test-css.component.html',
  styleUrls: ['./test-css.component.scss']
})
export class TestCssComponent implements OnInit {


  bcItems: MenuItem[] = [];
  columns: TableColumn[] = [];
  filterFields: string[] = [];
  testData: CSSTestDataObj[] =[];
  cities: CSSCityObj[] = [];
  jobs: CSSJobObj[] = [];
  jobSuggs: CSSJobObj[] = [];

  rblTest: boolean = false;
  toggleOptions: any[] = [{label: 'Yes', value: true}, {label: 'No', value: false}];
  selBtnTest: boolean = true;
  inputSwTest: boolean = true;

  cssForm: FormGroup<CSSTestFormGroup> = {} as FormGroup;

  constructor(
    private navService: NavService,    
    private confirmService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.navService.setLeftNavSelection('Campaign', null, false);
    this.bcItems = [
      { label: 'Home', routerLink: ['/home'], command: () => { this.navService.setLeftNavSelection(null); } },
      { label: 'CSS TEST', routerLink: ['/css'], command: () => { this.navService.setLeftNavSelection('Campaign', null, false); } },
      { label:  'breadcrumbs'}
    ];
    this.setupColumns();
    this.loadData();
  }

  buildForm() {
    this.cssForm = new FormGroup<CSSTestFormGroup>({
      firstName: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      lastName: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      city: new FormControl<string|null>(null, {nonNullable: true, validators: [Validators.required]}),
      job: new FormControl<CSSJobObj|null>(null, {nonNullable: true, validators: [Validators.required]})
    })
  }

  loadData() {
    this.testData = [
      {
        firstName: 'Alan', lastName: 'McAfee', city: 'Garland', job: 'Engineer Lead'
      },
      {
        firstName: 'Dave', lastName: 'Fisher', city: 'Rowlett', job: 'SQL Sleuth'
      }
    ];
    this.cities = [
      { cityName: 'Dallas', cityId: 1 },
      { cityName: 'Garland', cityId: 2 },
      { cityName: 'Plano', cityId: 3 },
      { cityName: 'Richardson', cityId: 4 },
      { cityName: 'Rockwall', cityId: 5 },
      { cityName: 'Rowlett', cityId: 6 },
      { cityName: 'Ft. Worth', cityId: 7 },
      { cityName: 'Graham', cityId: 8 },
      { cityName: 'South Bend', cityId: 9 },
      { cityName: 'North Richland Hills', cityId: 10 },
    ];
    this.jobs = [
      { jobName: 'CEO', jobId: 1, jobSalary: 1000000 },
      { jobName: 'CTO', jobId: 2, jobSalary: 500000 },
      { jobName: 'Software Manager', jobId: 3, jobSalary: 250000 },
      { jobName: 'Software Lead', jobId: 4, jobSalary: 150000 },
      { jobName: 'Software Engineer', jobId: 5, jobSalary: 125000 },
      { jobName: 'Database Admin', jobId: 6, jobSalary: 200000 },
      { jobName: 'Database Engineer', jobId: 7, jobSalary: 150000 },
      { jobName: 'Marketing Manager', jobId: 8, jobSalary: 150000 },
      { jobName: 'Marketing Associate', jobId: 9, jobSalary: 90000 },
      { jobName: 'Database Architect', jobId: 10, jobSalary: 200000 },
      { jobName: 'Customer Service Manager', jobId: 11, jobSalary: 200000 },
      { jobName: 'Customer Service Representative', jobId: 12, jobSalary: 75000 },
      { jobName: 'Reporting Manager', jobId: 13, jobSalary: 140000 },
      { jobName: 'Reporting Associate', jobId: 14, jobSalary: 125000 },
      { jobName: 'Provider Relations Manager', jobId: 15, jobSalary: 160000 },
      { jobName: 'Provider Relations Lead', jobId: 16, jobSalary: 140000 },
      { jobName: 'Provider Relations Associate', jobId: 17, jobSalary: 110000 },
      { jobName: 'Dev Ops Architect', jobId: 18, jobSalary: 150000 },
      { jobName: 'Dev Ops Manager', jobId: 19, jobSalary: 125000 },
      { jobName: 'Dev Ops Specialist', jobId: 20, jobSalary: 100000 },
      { jobName: 'CFO', jobId: 21, jobSalary: 750000 },
      { jobName: 'CIO', jobId: 22, jobSalary: 275000 },
    ]
  }



  setupColumns() {
    this.columns = [    
      {
        field: 'firstName',
        header: 'First Name',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },
      {
        field: 'lastName',
        header: 'Last Name',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },
      {
        field: 'city',
        header: 'City',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      },
      {
        field: 'job',
        header: 'Occupation',
        show: true,
        sortable: true,
        clickable: false, 
        class: null
      }
    ];
    this.filterFields = this.columns.filter(c => c.show).map(m => { return m.field; });
  }

  editRecord(obj: CSSTestDataObj) { 
    this.toastService.underConstruction();
  }

  confirmDelete(obj: CSSTestDataObj) { 
    this.confirmService.confirm({
      key: 'CSS-Test-Confirm-Key',
      message: 'Are you sure that you want to delete this item?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.toastService.add({ severity: 'success', sticky: true, summary: 'Success', detail: 'Example success toast notification.' });
        // this.deleteRecord(row);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'error', sticky: true, summary: 'Declined', detail: 'Example error toast notification.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', sticky: true, summary: 'Cancelled', detail: 'Example warn toast notification.' });
            break;
        }
      }
    });
    
  }


  searchJobs = (search: any) => {
    this.jobSuggs = [];
    let query = search.query.toLowerCase();
    if (query && query.length) {
      let tempResults = this.jobs.filter(d => d.jobName.toLowerCase().includes(query));
      if (tempResults && tempResults.length) {
        this.jobSuggs = tempResults;
      }
    }
  }

  saveEmployee() {
    this.testData.push({ firstName: this.cssForm.value.firstName ?? '', lastName: this.cssForm.value.lastName ?? '', city: this.cssForm.value.city ?? '', job: this.cssForm.value.job?.jobName ?? '' });
    this.clearEmployee();
  }

  clearEmployee() {
    this.cssForm.reset();
  }

  textBtnClicked() {
    this.toastService.add({severity: 'success', detail: 'Text Button Clicked!'}, 'center');
  }

  hyperLnkClicked() {
    this.toastService.add({severity: 'success', detail: 'Hyper link clicked!'}, 'center');
  }

  showSuccess() {
    this.toastService.add({severity: 'success', sticky: true, detail: 'Success Toast!'}, 'center');
  }

  showWarn() {
    this.toastService.add({severity: 'warn', sticky: true, detail: 'Warn Toast!'}, 'center');
  }

  showError() {
    this.toastService.add({severity: 'error', sticky: true, detail: 'Error Toast!'}, 'center');
  }

  showInfo() {
    this.toastService.add({severity: 'info', sticky: true, detail: 'Info Toast!'}, 'center');
  }
}

export class CSSTestDataObj {
  firstName: string = '';
  lastName: string = '';
  city: string = '';
  job: string = '';
}

export class CSSCityObj {
  cityName: string = '';
  cityId: number = 0;
}

export class CSSJobObj {
  jobName: string = '';
  jobId: number = 0;
  jobSalary: number = 0;
}