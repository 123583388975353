import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoveredAutocompleteComponent } from '@covered/covered-ui';
import { DERuleField } from 'src/app/models/decision/rule';
import { PgDataLabelFormGroup } from 'src/app/models/form-groups';
import { PageDataFunctionLk, PageDataLabel } from 'src/app/models/page-data';

@Component({
  selector: 'app-page-data-label',
  templateUrl: './page-data-label.component.html',
  styleUrls: ['./page-data-label.component.scss']
})
export class PageDataLabelComponent implements OnInit {

  @Input() data: PageDataLabel | null | undefined = null;
  @Input() pageFields: DERuleField[] = [];
  @Input() functions: PageDataFunctionLk[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();

  replaceResults: DERuleField[] = [];
  labelForm: FormGroup<PgDataLabelFormGroup> = {} as FormGroup;
  labelFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;
  selectedFunction: PageDataFunctionLk | null = null;

  @ViewChild('acTags') acTags: CoveredAutocompleteComponent = {} as CoveredAutocompleteComponent;
  constructor() { }

  ngOnInit(): void {
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initForm();
    this.emptyDataMsg = false;
    this.labelFormLoaded = true;
  }


  initForm() {
    this.labelForm = new FormGroup<PgDataLabelFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      label: new FormControl<string>(this.data?.label ?? '', { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),
      charLimit: new FormControl<string>(this.data?.mC ?? '', { nonNullable: true }),
      required: new FormControl<boolean>(this.data?.req == "1", { nonNullable: true })
    });
  }

  saveLabelForm() {
    if (this.data) {
      this.data.alias = this.labelForm.value.name ?? this.data.alias;
      this.data.name = this.labelForm.value.objectId ?? this.data.name;
      this.data.versionId = this.labelForm.value.version ?? this.data.versionId;
      this.data.label = this.labelForm.value.label ?? this.data.label;
      this.data.class = this.labelForm.value.css ?? this.data.class;
      this.data.mC = this.labelForm.value.charLimit ?? this.data.mC;
      this.data.req = this.labelForm.value.required ? '1' : '0';
    }
    let saveObj = {
      element: 'label',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  searchTags(event: any) {
    let search = this.pageFields.filter(pf => pf.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.replaceResults = search;
    else this.replaceResults = [];
  }

  insertTag() {
    let tagTxt = this.acTags.value?.fieldName ?? '';
    if (tagTxt.length) {
      let newVal = this.labelForm.value.label + `{!${tagTxt}}`;
      this.labelForm.patchValue({
        label: newVal
      });
    }
    this.acTags.value = null;
    this.acTags.innerValue = null;
  }

  functionSelected(funcId: number) {
    this.selectedFunction = this.functions.find(f => f.pageFunctionID == funcId) ?? null;
  }

  insertFunc() {
    if (this.selectedFunction) {
      let newVal = this.labelForm.value.label + `.${this.selectedFunction.pageFunctionName}()`;
      this.labelForm.patchValue({
        label: newVal
      });      
    }
  }

}
