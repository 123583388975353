<div *ngIf="!guidsLoaded">
    <div class="text-3xl text-red-500">
        You MUST select a Provider and Campaign before accessing this page.
    </div>
</div>

<div *ngIf="guidsLoaded && showList">
    <span class="text-2xl font-medium">Seed List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div *ngIf="hasEditAccess" class="flex flex-row-reverse">
                <covered-button id="btnLocation" label="Add New" (buttonClick)="editSeed(null)"></covered-button>
            </div>
            <div class="w-12">
                <app-custom-grid #cgSeeds id="cgSeeds" DataSourceName="dsAdminCampaignSeed"
                    (editRow)="editSeed($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<div *ngIf="guidsLoaded && showAddForm">
    <span class="text-2xl font-medium">{{ addSeedTitle }}</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <form *ngIf="seedFormLoaded" [formGroup]="seedForm" (ngSubmit)="saveSeed()">
                <div class="grid">

                    <div class="col-4">
                        <covered-form-text id="txtFName" placeholder="First Name" [required]="true"
                            formControlName="firstName">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.firstName.pristine && seedForm.controls.firstName.errors">
                                <p *ngIf="seedForm.controls.firstName.errors?.required">First Name is required.</p>
                            </div>
                        </covered-form-text>
                    </div>
                    <div class="col-4">
                        <covered-form-text id="txtLName" placeholder="Last Name" [required]="true"
                            formControlName="lastName">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.lastName.pristine && seedForm.controls.lastName.errors">
                                <p *ngIf="seedForm.controls.lastName.errors?.required">Last Name is required.</p>
                            </div>
                        </covered-form-text>
                    </div>
                    <div class="col-4">
                        <covered-datepicker id="dob" placeholder="Date of Birth" formControlName="dateOfBirth" [required]="true">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.dateOfBirth.pristine && seedForm.controls.dateOfBirth.errors">
                                <p *ngIf="seedForm.controls.dateOfBirth.errors?.required">Date of Birth is required.</p>
                            </div>
                        </covered-datepicker>
                    </div>

                    <div class="col-6">
                        <covered-form-text id="txtAdd1" placeholder="Address 1" [required]="true"
                            formControlName="address1">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.address1.pristine && seedForm.controls.address1.errors">
                                <p *ngIf="seedForm.controls.address1.errors?.required">Address 1 is required.</p>
                            </div>
                        </covered-form-text>
                    </div>
                    <div class="col-6">
                        <covered-form-text id="txtAdd2" placeholder="Address 2" formControlName="address2">
                        </covered-form-text>
                    </div>

                    <div class="col-4">
                        <covered-form-text id="txtCity" placeholder="City" [required]="true"
                            formControlName="city">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.city.pristine && seedForm.controls.city.errors">
                                <p *ngIf="seedForm.controls.city.errors?.required">City is required.</p>
                            </div>
                        </covered-form-text>
                    </div>
                    <div class="col-4">
                        <covered-dropdown id="state" placeholder="State" [items]="states" optionLabel="name"
                        optionValue="abbr" [showClear]="true" [required]="true" formControlName="state">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.state.pristine && seedForm.controls.state.errors">
                                <p *ngIf="seedForm.controls.state.errors?.required">State is required.</p>
                            </div>

                        </covered-dropdown>
                    </div>
                    <div class="col-4">
                        <covered-form-text id="txtZip" placeholder="Zip Code" [required]="true"
                            formControlName="zip">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.zip.pristine && seedForm.controls.zip.errors">
                                <p *ngIf="seedForm.controls.zip.errors?.required">Zip Code is required.</p>
                                <p *ngIf="seedForm.controls.zip.errors?.minlength || seedForm.controls.zip.errors?.maxlength">
                                    Zip Code must be between 5 and 10 characters.
                                </p>
                            </div>
                        </covered-form-text>
                    </div>

                    <div class="col-4">
                        <covered-input-mask id="SSN" placeholder="SSN7" [required]="true"
                            mask="999-99-9999" [unmask]="true" [autoClear]="true" 
                            formControlName="ssn" >
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.ssn.pristine && seedForm.controls.ssn.errors">
                                <p *ngIf="seedForm.controls.ssn.errors?.required">SSN is required.</p>
                                <p *ngIf="seedForm.controls.ssn.errors?.minlength || seedForm.controls.ssn.errors?.maxlength">
                                    SSN must be less 9 characters.
                                </p>
                            </div>
                        </covered-input-mask>
                    </div>
                    <div class="col-4">
                        <covered-dropdown id="state" placeholder="Loan Amount" [items]="loanAmounts" optionLabel="desc"
                        optionValue="id" [showClear]="true" [required]="true" formControlName="loanAmount">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!seedForm.controls.loanAmount.pristine && seedForm.controls.loanAmount.errors">
                                <p *ngIf="seedForm.controls.loanAmount.errors?.required">Loan Amount is required.</p>
                            </div>
                        </covered-dropdown>
                    </div>
                    <div class="col-4 align-self-center">
                        <p-checkbox [formControl]="$any(seedForm.controls['incFuture'])" [binary]="true" label="Include in Future Campaigns">
                        </p-checkbox>
                    </div>
                </div>
                <div class="flex flex-row-reverse">
                    <covered-button id="btnSaveSeed" buttonType="submit" label="{{saveSeedBtn}}"
                        [disabled]="!seedForm.valid"></covered-button>
                    <covered-button id="btnCancelSeed" label="Cancel" class="mr-4 outlined"
                        (buttonClick)="cancelSeedForm()"></covered-button>
                </div>
            </form>
        </div>
    </div>
</div>