import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumn } from '@covered/covered-ui';
import { MenuItem, Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfigs } from 'src/app/configs/app-config';
import { PortfolioItem } from 'src/app/models/customer-item';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { ApiService } from 'src/app/services/api.service';
import { NavService } from 'src/app/services/nav-service.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-list-page',
  templateUrl: './provider-list-page.component.html',
  styleUrls: ['./provider-list-page.component.scss']
})
export class ProviderListPageComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  hasError: boolean = false;
  showList: boolean = true;
  showEdit: boolean = false;
  tabMenu: MenuItem[] = [];
  bcItems: MenuItem[] = [];

  subscriptions: Subscription[] = [];
  providers: CustomerProviderModel[] = [];
  filterFields: string[] = ['addDate', 'customerName', 'relationship', 'template', 'website'];
  first: number = 0;
  columns: TableColumn[] = [];

  selectedPortfolio: PortfolioItem | null = null;
  selectedProvider: CustomerProviderModel | null = null;

  showProvider: boolean = false;
  showBranding: boolean = false;
  showSettlement: boolean = false;
  showLocation: boolean = false;
  showOffer: boolean = false;
  showProcedure: boolean = false;
  showCommunication: boolean = false;
  showLoan: boolean = false;
  showProcessor: boolean = false;
  showOther: boolean = false;
  addMenuItem: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService,
    private portfolioService: PortfolioService,
    private providerService: ProviderService,
    private navService: NavService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.data.subscribe(data => {
        if (data && data.action && data.action == 'add') {
          this.addMenuItem = true;
          this.loading = false;
          this.newProvider();
        }
        else {
          this.addMenuItem = false;
        }
      }),
      this.portfolioService.portfolio$
        .subscribe(p => {
          this.selectedPortfolio = p;
          if (this.providers.length > 0) {
            this.getProviders();
          }
        })
    );

    if (this.addMenuItem) {
      this.bcItems = [
        { label: 'Provider List', routerLink: ['/provider/list'], command: () => { this.navService.setLeftNavSelection('Client.List'); }  },
        { label: 'Add' }
      ];      
    }
    else { 
      this.bcItems = [
        { label: 'Provider List' }
      ];
    }
    
    this.tabMenu = [
      { label: 'Provider', command: () => { this.showTab('Provider'); } },
      { label: 'Branding', command: () => { this.showTab('Branding'); } },
      { label: 'Settlement Detail', command: () => { this.showTab('Settlement'); } },
      { label: 'Location Info', command: () => { this.showTab('Location'); } },
      { label: 'Offer Details', command: () => { this.showTab('Offer'); } },
      { label: 'Procedure(s)', command: () => { this.showTab('Procedure'); } },
      { label: 'Communication', command: () => { this.showTab('Communication'); } },
      { label: 'Loan App', command: () => { this.showTab('Loan'); } },
      { label: 'Processor', command: () => { this.showTab('Processor'); } },
      { label: 'Other', command: () => { this.showTab('Other'); } }
    ];
    this.createColumns();
    this.getProviders();
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  showTab(tabName: string) {
    this.showProvider = false;
    this.showBranding = false;
    this.showSettlement = false;
    this.showLocation = false;
    this.showOffer = false;
    this.showProcedure = false;
    this.showCommunication = false;
    this.showLoan = false;
    this.showProcessor = false;
    this.showOther = false;
    switch (tabName) {
      case 'Provider':
        this.showProvider = true;
        break;
      case 'Branding':
        this.showBranding = true;
        break;
      case 'Settlement':
        this.showSettlement = true;
        break;
      case 'Location':
        this.showLocation = true;
        break;
      case 'Offer':
        this.showOffer = true;
        break;
      case 'Procedure':
        this.showProcedure = true;
        break;
      case 'Communication':
        this.showCommunication = true;
        break;
      case 'Loan':
        this.showLoan = true;
        break;
      case 'Processor':
        this.showProcessor = true;
        break;
      case 'Other':
        this.showOther = true;
        break;
    }
  }

  getProviders() {
    let provSub = this.apiService.get('provider')
      .subscribe({
        next: (result: CustomerProviderModel[]) => {
          this.providers = result || [];
          this.providers.sort((a, b) => a.customerName.toLowerCase() > b.customerName.toLowerCase() ? 1 : -1);
          if (this.selectedPortfolio) {
            this.providers = this.providers.filter(p => p.customerGuid === this.selectedPortfolio?.customerGuid);
          }
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;

          let message: Message = {
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to load Providers. See log for details.'
          };
          this.setError(err, message);
        },
        complete: () => { provSub.unsubscribe(); }
      });
  }

  createColumns() {
    this.columns = [
      {
        field: 'customerGuid',
        header: 'Customer Guid',
        clickable: false,
        show: false,
        sortable: false, 
        class: null
      },
      {
        field: 'customerName',
        header: 'Name',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'template',
        header: 'Template',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'relationship',
        header: 'Relationship',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'website',
        header: 'Website',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'addDate',
        header: 'Create Date',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'clarityLive',
        header: 'Clarity Live',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      }
    ];
  }

  setError(err: any, message?: Message) {
    this.hasError = true;
    this.loading = false;
    console.error(err);

    if (message) this.toastService.add(message);
  }
  
  goToAddProvider() {
    this.navService.setLeftNavSelection('Client.Edit');
    this.router.navigate(['/provider/add']);
  }

  newProvider() {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEdit = false; this.showList = true; this.selectedProvider = null; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Add' });

    this.selectedProvider = null;
    this.providerService.removeProviderInfo();
    this.providerEditSetup();

  }

  editProvider(row: CustomerProviderModel) {
    let prevItem = this.bcItems.pop();
    if (prevItem) {
      prevItem.command = () => { this.showEdit = false; this.showList = true; this.selectedProvider = null; this.bcItems.pop(); this.removeBcCommand(prevItem?.label); };
      this.bcItems.push(prevItem);
    }
    this.bcItems.push({ label: 'Edit' });

    this.selectedProvider = row;
    this.providerService.loadInfoSectionProvider(row.customerGuid);
    this.providerEditSetup();

  }

  providerEditSetup() {
    localStorage.removeItem(AppConfigs.providerInfoForm);
    localStorage.removeItem(AppConfigs.settlementForm);
    localStorage.removeItem(AppConfigs.providerCommForm);
    localStorage.removeItem(AppConfigs.providerLoanForm);
    localStorage.removeItem(AppConfigs.otherForm);
    this.showTab('Provider'); 
    this.showList = false;
    this.showEdit = true;

  }

  providerCalendar(row: CustomerProviderModel) {
    this.toastService.underConstruction();
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }
}
