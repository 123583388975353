<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <form [formGroup]="otherForm" (ngSubmit)="saveOther()">
        <div class="grid">
            <div class="col-6 grid">
                <div class="col-10">
                    Is Ally Enabled
                </div>
                <div class="col-2">
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        formControlName="allyEnabled"></p-selectButton>
                </div>
                <div class="col-10">
                    Block PO Address (Loan App)
                </div>
                <div class="col-2">
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        formControlName="blockPOAdd"></p-selectButton>
                </div>
                <div class="col-10">
                    Save Loan Details on Single Element
                </div>
                <div class="col-2">
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        formControlName="singleElemLoan"></p-selectButton>
                </div>
                <div class="col-10">
                    Process Payment Statement
                </div>
                <div class="col-2">
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        formControlName="procPayState"></p-selectButton>
                </div>
                <div class="col-10">
                    Allow More Than 1 Payment Per Day
                </div>
                <div class="col-2">
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        formControlName="more1PayDay"></p-selectButton>
                </div>
            </div>
            <div class="col-6 grid" *ngIf="otherForm.value.allyEnabled">
                <div class="col-12">
                    <covered-form-text id="txtMerchId" placeholder="Ally Merchant Id" formControlName="allyMerchantId">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!otherForm.controls.allyMerchantId.pristine && otherForm.controls.allyMerchantId.errors">
                            <p *ngIf="otherForm.controls.allyMerchantId.errors?.required">Ally Merchant Id is required.</p>
                            <p *ngIf="otherForm.controls.allyMerchantId.errors?.maxlength">Ally Merchant Id must be 100 characters or less.</p>
                        </div>
                    </covered-form-text>
                </div>
                <div class="col-12">
                    <covered-form-text id="txtApiKey" placeholder="Ally Api Key" formControlName="allyApiKey">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!otherForm.controls.allyApiKey.pristine && otherForm.controls.allyApiKey.errors">
                            <p *ngIf="otherForm.controls.allyApiKey.errors?.required">Ally Api Key is required.</p>
                            <p *ngIf="otherForm.controls.allyApiKey.errors?.maxlength">Ally Api Key must be 250 characters or less.</p>
                        </div>                        
                    </covered-form-text>
                </div>
            </div>
            <div class="col-12">
                <div class="w-6 pr-6">
                    <covered-dropdown id="ddProductType" placeholder="Offer Prouct Type"
                        [items]="lookups.offerProductType" optionLabel="desc" optionValue="id" [showClear]="false"
                        formControlName="productType">
                    </covered-dropdown>
                </div>
            </div>
            <div class="col-12">
                <div class="w-6 pr-6">
                    <covered-dropdown id="ddLoanAdjust" placeholder="Loan Amount Adjustment"
                        [items]="lookups.bopAmountAdjustment" optionLabel="desc" optionValue="id" [showClear]="false"
                        formControlName="loanAdjust">
                    </covered-dropdown>
                </div>
            </div>

            <div class="col-12">
                <div class="w-6 pr-6">
                    <covered-input-number id="txtAdjustSvcDays" placeholder="Adjust Service Days" mode="decimal"
                        formControlName="serviceDays">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!otherForm.controls['serviceDays'].pristine && otherForm.controls['serviceDays'].errors">
                            <p
                                *ngIf="otherForm.controls['serviceDays'].errors?.min || otherForm.controls['serviceDays'].errors?.max">
                                Adjust Service Days must be between 0 and 365.</p>
                        </div>
                    </covered-input-number>
                </div>
            </div>

            <div class="col-6">
                <covered-input-number id="appggt" placeholder="Incomplete Application Expire (Days)" mode="decimal"
                    formControlName="incompleteAppExpiry"></covered-input-number>
            </div>
            <div class="col-6">
                <covered-input-number id="appggtf" placeholder="LoanApp Inactivity Timeout (Seconds)" mode="decimal"
                    formControlName="inactivityTimeout"></covered-input-number>
            </div>


            <div class="col-12 pt-2">
                <div class="pt-4 border-top-1 border-black-alpha-40 text-3xl font-bold">Clarity</div>
            </div>
            <div class="col-2">
                <covered-form-text id="txtClarFile" placeholder="File Name" formControlName="clarityFile">
                </covered-form-text>
            </div>
            <div class="col-2">
                <covered-form-text id="txtClarFileVer" placeholder="File Version Number"
                    formControlName="clarityVersion"></covered-form-text>
            </div>
            <div class="col-2">
                <covered-form-text id="txtClarLocation" placeholder="Location ID" formControlName="clarityLocation">
                </covered-form-text>
            </div>
            <div class="col-2">
                <covered-form-text id="txtClarInq" placeholder="Inquiry Purpose Type" formControlName="clarityPurpose">
                </covered-form-text>
            </div>
            <div class="col-2">
                <covered-form-text id="txtClarTrade" placeholder="Inquiry Tradeline Type"
                    formControlName="clarityTradeline"></covered-form-text>
            </div>
            <div class="col-2 grid">
                <div class="col-6">Clarity Live?</div>
                <div class="col-6">
                    <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                        formControlName="clarityLive"></p-selectButton>
                </div>
            </div>
            <div class="col-12 pt-2">
                <div class="pt-4 border-top-1 border-black-alpha-40 text-3xl font-bold">Verification Queue</div>
            </div>
            <div class="col-1">Auto Assign Apps</div>
            <div class="col-1">
                <p-selectButton [options]="toggleOptions" optionLabel="label" optionValue="value"
                    formControlName="autoAssignApps"></p-selectButton>
            </div>
            <div class="col-2">
                <covered-input-number id="txtAppsRep" placeholder="Max Apps per Rep" mode="decimal"
                    formControlName="appsPerRep"></covered-input-number>
            </div>
            <div class="col-2">
                <covered-input-number id="txtWaitReset" placeholder="Min Wait Reset App" mode="decimal"
                    formControlName="waitReset"></covered-input-number>
            </div>
            <div class="col-2">
                <covered-input-number id="txtRepInact" placeholder="Rep Inactivity App" mode="decimal"
                    formControlName="repInactivity"></covered-input-number>
            </div>

        </div>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveOther" label="Save Settings" buttonType="submit" [disabled]="otherForm.invalid">
            </covered-button>
        </div>
    </form>
</div>