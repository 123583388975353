<span class="text-2xl font-medium">Past Settlements</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="cds-card w-screen-fit p-2">
        <p-table #dt [value]="pastLogs" styleClass="p-datatable-lg mr-4 mb-4 col-12" [paginator]="true"
            [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
            [rows]="100" [rowHover]="true" [columns]="columns">
            <ng-template pTemplate="caption">
                <div class="col-offset-11 col-1 relative">
                    <span><i class="pi pi-download text-4xl cursor-pointer" (click)="showDownloadRange = !showDownloadRange"></i></span>
                    <div *ngIf="showDownloadRange"
                        class="border-0 shadow-2 w-25rem p-4 text-left right-0 menu absolute z-5 animate__animated animate__flipInY">
                        <div class="flex flex-wrap pt-2">
                            <div class="w-12 text-xl">Past Settlements</div>
                            <div class="w-12 text-sm">Select date range to process and download report.</div>
                            <div class="w-6 pr-1">
                                <covered-datepicker id="dtSt" placeholder="Start Date" [(ngModel)]="startDate"
                                panelStyleClass="w-20rem" [maxDate]="today"></covered-datepicker>
                            </div>
                            <div class="w-6 pl-1">
                                <covered-datepicker id="dtEnd" placeholder="End Date" [(ngModel)]="endDate"
                                panelStyleClass="w-20rem" [maxDate]="today"></covered-datepicker>
                            </div>
                        </div>
                        <div class="flex flex-row-reverse pt-2">
                            <covered-button id="btnRpt" label="Download" class="px-4" (buttonClick)="downloadReport()"
                                [disabled]="!startDate || !endDate"></covered-button>
                            <covered-button id="btnRptCancel" label="Cancel" class="outline" (buttonClick)="cancelReport()"></covered-button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                        <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field" class="font-medium"
                            scope="col">{{ col.header }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                    </ng-template>
                    <th scope="col"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr class="cursor-pointer" (click)="selectRow(rowData)">
                    <td>{{ rowData.createDateDisplay | date: 'MM/dd/yyyy'}}</td>
                    <td>{{ rowData.debitCount }}</td>
                    <td>{{ rowData.debitAmount }}</td>
                    <td>{{ rowData.creditCount }}</td>
                    <td>{{ rowData.creditAmount }}</td>
                    <td>
                        <i class="pi pi-download cursor-pointer mr-4" title="Edit" aria-hidden="true"
                            (click)="$event.stopPropagation();downloadRow(rowData)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [colSpan]="7">No Settlement found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="hidden">
        <p-table #dtRpt [value]="reportRows" styleClass="p-datatable-lg mr-4 mb-4 col-12"
            [paginator]="false" [columns]="reportColumns" [exportFilename]="exportFileName">
            <ng-template pTemplate="header">
                <tr>
                    <ng-template ngFor let-col [ngForOf]="reportColumns" let-i="index">                        
                        <th *ngIf="col.show" scope="col">{{ col.header }}</th>
                    </ng-template>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>   
                    <td>{{ rowData.paymentDate | date: 'MM/dd/yyyy' }}</td>
                    <td>{{ rowData.accountNumber }}</td>
                    <td>{{ rowData.loanNumber }}</td>
                    <td>{{ rowData.name }}</td>
                    <td>{{ rowData.debitAmount | currency }}</td>
                    <td>{{ rowData.creditAmount | currency }}</td>
                    <td>{{ rowData.payType }}</td>
                    <td>{{ rowData.approvalCode }}</td>
                    <td>{{ rowData.transRef }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [colSpan]="9">No Settlements found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>