<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && !guidsLoaded">
    <div class="text-3xl cds-error-text">
        You MUST select a Provider and Campaign before accessing this page.
    </div>
</div>

<div *ngIf="!loading && guidsLoaded && showPageDataCard">
    <span class="text-2xl font-medium">Page Data</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 text-xl pt-4">
                    {{pageName}}
                </div>
                <div class="col-12">
                    <p-table #dt [value]="pageRows" dataKey="id" styleClass="p-datatable-sm col-12"
                        [globalFilterFields]="filterFields" [paginator]="false" [rowsPerPageOptions]="[15, 30, 50, 100]"
                        [(first)]="first" [showCurrentPageReport]="false"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rowHover]="true" [columns]="columns">
                        <ng-template pTemplate="caption">
                            <div class="grid">
                                <div class="col-offset-9 col-1 relative align-self-center text-right"
                                elemClickOutside (clickOutside)="showAddNew = false;">
                                    <span class="cursor-pointer cds-text-color" (click)="showAddNew = !showAddNew">Add New</span>
                                    <div *ngIf="showAddNew"
                                        class="border-0 shadow-2 w-25rem p-4 text-left left-0 menu absolute z-5 animate__animated animate__flipInY">
                                        <div class="flex flex-wrap">
                                            <div class="w-12 font-bold">Create New Component(s)</div>
                                            <div class="w-8">
                                                <covered-dropdown id="ddNewComp" placeholder="Select"
                                                    [items]="newCompItems" optionValue="value" optionLabel="text"
                                                    [(ngModel)]="newCompItemSel"></covered-dropdown>
                                            </div>
                                            <div class="w-4 pl-2">
                                                <covered-input-number id="txtnum" placeholder="How Many" [(ngModel)]="newCompItemCnt"
                                                    mode="decimal" [useGrouping]="false">
                                                </covered-input-number>
                                            </div>
                                        </div>
                                        <div class="flex flex-row-reverse">
                                            <span class="cursor-pointer cds-text-color" (click)="addNewComponent()">Create</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" aria-hidden="true"></i>
                                        <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                            (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-row-reverse">
                                <covered-dropdown id="ddLangMstr" [items]="languages" optionLabel="name"
                                    optionValue="versionId" [showClear]="false"
                                    (optionSelected)="mainVersionChanged($event);" appendTo="body"
                                    [(ngModel)]="selectedLanguage" class="w-2">
                                </covered-dropdown>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Order</th>
                                <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                                    <th *ngIf="col.show" scope="col">{{ col.header }}</th>
                                </ng-template>
                                <th>Version</th>
                                <th scope="col"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-pageData let-index="rowIndex" let-expanded="expanded">
                            <tr>
                                <td>{{ (index + 1) }}</td>
                                <td *ngFor="let c of getColumns()" [ngClass]="c.class != null ? c.class : ''">
                                    <div *ngIf="c.field != 'preview'; else showPreview">{{pageData[c.field]}}</div>
                                    <ng-template #showPreview>
                                        <div [ngSwitch]="pageData.element">
                                            <div *ngSwitchCase="'TextBox'">
                                                <covered-form-text id="{{pageData.objectId}}"
                                                    placeholder="{{pageData.render.placeholder}}"
                                                    [(ngModel)]="pageData.render.model" readonly></covered-form-text>
                                            </div>
                                            <div *ngSwitchCase="'Button'">
                                                <covered-button id="{{pageData.objectId}}"
                                                    label="{{pageData.render.label}}"
                                                    class="{{pageData.render.class}}"></covered-button>
                                            </div>
                                            <div *ngSwitchCase="'Dropdown'">
                                                <covered-dropdown id="{{pageData.objectId}}"
                                                    placeholder="{{pageData.render.placeholder}}"
                                                    [items]="pageData.render.items" [(ngModel)]="pageData.render.model"
                                                    optionLabel="name" optionValue="value" appendTo="body">
                                                </covered-dropdown>
                                            </div>
                                            <div *ngSwitchCase="'RadioList'">
                                                <div class="{{pageData.render.class}}">
                                                    <span *ngIf="pageData.render.label.length > 0"
                                                        class="{{pageData.render.lblClass}}">{{
                                                        pageData.render.label }}</span>
                                                    <ng-container *ngFor="let rdo of pageData.render.items">
                                                        <div class="{{pageData.render.rdoClass}}">
                                                            <p-radioButton name="{{pageData.objectId}}"
                                                                [value]="rdo.value" label="{{rdo.name}}"
                                                                [(ngModel)]="pageData.render.model">
                                                            </p-radioButton>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'CheckBoxList'">
                                                <div class="{{pageData.render.class}}">
                                                    <span *ngIf="pageData.render.label.length > 0"
                                                        class="{{pageData.render.lblClass}}">{{
                                                        pageData.render.label }}</span>
                                                    <ng-container *ngFor="let chk of pageData.render.items">
                                                        <div class="{{pageData.render.chkClass}}">
                                                            <p-checkbox [binary]="true" label="{{chk.label}}"
                                                                [(ngModel)]="chk.selected">
                                                            </p-checkbox>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div *ngSwitchCase="'Unsorted List'">
                                                <ul class="list-disc">
                                                    <li *ngFor="let li of pageData.render.items">{{li.name}}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div *ngSwitchDefault class="break-word">{{pageData.preview}}</div>
                                        </div>
                                    </ng-template>
                                </td>
                                <td>
                                    <covered-dropdown id="ddLang{{index}}" [items]="languages" optionLabel="name"
                                        optionValue="versionId" [showClear]="false"
                                        (optionSelected)="rowVersionChanged($event, pageData, index);" appendTo="body"
                                        [(ngModel)]="langSelects[index]">
                                    </covered-dropdown>
                                </td>
                                <td>
                                    <div class="flex">
                                        <i class="pi pi-pencil cursor-pointer mr-4" title="Edit" aria-hidden="true"
                                            [pRowToggler]="pageData"></i>
                                        <i class="pi pi-copy cursor-pointer mr-4" title="Copy" aria-hidden="true"
                                            (click)="confirmCopyRow(pageData)"></i>
                                        <i class="pi pi-trash cursor-pointer" title="Delete" aria-hidden="true"
                                            (click)="confirmDeleteRow(pageData, index)"></i>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-pageData let-index="rowIndex">
                            <tr class="pg-data-expanded-bg">
                                <td>{{ (index + 1) }}</td>
                                <td colspan="6">
                                    <div [ngSwitch]="pageData.element">
                                        <div *ngSwitchCase="'Script'">
                                            <app-page-data-script [data]="getDataObject('script', pageData)"
                                                [pageFields]="pageFields" [functions]="lookups.functions"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-script>
                                        </div>
                                        <div *ngSwitchCase="'Label'">
                                            <app-page-data-label [data]="getDataObject('label', pageData)"
                                                [pageFields]="pageFields" [functions]="lookups.functions"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-label>
                                        </div>
                                        <div *ngSwitchCase="'Href'">
                                            <app-page-data-href [data]="getDataObject('href', pageData)"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-href>
                                        </div>
                                        <div *ngSwitchCase="'Button'">
                                            <app-page-data-button [data]="getDataObject('button', pageData)"
                                                [buttonActions]="buttonActions" [pages]="getPages()"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-button>
                                        </div>
                                        <div *ngSwitchCase="'Unsorted List'">
                                            <app-page-data-unsortlist [data]="getDataObject('unsortlist', pageData)"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-unsortlist>
                                        </div>
                                        <div *ngSwitchCase="'TextBox'">
                                            <app-page-data-textbox [data]="getDataObject('text', pageData)"
                                                [pageFields]="pageFields" [functions]="lookups.functions"
                                                [dupItems]="getTxtDupItems(pageData)" [validations]="validations"
                                                [serverVals]="serverVals" (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-textbox>
                                        </div>
                                        <div *ngSwitchCase="'Dropdown'">
                                            <app-page-data-dropdown [data]="getDataObject('dropdown', pageData)"
                                                [pageFields]="pageFields" [functions]="lookups.functions"
                                                [validations]="validations" [serverVals]="serverVals"
                                                [pages]="getPages()" (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-dropdown>
                                        </div>
                                        <div *ngSwitchCase="'RadioList'">
                                            <app-page-data-radiobutton [data]="getDataObject('radiolist', pageData)"
                                                [pageFields]="pageFields" [functions]="lookups.functions"
                                                [validations]="validations" [pages]="getPages()"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-radiobutton>
                                        </div>
                                        <div *ngSwitchCase="'CheckBoxList'">
                                            <app-page-data-checkboxlist [data]="getDataObject('checkboxes', pageData)"
                                                [pageFields]="pageFields" [functions]="lookups.functions"
                                                [validations]="validations" [pages]="getPages()"
                                                (onCancel)="cancelRowEdit(pageData);"
                                                (onSave)="saveRow($event);"></app-page-data-checkboxlist>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td>
                                    <covered-dropdown id="ddLangSub{{index}}" [items]="languages" optionLabel="name"
                                        optionValue="versionId" [showClear]="false"
                                        (optionSelected)="rowVersionChanged($event, pageData, index);" appendTo="body"
                                        [(ngModel)]="langSelects[index]">
                                    </covered-dropdown>
                                </td> 
                                <td></td>-->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="7">No Page Data found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog [key]="pageDataConfirmKey" [style]="{width: '45vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-outlined" styleClass="text-xl">
</p-confirmDialog>