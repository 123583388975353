import { Component, OnInit } from '@angular/core';
import { TableColumn } from '@covered/covered-ui';
import { MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-provider-offer-lookup',
  templateUrl: './provider-offer-lookup.component.html',
  styleUrls: ['./provider-offer-lookup.component.scss']
})
export class ProviderOfferLookupComponent implements OnInit {

  bcItems: MenuItem[] = [];
  amtColumns: TableColumn[] = [];
  aprColumns: TableColumn[] = [];
  mdrColumns: TableColumn[] = [];
  minColumns: TableColumn[] = [];
  termColumns: TableColumn[] = [];
  loading = false;
  offers = {
    offerAmounts: [],
    aprPercents: [],
    merchantDiscountRates: [],
    minDownPercents: [],
    terms: [],
  };
  constructor(private api: ApiService, private provider: ProviderService) { 
    this.bcItems = [{ label: 'Provider List >' }];
  }

  ngOnInit(): void {
    this.provider.loadCustomerNames();
    this.createColumns();
      this.getOffers();
  }

  getOffers(){
    this.loading = true;
    this.api.get("offerlookups/all").subscribe(data => {
      this.offers = data;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  createColumns() {
    var custName = { field: 'customerName', header: 'Customer Name', clickable: false, show: true, sortable: true,
    class: null };
    var custId = { field: 'customerGuid', header: 'Customer Id', clickable: false, show: false, sortable: false, 
    class: null };

    this.amtColumns = [
      custName,
      custId,
      {
        field: 'offerTermDesc',
        header: 'Offer Term Desc',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'offerTermAmt',
        header: 'Offer Term Amt',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'isMinAmt',
        header: 'Is Min Amt',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'isDownPmt',
        header: 'Is Down Pmt',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      }
    ];
    this.aprColumns = [
      custName,
      custId,
      {
        field: 'offerAprId',
        header: 'OfferAprId',
        clickable: false,
        show: false,
        sortable: true, 
        class: null
      },
      {
        field: 'offerAprDesc',
        header: 'OfferAprDesc',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'offerAprPct',
        header: 'OfferAprPct',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      }
    ];
    this.mdrColumns = [
      custName,
      custId,
      {
        field: 'offerMdrId',
        header: 'OfferMdrId',
        clickable: false,
        show: false,
        sortable: true, 
        class: null
      },
      {
        field: 'offerMdrPct',
        header: 'OfferMdrPct',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'offerMdrDesc',
        header: 'OfferMdrDesc',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      }
    ];
    this.minColumns = [
      custName,
      custId,
      {
        field: 'offerMinDownPctId',
        header: 'OfferMinDownPctId',
        clickable: false,
        show: false,
        sortable: true, 
        class: null
      },
      {
        field: 'offerMinDownPctDesc',
        header: 'OfferMinDownPctDesc',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'offerMinDownPct',
        header: 'OfferMinDownPct',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      }
    ];
    this.termColumns = [
      custName,
      custId,
      {
        field: 'offerTermId',
        header: 'OfferTermId',
        clickable: false,
        show: false,
        sortable: true, 
        class: null
      },
      {
        field: 'offerTermDesc',
        header: 'Offer Term Desc',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      },
      {
        field: 'isSameAsCash',
        header: 'IsSameAsCash',
        clickable: false,
        show: true,
        sortable: true, 
        class: null
      }
    ];
  }

}
