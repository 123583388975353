<div>
    <span class="text-2xl font-medium">New Predefined Lead</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <form [formGroup]="leadForm">
        <div class="mt-4">
            <div class="row">
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddCountry" [items]="lookups.providerBranches" optionLabel="desc"
                            placeholder="Branch" optionValue="id" [showClear]="false" [required]="true"
                            formControlName="BranchID">
                        </covered-dropdown>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="LeadName" formControlName="LeadName" [required]="true">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddCountry" [items]="disclosures" optionLabel="desc"
                            placeholder="Disclosures" optionValue="id" [showClear]="false" [required]="true"
                            formControlName="AgreeToSignDisclosures">
                        </covered-dropdown>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Patient Id" formControlName="LeadPatientID">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Assign To" formControlName="AssignTo">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddem" [items]="trueFalse" optionLabel="desc"
                        placeholder="Send Email" optionValue="id" [showClear]="false" [required]="true"
                        formControlName="SendEmail">
                    </covered-dropdown>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddsm" [items]="trueFalse" optionLabel="desc"
                            placeholder="Send SMS" optionValue="id" [showClear]="false" [required]="true"
                            formControlName="SendSMS">
                        </covered-dropdown>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddapp" [items]="appLinks" optionLabel="desc"
                            placeholder="Send App Link" optionValue="id" [showClear]="false" [required]="true"
                            formControlName="SendAppLink">
                        </covered-dropdown>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddln" [items]="languages" optionLabel="desc"
                            placeholder="Language" optionValue="id" [showClear]="false" [required]="true"
                            formControlName="Language">
                        </covered-dropdown>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead First Name *" formControlName="LeadFirstName">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Last Name *" formControlName="LeadLastName">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead DOB" formControlName="LeadDateOfBirth">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Address *" formControlName="LeadAddress">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Address 2" formControlName="LeadAddress2">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead City *" formControlName="LeadCity">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead State *" formControlName="LeadState">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Zip Code *" formControlName="LeadZip">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Email Address *" formControlName="LeadEmailAddress">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead Phone Number *" formControlName="LeadPhone">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Lead SSN" formControlName="LeadSSN">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Loan Requested Amount" formControlName="RequestedAmount">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Loan Type" formControlName="LoanType" [disabled]=true>
                        </covered-form-text>
                    </div>
                </div>
            </div>
        </div>

    </form>
    <form [formGroup]="procedureForm">
        <div class="mt-1">
            <div class="row">
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Procedure ID" formControlName="ProcedureId" [disabled]=true>
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Procedure Date" formControlName="ProcedureDate">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-form-text id="" placeholder="Procedure Amount" formControlName="ProcedureAmount">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="m-2">
                        <covered-dropdown id="ddlol" [items]="lookups.providerProducts" optionLabel="desc"
                        placeholder="Procedure Name *" optionValue="id" [showClear]="false" [required]="true"
                        formControlName="ProcedureName">
                    </covered-dropdown>
                    </div>
                </div>
            </div>
        </div>

    </form>
    <div class="d-flex flex-row mb-3 mt-3">
        <div class="p-2">
            <covered-button id="btnSaveRecs" [label]="btnText" (buttonClick)="save()">
            </covered-button>
        </div>
        <div class="p-2">
            <covered-button id="btnClear" class="outline" (buttonClick)="cancel()">Cancel</covered-button>
        </div>
      </div>
</div>