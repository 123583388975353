<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">Unable to load Offer Matrix Information.
</div>


<div *ngIf="!loading && !hasError && showCard">

    <span class="text-2xl font-medium">Decision Engine Rule Reasons</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div *ngIf="hasEditFeature" class="col-12 flex flex-row-reverse">
                    <covered-button id="btnAddMatrix" label="Add Rule Reason" (buttonClick)="addRuleReason()">
                    </covered-button>
                </div>
                <div class="col-12 pt-4">
                    <app-custom-grid #cgRuleReas id="cgRuleReas" DataSourceName="dsAdminDERuleReasonList"
                        [allowCopy]="false" (editRow)="editRuleReason($event)" [overrideCustomer]="true"
                        [overrideDelete]="true" (deleteRow)="deleteReason($event)"
                        [allowCustomCRUD]="false" [showCustomCaption]="true">
                        <div caption>
                            <covered-dropdown id="ddDecTypes" [items]="filterDecisionTypes" [displayFirst]="true"
                                optionLabel="decisionType" placeholder="Show Decision Type"
                                (optionSelected)="decisionTypeFilterChanged($event)"></covered-dropdown>
                        </div>
                    </app-custom-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!loading && !hasError && showReasonEdit">

    <span class="text-2xl font-medium">Decision Engine {{reasonEditTitle}}</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4" [ngClass]="leftNavExpanded ? 'max-w-86':'max-w-92'">
            <form *ngIf="reasonFormLoaded" [formGroup]="reasonForm" (ngSubmit)="saveReason()">
                <div class="grid">
                    <div class="col-12 text-2xl font-bold">{{reasonEditTitle}}</div>
                    <div class="col-12 py-4">
                        <div class="w-6 pr-2">
                            <covered-form-text id="txtReasonName" placeholder="Name" [required]="true" formControlName="name">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="!reasonForm.controls.name.pristine && reasonForm.controls.name.errors">
                                    <p *ngIf="reasonForm.controls.name.errors?.required">Name is required
                                    </p>
                                </div>
                            </covered-form-text>
                        </div>
                    </div>
                    <div class="col-6 py-4">
                        <covered-dropdown id="ddDecisionType" placeholder="Decision Type" [required]="true"
                            [items]="lookups.decisionTypes" optionLabel="decisionType" optionValue="decisionTypeID"
                            formControlName="decisionType" (optionSelected)="decisionTypeSelected($event)">
                        </covered-dropdown>
                    </div>
                    <div class="col-6 py-4">
                        <covered-dropdown  *ngIf="reasonForm.value.decisionType != 10" id="ddAppStatus" 
                            placeholder="App Status" [required]="true" [items]="lookups.appStatusesShow"
                            optionLabel="displayName" optionValue="loanAppStatusID" formControlName="appStatus"></covered-dropdown>
                        <div class="w-12" *ngIf="reasonForm.value.decisionType == 10">&nbsp;</div>
                    </div>
                    <div class="col-12" *ngIf="showDisclosure">
                        <div class="w-6 pr-2">
                            <covered-form-textarea id="txtDisclosure" placeholder="{{txtDisclosurePlaceholder}}" [required]="true"
                                formControlName="disclosure"></covered-form-textarea>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="showNOAACheckbox">
                        <div class="w-6 pr-2">
                            <p-checkbox id="chkNOAA" [formControl]="$any(reasonForm.controls['ignoreRFAIDocs'])" [binary]="true" label="Ignore RFAI Docs NOAA Disclosure"></p-checkbox>
                            <label for="chkNOAA"><em> (If this is checked, this NOAA disclosure will be used no matter there were previous RFAI docs on the customer, which failed and has their own NOAA disclosures.)</em></label>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="showDocuments">
                        <span class="text-lg font-bold">DocsRequested</span>
                        <div formArrayName="documents">
                            <div *ngFor="let doc of reasonForm.controls.documents.controls; index as i">
                                <div class="grid" [formGroupName]="i">
                                    <div class="col-12">
                                        <p-checkbox id="chkVer{{i}}" [formControl]="$any(doc.controls.selected)" [binary]="true" label="{{doc.value.document}}"></p-checkbox>
                                    </div>
                                    <div class="col-5">
                                        <covered-form-textarea id="txtDocDesc{{i}}" formControlName="description"></covered-form-textarea>
                                    </div>
                                    <div class="col-5">
                                        <covered-form-textarea id="txtDocNOAA{{i}}" formControlName="disclosure" placeholder="NOAA Disclosure"></covered-form-textarea>
                                    </div>
                                    <div class="col-1 flex flex-wrap">
                                        <p-checkbox id="chkPass{{i}}" [formControl]="$any(doc.controls.pass)" [binary]="true" label="Pass"></p-checkbox>
                                        <p-checkbox id="chkNeutral{{i}}" [formControl]="$any(doc.controls.neutral)" [binary]="true" label="Neutral"></p-checkbox>
                                        <p-checkbox id="chkFail{{i}}" [formControl]="$any(doc.controls.fail)" [binary]="true" label="Show"></p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="showVerifications">
                        <span class="text-lg font-bold">Verifications to Perform</span>
                        <div formArrayName="verifications">
                            <div *ngFor="let ver of reasonForm.controls.verifications.controls; index as i">
                                <div class="grid" [formGroupName]="i">
                                    <div class="col-12">
                                        <p-checkbox id="chkVer{{i}}" [formControl]="$any(ver.controls.selected)" [binary]="true" label="{{ver.value.verification}}"></p-checkbox>
                                    </div>
                                    <div class="col-5">
                                        <covered-form-textarea id="txtVerDesc{{i}}" formControlName="description"></covered-form-textarea>
                                    </div>
                                    <div class="col-1 flex flex-wrap">
                                        <p-checkbox id="chkPass{{i}}" [formControl]="$any(ver.controls.pass)" [binary]="true" label="Pass"></p-checkbox>
                                        <p-checkbox id="chkNeutral{{i}}" [formControl]="$any(ver.controls.neutral)" [binary]="true" label="Neutral"></p-checkbox>
                                        <p-checkbox id="chkFail{{i}}" [formControl]="$any(ver.controls.fail)" [binary]="true" label="Show"></p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row-reverse">
                    <covered-button *ngIf="isInventory" id="btnSaveReason" label="Save" buttonType="submit" [disabled]="!reasonForm.valid">
                    </covered-button>
                    <covered-button id="btnCancelReason" label="Cancel" class="outline mr-4" (buttonClick)="cancelReason()">
                    </covered-button>
                    <!-- <covered-button id="btnTestForm" label="Test" class="outline pr-4" (buttonClick)="testFormValidity()"></covered-button> -->
                </div>
            </form>
        </div>
    </div>
