<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <form [formGroup]="settlementForm" (ngSubmit)="saveSettlement();">
        <div class="grid">

            <div class="col-12 pt-2">
                <div class="pt-4 text-3xl font-bold">Funding</div>
            </div>
            <div class="col-12">
                <div class="w-6">
                    <covered-dropdown id="ddFacility" placeholder="Facility" [items]="lookups.tranches"
                        optionLabel="desc" optionValue="id" [showClear]="false" [required]="true"
                        formControlName="facility"></covered-dropdown>
                </div>
            </div>

            <div class="col-12 pt-2">
                <div class="pt-4 border-top-1 border-black-alpha-40 text-3xl font-bold">Bank Details</div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtName" placeholder="Bank Name" [required]="true" formControlName="bankName"></covered-form-text>
                </div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtAccount" placeholder="Bank Account" [required]="true" formControlName="bankAccount"></covered-form-text>
                </div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtRouting" placeholder="Routing Number" [required]="true" formControlName="bankRouting"></covered-form-text>
                </div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtWireInst" placeholder="Wire Instructions" [required]="true" formControlName="wireInst"></covered-form-text>
                </div>
            </div>

            <div class="col-12 pt-2">
                <div class="pt-4 border-top-1 border-black-alpha-40 text-3xl font-bold">Contact Details</div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtContName" placeholder="Name" [required]="true" formControlName="contName"></covered-form-text>
                </div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtContPhone" placeholder="Phone" [required]="true" formControlName="contPhone"></covered-form-text>
                </div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtContEmail" placeholder="Email" [required]="true" formControlName="contEmail"></covered-form-text>
                </div>
            </div>


            <div class="col-12 pt-2">
                <div class="pt-4 border-top-1 border-black-alpha-40 text-3xl font-bold">Lock Box</div>
            </div>
            <div class="col-12 pt-4">
                <div class="w-6">
                    <covered-form-text id="txtFullAdd" placeholder="Full Address" [required]="true" formControlName="fullAddress"></covered-form-text>
                </div>
            </div>

            <div class="col-12 flex flex-row-reverse">
                <covered-button buttonType="submit" id="btnSaveSettlement" [disabled]="settlementForm.invalid"><span class="px-4">Save Settlement</span></covered-button>
            </div>

        </div>
    </form>
</div>