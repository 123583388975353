<span class="text-2xl font-medium">Settlement Report</span>
<div class="w-full mt-4 border-1 border-black-alpha-10">
    <div class="p-4 report-item-link" (click)="goToSettlementPage('full');">
        Full Summary Report
    </div>
    <div class="p-4 report-item-link" (click)="goToSettlementPage('day');">
        Summary by Date
    </div>    
    <div class="p-4 report-item-link" (click)="goToSettlementPage('provider');">
        Summary by Provider
    </div>
    <div class="p-4 report-item-link" (click)="goToSettlementPage('records');">
        All Records - Previous Day
    </div>
</div>
