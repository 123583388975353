// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  redirectUrl: "/",
  appVersion: require('../../package.json').version + '-' + require('../../package.json').buildNumber + '-dev',
  apiEndpoint: "https://dev-admin-coveredcare-api.azurewebsites.net/api/",
  appInsights: {
    instrumentationKey: "5d938af6-44be-41c9-824c-9446c90f3c39"
  }
};
