<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <form [formGroup]="loanForm" (ngSubmit)="saveLoanApp();">
    <div class="grid">
        <div class="col-6">
            <covered-form-text id="txtNLSPortName" placeholder="NLS Portfolio Name"
                formControlName="nlsLoanPortfolioName" [disabled]="true">
            </covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtLoanTemmplPre" placeholder="Loan Template Prefix"
                formControlName="loanTemplatePrefix" [disabled]="true">
            </covered-form-text>
        </div>
        <div class="col-12 grid">
            <div class="col-6">
            <covered-form-text id="txtLoanNumPre" placeholder="Loan Number Prefix"
                formControlName="loanNumberPrefix" [disabled]="true">
            </covered-form-text>
            </div>
        </div>
        <div class="col-6">
            <covered-form-text id="txtLoanAmt" placeholder="Loan Amount"
                [required]="true" formControlName="loanAmountTemplate"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtCampName" placeholder="Campaign Name"
                [required]="true" formControlName="campaignName"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtDomain" placeholder="Domain"
                [required]="true" formControlName="domain"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtCtrlFldr" placeholder="Customer control(s) Folder"
                [required]="true" formControlName="campaignCustomFolder"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtStartUrl" placeholder="Start URL"
                formControlName="startURL"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtCampFldr" placeholder="Campaign Folder"
                formControlName="campaignFolder"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtAcctDomain" placeholder="My Account Domain"
                formControlName="campaignMyAccountDomain"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtInvPgUrl" placeholder="Invitation Page URL"
                formControlName="campaignInvitationPageURL"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtWebInvDom" placeholder="Web Invite Domain"
                formControlName="campaignWebInviteDomain"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtAcctDefPg" placeholder="My Account Default Page"
                formControlName="campaignMyAccountDefaultPage"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtAdminDom" placeholder="Admin Domain"
                formControlName="adminDomain"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-input-number id="txtLogoutTime" placeholder="My Account Logout Timeout (Seconds)"
                mode="decimal" formControlName="logoutTimeout">
        </covered-input-number>
        </div>
        <div class="col-6">
            <covered-form-text id="txtMouseFlow" placeholder="Mouse Flow"
                formControlName="campaignMouseFlowTag"></covered-form-text>
        </div>
    </div>    
   
    <div class="flex flex-row-reverse">
        <covered-button id="btnSaveLoan" buttonType="submit" label="Save Loan App" [disabled]="loanForm.invalid"></covered-button>
    </div>
</form>
</div>