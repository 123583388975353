<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">Unable to load Rule Inventory.
</div>

<div *ngIf="!loading && !hasError && showRules">
    <span class="text-2xl font-medium">Decision Engine Inventory</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 pt-4">
                    <p-table #dt [value]="rules" styleClass="p-datatable-lg mr-4 mb-4 col-12" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rows]="100" [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <div class="flex justify-content-start">
                                    <span class="text-2xl font-medium">Rules</span>
                                </div>
                                <div *ngIf="hasEditFeature" class="flex flex-grow-1 justify-content-end">
                                    <covered-button id="btnAddMatrix" label="Add Rule" (buttonClick)="addRule()">
                                    </covered-button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr scope="row">
                                <th>Order</th>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Reason</th>
                                <th>Action</th>
                                <th># Rule Sets Using Rule</th>
                                <th># Active Rule Sets Using Rule</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rule>
                            <tr>
                                <td>
                                    {{rule.stepNo}}
                                </td>
                                <td [ngClass]="rule.ruleSetAvailable ? 'cds-blue-text' : 'cds-warn-text'">
                                    {{rule.ruleSetAvailable ? 'Available for Rule Sets' : 'Design'}}
                                </td>
                                
                                <td *ngIf="hasEditAccess" class="cursor-pointer" (click)="editRule(rule)"
                                    [ngClass]="rule.ruleSetAvailable ? 'cds-text-color' : 'cds-warn-text'">
                                    {{rule.ruleName}}
                                </td>
                                <td *ngIf="!hasEditAccess" [ngClass]="rule.ruleSetAvailable ? 'cds-text-color' : 'cds-warn-text'">
                                    {{rule.ruleName}}
                                </td>

                                <td [ngClass]="rule.ruleSetAvailable ? '' : 'cds-warn-text'">
                                    {{rule.ruleCategoryName}}
                                </td>
                                <td [ngClass]="rule.ruleSetAvailable ? '' : 'cds-warn-text'">
                                    {{rule.ruleReasonName}}
                                </td>
                                <td [ngClass]="rule.ruleSetAvailable ? '' : 'cds-warn-text'">
                                    {{rule.decisionType}}
                                </td>
                                <td [ngClass]="rule.ruleSetAvailable ? '' : 'cds-warn-text'">
                                    {{rule.rulesetsCount}}
                                </td>
                                <td [ngClass]="rule.ruleSetAvailable ? '' : 'cds-warn-text'">
                                    {{rule.rulesetsActiveCount}}
                                </td>
                                <td>
                                    <i *ngIf="hasEditAccess" class="pi pi-trash cursor-pointer" title="Delete" aria-hidden="true"
                                        (click)="confirmDeleteRule(rule)"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="9">No Rules found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && !hasError && showRuleEdit">
    <span class="text-2xl font-medium">{{ruleEditTitle}}</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <form *ngIf="ruleFormLoaded" [formGroup]="ruleForm" (ngSubmit)="saveRule()">
                <div class="grid">
                    <div class="col-6">
                        <span class="text-2xl font-medium">Rule {{ruleEditCardTitle}}</span>
                    </div>
                    <div class="col-6 flex flex-row-reverse">
                        <p-selectButton *ngIf="isInventory" [options]="availOptions" optionLabel="label"
                            optionValue="value" formControlName="available"></p-selectButton>
                        <p-selectButton *ngIf="!isInventory" [options]="activeOptions" optionLabel="label"
                            optionValue="value" formControlName="active"></p-selectButton>
                    </div>
                    <div class="col-12" *ngIf="!isInventory">
                        <p-checkbox id="chkBreakRule" [formControl]="$any(ruleForm.controls.isBreakRule)"
                            [binary]="true" label="Is Break Rule" (onChange)="breakRuleChange($event)"></p-checkbox>
                    </div>
                    <div class="col-6">
                        <covered-form-text id="txtName" placeholder="Rule Name" [required]="true"
                            formControlName="name">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!ruleForm.controls.name.pristine && ruleForm.controls.name.errors">
                                <p *ngIf="ruleForm.controls.name.errors?.required">Name is required
                                </p>
                            </div>
                        </covered-form-text>
                    </div>
                    <div class="col-6" *ngIf="!isBreakRule">&nbsp;</div>
                    <div class="col-6" *ngIf="!isBreakRule">
                        <covered-dropdown id="ddCategory" placeholder="Rule Category" [items]="lookups.categories"
                            optionLabel="ruleCategoryName" optionValue="ruleCategoryID" formControlName="category"
                            (optionSelected)="categorySelected($event)" [required]="true">
                        </covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-form-text *ngIf="ruleForm.value?.category == 0" id="txtCatName"
                            placeholder="Category Name" [required]="true" formControlName="categoryName">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!ruleForm.controls.categoryName.pristine && ruleForm.controls.categoryName.errors">
                                <p *ngIf="ruleForm.controls.categoryName.errors?.required">Category Name is required
                                </p>
                            </div>
                        </covered-form-text>
                        <span *ngIf="ruleForm.value?.category != 0">&nbsp;</span>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddRuleReason" placeholder="Rule Reason" [required]="true"
                            [items]="ruleReasons" optionLabel="ruleReasonName" optionValue="ruleReasonID"
                            formControlName="ruleReason"></covered-dropdown>
                    </div>
                    <div class="col-2 align-self-center">
                        <covered-button id="btnEditReason" label="Edit" class="outline" (buttonClick)="editReason()"
                            [disabled]="!ruleForm.value.ruleReason"></covered-button>
                    </div>

                    <div class="col-12 grid" *ngIf="!isBreakRule">
                        <div class="col-12">
                            <div class="text-xl font-medium">Rule <span
                                    class="cds-error-text font-bold text-lg ">*</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div *ngIf="!showComments">
                                <div class="cursor-pointer cds-text-color" (click)="showComments = true">Show Comments
                                </div>
                            </div>

                            <div *ngIf="showComments" class="grid">
                                <div class="col-12 pb-4 cursor-pointer cds-text-color" (click)="showComments = false;">
                                    Hide
                                    Comments</div>
                                <div class="col-6">
                                    <covered-form-textarea id="txtComments" [rows]="5" placeholder="Comments"
                                        formControlName="comments"></covered-form-textarea>
                                </div>
                            </div>
                        </div>
                        <div formArrayName="rules" class="col-12">
                            <div *ngFor="let rule of ruleForm.controls.rules.controls; index as i">
                                <div [formGroupName]="i" class="pb-5">
                                    <div class="cds-card px-4">
                                        <div class="grid">
                                            <div
                                                class="col rule-expression text-xl flex justify-content-center align-items-center">
                                                {{i == 0 ? 'IF' : 'and'}}
                                            </div>
                                            <div class="col">
                                                <covered-dropdown id="ddRlDS{{i}}" placeholder="Data Source"
                                                    [items]="lookups.dataSources" appendTo="body"
                                                    optionLabel="dataSourceName" optionValue="dataSourceID"
                                                    [formControl]="rule.controls.rule.controls.dataSource">
                                                </covered-dropdown>
                                            </div>
                                            <div class="col">
                                                <covered-autocomplete id="acRlDF{{i}}" placeholder="Field"
                                                    emptyMessage="No fields..." [showEmptyMessage]="true"
                                                    [suggestions]="ruleFieldSuggs" [completeMethod]="searchDataFields"
                                                    optionLabel="fieldName" [forceSelection]="true"
                                                    [formControl]="rule.controls.rule.controls.dataField">
                                                </covered-autocomplete>
                                            </div>
                                            <div class="col">
                                                <covered-dropdown id="ddRlFunc{{i}}" placeholder="Function"
                                                    [items]="getDataRuleFunctions(i, null)" appendTo="body"
                                                    optionLabel="functionName" optionValue="functionID"
                                                    [formControl]="rule.controls.rule.controls.function">
                                                </covered-dropdown>
                                            </div>
                                            <div class="col">
                                                <covered-dropdown id="ddRlDatType{{i}}" placeholder="Data Type"
                                                    [items]="lookups.dataTypes" appendTo="body"
                                                    optionLabel="fieldDataType" optionValue="fieldDataTypeID"
                                                    [formControl]="rule.controls.rule.controls.dataType">
                                                </covered-dropdown>
                                            </div>
                                            <div class="col">
                                                <covered-dropdown id="ddRlCriteria{{i}}" placeholder="Criteria"
                                                    [items]="lookups.compareOperators" appendTo="body"
                                                    optionLabel="comparisonOperator" optionValue="comparisonOperatorID"
                                                    [formControl]="rule.controls.rule.controls.criteria">
                                                </covered-dropdown>
                                            </div>
                                            <div class="col">
                                                <covered-form-text id="txtRlVal{{i}}"
                                                    [formControl]="rule.controls.rule.controls.value">
                                                </covered-form-text>
                                            </div>
                                            <div class="col flex justify-content-center align-items-center">
                                                <span class="px-4">
                                                    <i class="pi pi-tag cursor-pointer"
                                                        (click)="addTag(rule.controls.rule.controls.value)"></i>
                                                </span>
                                                <span class="px-4">
                                                    <span class="cursor-pointer" (click)="addOr(i)">OR</span>
                                                </span>
                                                <span class="px-4">
                                                    <i class="pi pi-trash cursor-pointer"
                                                        (click)="delRule(i, null)"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div formArrayName="ors">
                                        <div *ngFor="let or of rule.controls.ors.controls; index as j">
                                            <div [formGroupName]="j">
                                                <div class="cds-card px-4 ml-8">
                                                    <div class="grid">
                                                        <div
                                                            class="col rule-expression text-xl flex justify-content-center align-items-center">
                                                            or
                                                        </div>
                                                        <div class="col">
                                                            <covered-dropdown id="ddRlDS{{i}}{{j}}"
                                                                placeholder="Data Source" [items]="lookups.dataSources"
                                                                appendTo="body" optionLabel="dataSourceName"
                                                                optionValue="dataSourceID" formControlName="dataSource">
                                                            </covered-dropdown>
                                                        </div>
                                                        <div class="col">
                                                            <covered-autocomplete id="acRlDF{{i}}-{{j}}"
                                                                placeholder="Field" emptyMessage="No fields..."
                                                                [showEmptyMessage]="true" [suggestions]="ruleFieldSuggs"
                                                                [completeMethod]="searchDataFields"
                                                                optionLabel="fieldName" [forceSelection]="true"
                                                                formControlName="dataField">
                                                            </covered-autocomplete>
                                                        </div>
                                                        <div class="col">
                                                            <covered-dropdown id="ddRlFunc{{i}}{{j}}"
                                                                placeholder="Function"
                                                                [items]="getDataRuleFunctions(i, j)" appendTo="body"
                                                                optionLabel="functionName" optionValue="functionID"
                                                                formControlName="function"></covered-dropdown>
                                                        </div>
                                                        <div class="col">
                                                            <covered-dropdown id="ddRlDatType{{i}}{{j}}"
                                                                placeholder="Data Type" [items]="lookups.dataTypes"
                                                                appendTo="body" optionLabel="fieldDataType"
                                                                optionValue="fieldDataTypeID"
                                                                formControlName="dataType">
                                                            </covered-dropdown>
                                                        </div>
                                                        <div class="col">
                                                            <covered-dropdown id="ddRlCriteria{{i}}{{j}}"
                                                                placeholder="Criteria"
                                                                [items]="lookups.compareOperators" appendTo="body"
                                                                optionLabel="comparisonOperator"
                                                                optionValue="comparisonOperatorID"
                                                                formControlName="criteria"></covered-dropdown>
                                                        </div>
                                                        <div class="col">
                                                            <covered-form-text id="txtRlVal{{i}}{{j}}"
                                                                formControlName="value"></covered-form-text>
                                                        </div>
                                                        <div class="col flex justify-content-center align-items-center">
                                                            <span class="px-4">
                                                                <i class="pi pi-tag cursor-pointer"
                                                                    (click)="addTag(or.controls.value)"></i>
                                                            </span>
                                                            <span class="px-4">
                                                                <i class="pi pi-trash cursor-pointer"
                                                                    (click)="delRule(i, j)"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-1 cursor-pointer cds-text-color" (click)="addNewRule()">Add New Rule</div>
                    </div>
                    <div class="col-12">
                        <span class="mr-5">Retry until above rule contains valid response?</span>
                        <p-radioButton name="retry" [value]="true" label="Yes" formControlName="retry" class="mr-4">
                        </p-radioButton>
                        <p-radioButton name="retry" [value]="false" label="No" formControlName="retry" class="mr-7">
                        </p-radioButton>
                    </div>
                    <div *ngIf="ruleForm.value.retry" class="col-6 grid animate__animated animate__backInLeft">
                        <div class="col">
                            <covered-input-number mode="decimal" id="txtRetryIntv"
                                placeholder="Retry Interval (miliseconds)" formControlName="retryInterval">
                            </covered-input-number>
                        </div>
                        <div class="col">
                            <covered-input-number mode="decimal" id="txtRetryTimeout"
                                placeholder="Retry Timeout (seconds)" formControlName="retryTimeout">
                            </covered-input-number>
                        </div>
                    </div>
                    <div class="col-12 grid" *ngIf="!isBreakRule">
                        <div class="col-12">
                            <div class="text-xl font-medium">If Meets Rule Then <span
                                    class="cds-error-text font-bold text-lg ">*</span></div>
                        </div>
                        <div class="col-12 grid">
                            <div class="col">
                                <covered-dropdown id="ddIfMeetsAction" [items]="lookups.ruleActions"
                                    placeholder="Select Action" optionLabel="ruleAction" optionValue="ruleActionID"
                                    [disabled]="isInventory" formControlName="successActionId">
                                </covered-dropdown>
                            </div>
                            <div class="col" *ngIf="ruleForm.value.successActionId == 5">
                                <covered-dropdown id="ddIfGoTo" placeholder="Select GoTo" [items]="resumeRules"
                                    optionLabel="ruleName" optionValue="ruleID" [disabled]="isInventory"
                                    formControlName="successGoToId"></covered-dropdown>
                            </div>
                            <div class="col">
                                <covered-dropdown id="ddIfMeetsTrigger" [items]="lookups.ruleTriggers"
                                    placeholder="Select Trigger" optionLabel="ruleTriggerName"
                                    optionValue="ruleTriggerID" [disabled]="isInventory"
                                    formControlName="successTriggerId"></covered-dropdown>
                            </div>
                        </div>
                        <div formArrayName="successVars" class="col-12" *ngIf="isInventory">
                            <div *ngFor="let var of ruleForm.controls.successVars.controls; index as i">
                                <div class="flex align-items-center" [formGroupName]="i">
                                    <div class="px-4">Set Variable</div>
                                    <div class="px-4 w-4">
                                        <covered-dropdown id="ddIfRuleFields{{i}}" [items]="ruleFields"
                                            optionLabel="fieldName" optionValue="fieldID" formControlName="fieldId">
                                        </covered-dropdown>
                                    </div>
                                    <div>TO</div>
                                    <div class="px-4 w-4">
                                        <covered-form-text id="txtIfRuleVal{{i}}" formControlName="fieldValue">
                                        </covered-form-text>
                                    </div>
                                    <div>
                                        <span class="px-4">
                                            <i class="pi pi-tag cursor-pointer"
                                                (click)="addTag(ruleForm.controls.successVars.at(i).controls.fieldValue)"></i>
                                        </span>
                                        <span class="px-4" *ngIf="showAddSetVar(ruleForm.controls.successVars, i)">
                                            <i class="pi pi-plus-circle cursor-pointer"
                                                (click)="addSetVarRow(ruleForm.controls.successVars)"></i>
                                        </span>
                                        <span class="px-4" *ngIf="showDelSetVar(ruleForm.controls.successVars, i)">
                                            <i class="pi pi-trash cursor-pointer"
                                                (click)="removeSetVarRow(ruleForm.controls.successVars)"></i>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 grid" *ngIf="!isBreakRule">
                        <div class="col-12">
                            <div class="text-xl font-medium">Else <span
                                    class="cds-error-text font-bold text-lg ">*</span>
                            </div>
                        </div>
                        <div class="col-12 grid">
                            <div class="col">
                                <covered-dropdown id="ddElseMeetsAction" [items]="lookups.ruleActions"
                                    placeholder="Select Action" optionLabel="ruleAction" optionValue="ruleActionID"
                                    [disabled]="isInventory" formControlName="failActionId">
                                </covered-dropdown>
                            </div>

                            <div class="col" *ngIf="ruleForm.value.failActionId == 5">
                                <covered-dropdown id="ddElseGoTo" placeholder="Select GoTo" [items]="resumeRules"
                                    optionLabel="ruleName" optionValue="ruleID" [disabled]="isInventory"
                                    formControlName="failGoToId"></covered-dropdown>
                            </div>
                            <div class="col">
                                <covered-dropdown id="ddElseMeetsTrigger" [items]="lookups.ruleTriggers"
                                    placeholder="Select Trigger" optionLabel="ruleTriggerName"
                                    optionValue="ruleTriggerID" [disabled]="isInventory"
                                    formControlName="failTriggerId"></covered-dropdown>
                            </div>
                        </div>
                        <div formArrayName="failVars" class="col-12" *ngIf="isInventory">
                            <div *ngFor="let var of ruleForm.controls.failVars.controls; index as i">
                                <div class="flex align-items-center" [formGroupName]="i">
                                    <div class="px-4">Set Variable</div>
                                    <div class="px-4 w-4">
                                        <covered-dropdown id="ddElseRuleFields{{i}}" [items]="ruleFields"
                                            optionLabel="fieldName" optionValue="fieldID" formControlName="fieldId">
                                        </covered-dropdown>
                                    </div>
                                    <div>TO</div>
                                    <div class="px-4 w-4">
                                        <covered-form-text id="txtElseRuleVal{{i}}" formControlName="fieldValue">
                                        </covered-form-text>
                                    </div>
                                    <div>
                                        <span class="px-4">
                                            <i class="pi pi-tag cursor-pointer"
                                                (click)="addTag(ruleForm.controls.failVars.at(i).controls.fieldValue)"></i>
                                        </span>
                                        <span class="px-4" *ngIf="showAddSetVar(ruleForm.controls.failVars, i)">
                                            <i class="pi pi-plus-circle cursor-pointer"
                                                (click)="addSetVarRow(ruleForm.controls.failVars)"></i>
                                        </span>
                                        <span class="px-4" *ngIf="showDelSetVar(ruleForm.controls.failVars, i)">
                                            <i class="pi pi-trash cursor-pointer"
                                                (click)="removeSetVarRow(ruleForm.controls.failVars)"></i>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 grid grid-nogutter pt-4" *ngIf="isBreakRule">
                        <div class="col-6">
                            <covered-form-textarea id="txtDescription" [rows]="5" placeholder="Description"
                                formControlName="description"></covered-form-textarea>
                        </div>
                        <div class="col-12 grid">
                            <div class="col-3">
                                <covered-dropdown id="ddRuleColor" placeholder="Listing Color"
                                    [items]="lookups.separatorColors" optionLabel="colorName" optionValue="ruleColorID"
                                    formControlName="ruleColor" [showFirst]="true"></covered-dropdown>
                            </div>
                            <div class="col-9 align-self-center" [ngClass]="getSampleColors()">This is an example text</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddResume" placeholder="Resume Rule" [items]="resumeRules"
                            optionLabel="ruleName" optionValue="ruleID" formControlName="resumeRuleId"></covered-dropdown>
                    </div>
                </div>
                <div class="flex flex-row-reverse">
                    <covered-button *ngIf="editMode && hasEditFeature" id="btnSaveRule" buttonType="submit" [disabled]="!ruleForm.valid">
                        <span class="px-4">Save</span>
                    </covered-button>
                    <covered-button id="btnCancelRule" class="outline px-4" (buttonClick)="cancelRule()">
                        <span>Cancel</span>
                    </covered-button>
                    <covered-button id="btnTestForm" label="Test" class="outline pr-4" (buttonClick)="testFormValidity()"></covered-button>
                </div>
            </form>
        </div>
    </div>
</div>

<covered-dialog [isVisible]="showAddTag" header="Add Tag" headerClass="text-2xl" [showHeader]="true" [isModal]="true"
    [isClosable]="true" (HideClick)="showAddTag=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="w-12">
            <covered-dropdown id="ddTagSource" placeholder="Data Source" [items]="lookups.dataSources" appendTo="body"
                optionLabel="dataSourceName" (optionSelected)="getTagFields($event)" [(ngModel)]="tagDataSource">
            </covered-dropdown>
        </div>
        <div class="w-12 pt-4">
            <covered-dropdown id="ddTagField" placeholder="Field" [items]="dataSourceFields" appendTo="body"
                optionLabel="fieldName" [(ngModel)]="tagDataField">
            </covered-dropdown>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveTag" label="Save" (buttonClick)="saveTag();"
                [disabled]="!tagDataSource || !tagDataField"></covered-button>
            <covered-button id="bthCloseTag" label="Close" class="outline pr-4" (buttonClick)="closeAddTag()">
            </covered-button>
        </div>
    </div>
</covered-dialog>
<p-confirmDialog [key]="deRulesConfirmKey" [style]="{width: '45vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-outlined" styleClass="text-2xl">
</p-confirmDialog>