import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppConfigs } from 'src/app/configs/app-config';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { CommunicationFormGroup } from 'src/app/models/form-groups';
import { ProviderInfo } from 'src/app/models/provider-info';
import { SelectTime } from 'src/app/models/select-time';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-communication',
  templateUrl: './provider-communication.component.html',
  styleUrls: ['./provider-communication.component.scss']
})
export class ProviderCommunicationComponent implements OnInit {

  datePipe: DatePipe = new DatePipe('en-US');
  private _provider: CustomerProviderModel | null = null;
  @Input() set provider(value: CustomerProviderModel | null) { this._provider = value; }
  get provider(): CustomerProviderModel | null { return this._provider; }
  
  providerInfo: ProviderInfo | null = null;
  commForm: FormGroup<CommunicationFormGroup> = {} as FormGroup;

  formInStorage: boolean = false;

  smsOptions: any[] = [{label: 'Yes', value: true}, {label: 'No', value: false}]
  times: SelectTime[] = [];

  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.times = this.helperService.times(0, 24, 30);
    this.times.push({display: '11:59 PM', value: '23:59:00'});
    this.createForm();
    this.loadFromStorage();

    let provInfoSub = this.providerService.infoSectionProvider$
      .subscribe({
        next: (provider: ProviderInfo | null) => { 
          this.providerInfo = provider;
          if (!this.formInStorage) {
            this.loadProviderComms();
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Provider information. See log for details'
          });
          console.error(err);
        },
        complete: () => { provInfoSub.unsubscribe(); }
      });


    this.commForm.valueChanges
    .subscribe(value => { 
      localStorage.setItem(AppConfigs.providerCommForm, JSON.stringify(this.commForm.value));
    });

  }

  createForm() {
    this.commForm = new FormGroup<CommunicationFormGroup>({
      notificationFromEmail: new FormControl<string>('', { nonNullable: true }),
      notificationFromDisplayName: new FormControl<string>('', { nonNullable: true }),
      notificationReplyEmail: new FormControl<string>('', { nonNullable: true }),
      notificationReplyDisplayName: new FormControl<string>('', { nonNullable: true }),
      contactEmail: new FormControl<string>('', { nonNullable: true }),
      supportEmail: new FormControl<string>('', { nonNullable: true }),
      contactPhone: new FormControl<string>('', { nonNullable: true }),
      contactFax: new FormControl<string>('', { nonNullable: true }),
      paymentMailingAddress: new FormControl<string>('', { nonNullable: true }),
      supportHours: new FormControl<string>('', { nonNullable: true }),
      enableSMS: new FormControl<boolean>(true, { nonNullable: true }),
      apiKey: new FormControl<string>('', { nonNullable: true }),
      orgCode: new FormControl<string>('', { nonNullable: true }),
      orgCodeComm: new FormControl<string>('', { nonNullable: true }),
      orgCodeHelp: new FormControl<string>('', { nonNullable: true }),
      orgCodeMobile: new FormControl<string>('', { nonNullable: true }),
      commEndTime: new FormControl<string|null>(null, { nonNullable: true }),
      commStartTime: new FormControl<string|null>(null, { nonNullable: true }),
    });
  }

  loadFromStorage() {
    this.formInStorage = false;
    let storage = localStorage.getItem(AppConfigs.providerCommForm);
    if (storage) {
      this.formInStorage = true;
      this.commForm.setValue(JSON.parse(storage));
    }    
  }

  loadProviderComms() {    
    this.commForm.patchValue({
      notificationFromEmail: this.providerInfo?.notificationFromEmail,
      notificationFromDisplayName: this.providerInfo?.notificationFromDisplayName,
      notificationReplyEmail: this.providerInfo?.notificationReplyEmail,
      notificationReplyDisplayName: this.providerInfo?.notificationReplyDisplayName,
      contactEmail: this.providerInfo?.contact_Email || '',
      supportEmail: this.providerInfo?.contact_Email_Support || '',
      contactPhone: this.providerInfo?.contact_Phone || '',
      contactFax: this.providerInfo?.contact_Fax || '',
      paymentMailingAddress: this.providerInfo?.paymentMailingAddress || '',
      supportHours: this.providerInfo?.supportHours || '',
      enableSMS: this.providerInfo?.communicationShowSMS,
      apiKey: this.providerInfo?.sbT_ApiKey,
      orgCode: this.providerInfo?.sbT_OrgCode,
      orgCodeComm: this.providerInfo?.sbT_OrgCodeCommunication || '',
      orgCodeHelp: this.providerInfo?.sbT_OrgCodeHelp || '',
      orgCodeMobile: this.providerInfo?.sbT_OrgCodeMobile || '',
      commStartTime: this.datePipe.transform(this.providerInfo?.communicationStartTime, "HH:mm:ss"),
      commEndTime: this.datePipe.transform(this.providerInfo?.communicationEndTime, "HH:mm:ss")                
    });
  }

  saveComms() {
    let provider = {...this.providerInfo};
    provider.notificationFromEmail = this.commForm.value.notificationFromEmail;
    provider.notificationFromDisplayName = this.commForm.value.notificationFromDisplayName;
    provider.notificationReplyEmail = this.commForm.value.notificationReplyEmail;
    provider.notificationReplyDisplayName = this.commForm.value.notificationReplyDisplayName;
    provider.contact_Email = this.commForm.value.contactEmail;
    provider.contact_Email_Support = this.commForm.value.contactEmail;
    provider.contact_Phone = this.commForm.value.contactPhone;
    provider.contact_Fax = this.commForm.value.contactFax;
    provider.paymentMailingAddress = this.commForm.value.paymentMailingAddress;
    provider.supportHours = this.commForm.value.supportHours;
    provider.communicationShowSMS = this.commForm.value.enableSMS;
    provider.sbT_ApiKey = this.commForm.value.apiKey;
    provider.sbT_OrgCode = this.commForm.value.orgCode;
    provider.sbT_OrgCodeCommunication = this.commForm.value.orgCodeComm;
    provider.sbT_OrgCodeHelp = this.commForm.value.orgCodeHelp;
    provider.sbT_OrgCodeMobile = this.commForm.value.orgCodeMobile;
    provider.communicationStartTime = this.getCommTime(this.commForm.value.commStartTime);
    provider.communicationEndTime = this.getCommTime(this.commForm.value.commEndTime);
    
    let commSub = this.apiService.post('provider/communications', provider)
    .subscribe({
      next: () => { 
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Provider Communication successfully updated.'
        });
        this.providerService.loadInfoSectionProvider((this.provider?.customerGuid || ''));
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Provider communications. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { commSub.unsubscribe(); }
    })

  }

  getCommTime(commTime: string | null | undefined): Date | undefined {    
    if (commTime == null || commTime == undefined) return undefined;

    let timeArr = commTime.split(':');
    let tempDt = new Date(); 
    tempDt.setHours(+timeArr[0]);
    tempDt.setMinutes(+timeArr[1]);
    tempDt.setSeconds(0);

    let utcDate = new Date(Date.UTC(
      tempDt.getFullYear(),
      tempDt.getMonth(),
      tempDt.getDate(),
      tempDt.getHours(),
      tempDt.getMinutes(),
      tempDt.getSeconds(),
      tempDt.getMilliseconds()
    ));
    return utcDate;
  }

}
