<div>
    <span class="text-2xl font-medium">Decision Engine Line Matrixes</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>

    <div class="mt-4">
        <div class="cds-card w-screen-fit p-2">
            <div *ngIf="hasEditFeature" class="d-flex flex-row-reverse">
                <div class="p-2">
                    <covered-button id="btnAddNew" label="Add Line Matrix" class="" (buttonClick)="addLine()">
                    </covered-button>
                </div>
            </div>            
            <div *ngIf="!loading">
                <p-table #dt [value]="matrixes" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                    [globalFilterFields]="filterFields" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    responsiveLayout="scroll" [rows]="100" [rowHover]="true" [columns]="columns">
                    <ng-template pTemplate="header">
                        <tr>
                            <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                                <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                                    class="font-medium" scope="col">{{ col.header }}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                            </ng-template>
                            <th scope="col" *ngIf="hasEditFeature"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>{{rowData.orderNo}}</td>
                            <td>{{rowData.dELineMatrixName}}</td>
                            <td>{{rowData.ruleSetsNo}}</td>
                            <td>{{rowData.ruleSetsActiveNo}}</td>
                            <td *ngIf="hasEditFeature">
                                <p class="m-0 p-0">
                                    <i class="pi pi-pencil cursor-pointer mr-4" title="Edit" aria-hidden="true" (click)="editLine(rowData)"></i>
                                    <i class="pi pi-trash cursor-pointer" title="Delete" aria-hidden="true" (click)="deleteLine(rowData)"></i>
                                </p>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [colSpan]="hasEditFeature ? 5 : 4">No Line Matrices found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog key="key11" [style]="{width: '45vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>