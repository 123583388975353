import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TableColumn } from '@covered/covered-ui';
import { BranchModel } from 'src/app/models/branch-model';
import { CustomerSettlementWithBranch } from 'src/app/models/branch-settlement';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { BranchFormGroup, BranchSettlementFormGroup } from 'src/app/models/form-groups';
import { ProviderInfo } from 'src/app/models/provider-info';
import { ProviderLocation, ProviderLocationUpload } from 'src/app/models/provider-location';
import { ProviderRegion, ProviderRegionUpload, ProviderSubRegion, ProviderSubRegionUpload } from 'src/app/models/provider-region';
import { State } from 'src/app/models/states';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomGridComponent } from '../../custom-grid/custom-grid.component';

@Component({
  selector: 'app-provider-location',
  templateUrl: './provider-location.component.html',
  styleUrls: ['./provider-location.component.scss']
})
export class ProviderLocationComponent implements OnInit, AfterViewInit {

  private _provider: CustomerProviderModel | null = null;

  @Input() set provider(value: CustomerProviderModel | null) {
    this._provider = value;
    this.getRegions();
  }
  get provider(): CustomerProviderModel | null { return this._provider; }

  providerInfo: ProviderInfo | null = null;
  lookups: ICustomerLookups = {} as ICustomerLookups;
  btnAllyLocationsDisabled: boolean = true;
  allyEnabled: boolean = false;
  showBranchList: boolean = false;
  showStateDD: boolean = true;
  showBranch: boolean = false;
  showSettlement: boolean = false;
  showSettlementsTable: boolean = false;
  showAddRegion: boolean = false;
  showAddSubRegion: boolean = false;
  branchFormLoaded: boolean = false;
  settlementFormLoaded: boolean = false;
  branchTitle: string = '';
  branchSettlementTitle: string = '';
  newRegion: string | null = null;
  newSubRegion: string | null = null;
  selectedProviderLocation: ProviderLocation | null = null;
  providerRegions: ProviderRegion[] = [];
  subRegions: ProviderSubRegion[] = [];
  states: State[] = [];
  customerSettlements: CustomerSettlementWithBranch[] = [];

  settlementColumns: TableColumn[] = [];
  settlementFilterFields: string[] = [];

  branchForm: FormGroup<BranchFormGroup> = {} as FormGroup;
  branchSettlementForm: FormGroup<BranchSettlementFormGroup> = {} as FormGroup;

  @ViewChild('cgBranches') cgBranches: CustomGridComponent = {} as CustomGridComponent;
  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.showBranchList = true;
    this.states = this.helperService.usStates();
    this.providerService.customerLookups$
      .subscribe({
        next: (lookups: ICustomerLookups) => {
          this.lookups = lookups;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Provider information. See log for details'
          });
          console.error(err);
        }
      });
      
      let provInfoSub = this.providerService.infoSectionProvider$
      .subscribe({
        next: (provider: ProviderInfo | null) => { 
          this.providerInfo = provider;     
          if (this.providerInfo) {
            this.allyEnabled = this.providerInfo.isAllyEnabled;
            this.btnAllyLocationsDisabled = false;
          }     
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Provider information. See log for details'
          });
          console.error(err);
        },
        complete: () => { provInfoSub.unsubscribe(); }
      });

  }

  ngAfterViewInit(): void {
    if (this.provider) {
      this.cgBranches.SearchXML = `<params><f fn=\"EditCustomerGUID\" fv=\"${this.provider.customerGuid}\" /></params>`;
      this.cgBranches.setCustomerInfo(this.provider.customerGuid, null);
    }        
  }

  rowSelected(row: ProviderLocation) {
    this.selectedProviderLocation = row;

    this.createBranchSettlementForm();
    this.branchSettlementForm.patchValue({
      location: row.Name || `${row.City}, ${row.State}`,
      customerGuid: this.provider?.customerGuid,
      branchGuid: row.BranchGUID
    });


    if (row.BranchSettlementGUID && row.BranchSettlementGUID.length > 10) {
      this.branchSettlementTitle = "Edit";
      this.apiService.get(`provider/branch-settlement/${row.BranchSettlementGUID}`)
        .subscribe((customerSettlement: CustomerSettlementWithBranch) => {
          this.branchSettlementForm.patchValue({
            branchSettlementGuid: customerSettlement.branchSettlementGUID,
            branchGuid: customerSettlement.branchGUID,
            customerGuid: customerSettlement.customerGuid,
            bankName: customerSettlement.bankName,
            accountName: customerSettlement.accountName,
            bankAccount: customerSettlement.bankAccount,
            bankAccountType: customerSettlement.bankAccountType,
            bankRouting: customerSettlement.bankRouting,
            bankWireInst: customerSettlement.bankWiringInstr,
            contactName: customerSettlement.contactName,
            contactPhone: customerSettlement.contactPhone,
            contactEmail: customerSettlement.contactEmail,
            lockbox: customerSettlement.lockBox
          });

          this.settlementFormLoaded = true;
          this.showSettlement = true;
        });
    }
    else {
      this.branchSettlementTitle = "Add New";
      this.settlementFormLoaded = true;
      this.showSettlement = true;
    }

  }

  createBranchSettlementForm() {
    this.branchSettlementForm = new FormGroup<BranchSettlementFormGroup>({
      branchSettlementGuid: new FormControl<string | null>(null, { nonNullable: false }),
      branchGuid: new FormControl<string>('', { nonNullable: true }),
      customerGuid: new FormControl<string>('', { nonNullable: true }),
      location: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      bankName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      accountName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      bankAccount: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      bankAccountType: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      bankRouting: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      bankWireInst: new FormControl<string>('none', { nonNullable: true, validators: [Validators.required] }),
      contactName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      contactPhone: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      contactEmail: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      lockbox: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
    });
  }

  addNewLocation() {
    this.selectedProviderLocation = null;
    this.createBranchForm();
    this.branchTitle = "Add";
    this.branchFormLoaded = true;
    this.showBranch = true;
  }

  editLocation(row: ProviderLocation) {
    this.selectedProviderLocation = row;
    this.createBranchForm();
    this.branchTitle = "Edit";
    this.apiService.get(`provider/branch/${row.BranchGUID}`)
      .subscribe((branch: BranchModel) => {
        let country = this.lookups.countries.find(c => c.desc.toLowerCase() == branch.branchCountry?.toLowerCase());
        let state = branch.branchState || undefined;
        if (country && country.id == "1") {
          state = this.states.find(s => s.name == state)?.abbr;
        }
        this.subRegions = this.providerRegions.find(r => r.providerRegionID == branch.providerRegionID)?.subRegions || [];
        this.branchForm.patchValue({
          country: country?.id,
          region: branch.providerRegionID,
          subRegion: branch.providerSubRegionID,
          locationId: branch.branch_ID,
          locationName: branch.branchName,
          address1: branch.branchAddress1 || '',
          address2: branch.branchAddress2 || '',
          city: branch.branchCity || '',
          state: state,
          zip: branch.branchZip || '',
          contactName: branch.branchContact || '',
          contactPhone: branch.branchPhone || '',
          contactEmail: branch.branchEmail || '',
          website: branch.branchWebsite || '',
          allyOfficeId: branch.ally_OfficeId || null
        });

        this.branchFormLoaded = true;
        this.showBranch = true;
      });
  }

  createBranchForm() {
    this.branchForm = new FormGroup<BranchFormGroup>({
      country: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      region: new FormControl<number>(0, { nonNullable: true }),
      subRegion: new FormControl<number>(0, { nonNullable: true }),
      locationId: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      locationName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      address1: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      address2: new FormControl<string>('', { nonNullable: true }),
      city: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      state: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      zip: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      contactName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      contactPhone: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      contactEmail: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      website: new FormControl<string>('', { nonNullable: true }),
      allyOfficeId: new FormControl<string|null>(null, {nonNullable:true})
    });

    if (this.providerInfo && this.providerInfo.isAllyEnabled) {
      let officeId = this.branchForm.controls.allyOfficeId;
      officeId.setValidators([Validators.required, Validators.maxLength(100)]);
    }
  }

  toggelShowState(countryId: number) {
    this.showStateDD = countryId == 1;
  }

  getRegions() {
    if (this.provider != null) {
      this.providerService.getRegions(this.provider.customerGuid)
        .subscribe((regions: ProviderRegion[]) => {
          this.providerRegions = regions;
        });
    }
  }

  setSubRegions(regionId: number) {
    this.subRegions = this.providerRegions.find(r => r.providerRegionID == regionId)?.subRegions || [];
  }

  openRegionAdd() {
    this.newRegion = null;
    this.showAddRegion = true;
  }

  openSubRegionAdd() {
    if (!this.branchForm.value.region) {
      this.toastService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must select a Region first'
      });
      return;
    }

    this.newSubRegion = null;
    this.showAddSubRegion = true;
  }

  showAllSettlements() {
    let settleSub = this.providerService.getAllSettlements(this.provider?.customerGuid)
      .subscribe({
        next: (settlements: CustomerSettlementWithBranch[]) => {
          this.customerSettlements = settlements.sort((a, b) => { return a.accountName.toLowerCase() > b.accountName.toLowerCase() ? 1 : -1; });
          this.setupSettlementTable();
          this.showBranchList = false;
          this.showSettlementsTable = true;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Customer Settlements. See log for details.'
          });
          console.error(err);
        },
        complete: () => { settleSub.unsubscribe(); }
      });

  }

  editSettlementRecord(row: CustomerSettlementWithBranch) {
    this.branchSettlementTitle = "Edit";
    this.createBranchSettlementForm();
    this.branchSettlementForm.patchValue({
      branchSettlementGuid: row.branchSettlementGUID,
      branchGuid: row.branchGUID,
      customerGuid: row.customerGuid,
      location: row.branchName,
      bankName: row.bankName,
      accountName: row.accountName,
      bankAccount: row.bankAccount,
      bankAccountType: row.bankAccountType,
      bankRouting: row.bankRouting,
      bankWireInst: row.bankWiringInstr,
      contactName: row.contactName,
      contactPhone: row.contactPhone,
      contactEmail: row.contactEmail,
      lockbox: row.lockBox
    });

    this.settlementFormLoaded = true;
    this.showSettlement = true;

  }

  backToBranchList() {
    this.showBranchList = true;
    this.showSettlementsTable = false;
    let tempProvider = this.provider;
    this.provider = null;
    this.provider = tempProvider;

    let i: number = 0;
    let componentLoaded: boolean = false;
    while (i < 5 && !componentLoaded) {
      setTimeout(() => {
        if (this.provider && this.cgBranches) {
          this.cgBranches.SearchXML = `<params><f fn=\"EditCustomerGUID\" fv=\"${this.provider.customerGuid}\" /></params>`;
          this.cgBranches.setCustomerInfo(this.provider.customerGuid, null);
          componentLoaded = true;
        }
      }, 100);
      i++;
    }
  }

  saveBranch() {
    let branch: ProviderLocationUpload = new ProviderLocationUpload();

    if (this.selectedProviderLocation) {
      branch.branchGuid = this.selectedProviderLocation.BranchGUID;
    }
    branch.customerGuid = this.provider?.customerGuid || '';
    branch.branchId = this.branchForm.value.locationId || '';
    branch.branchName = this.branchForm.value.locationName || '';
    branch.branchAddress1 = this.branchForm.value.address1 || null;
    branch.branchAddress2 = this.branchForm.value.address2 || null;
    branch.branchCity = this.branchForm.value.city || null;
    branch.branchZip = this.branchForm.value.zip || null;
    branch.branchCountry = this.lookups.countries.find(c => c.id == this.branchForm.value.country)?.desc || null;
    if (this.branchForm.value.country == '1') {
      branch.branchState = this.states.find(s => s.abbr == this.branchForm.value.state)?.name || null;
    }
    else {
      branch.branchState = this.branchForm.value.state || null;
    }
    branch.branchContact = this.branchForm.value.contactName || null;
    branch.branchPhone = this.branchForm.value.contactPhone || null;
    branch.branchEmail = this.branchForm.value.contactEmail || null;
    branch.branchWebsite = this.branchForm.value.website || null;
    branch.regionGuid = this.providerRegions.find(r => r.providerRegionID == this.branchForm.value.region)?.providerRegionGuid || null;
    branch.subRegionGuid = this.subRegions.find(s => s.providerRegionID == this.branchForm.value.subRegion)?.providerSubRegionGuid || null;
    branch.allyOfficeId = this.branchForm.value.allyOfficeId ?? null;

    let branchSub = this.apiService.post(`provider/branch`, branch)
      .subscribe({
        next: () => {
          this.showSettlement = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Location successfully added / updated'
          });
          this.showBranch = false;
          this.cgBranches.getDataInfo();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save location. See log for details.'
          });
          console.error(err);
        },
        complete: () => { branchSub.unsubscribe(); }
      });

  }

  saveSettlement() {
    let settleBody: CustomerSettlementWithBranch = new CustomerSettlementWithBranch();
    settleBody.accountName = this.branchSettlementForm.value.accountName || '';
    settleBody.bankAccount = this.branchSettlementForm.value.bankAccount || '';
    settleBody.bankAccountType = this.branchSettlementForm.value.bankAccountType || '';
    settleBody.bankName = this.branchSettlementForm.value.bankName || '';
    settleBody.bankRouting = this.branchSettlementForm.value.bankRouting || '';
    settleBody.bankWiringInstr = this.branchSettlementForm.value.bankWireInst || '';
    settleBody.branchGUID = this.branchSettlementForm.value.branchGuid || '';
    settleBody.branchSettlementGUID = this.branchSettlementForm.value.branchSettlementGuid || null;
    settleBody.customerGuid = this.branchSettlementForm.value.customerGuid || '';
    settleBody.contactName = this.branchSettlementForm.value.contactName || '';
    settleBody.contactEmail = this.branchSettlementForm.value.contactEmail || '';
    settleBody.contactPhone = this.branchSettlementForm.value.contactPhone || '';
    settleBody.lockBox = this.branchSettlementForm.value.lockbox || '';

    let settleSub = this.apiService.post(`provider/branch-settlement`, settleBody)
      .subscribe({
        next: () => {
          this.showSettlement = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Settlement successfully added / updated'
          });
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save settlement. See log for details.'
          });
          console.error(err);
        },
        complete: () => { settleSub.unsubscribe(); }
      });

  }

  saveRegion() {

    let regionGuid = this.providerRegions.find(r => r.providerRegionID == this.branchForm.value.region)?.providerRegionGuid;
    let region: ProviderRegionUpload = new ProviderRegionUpload();
    region.customerGuid = this.provider?.customerGuid || '';
    region.regiondesc = this.newRegion || '';
    let regionSub = this.apiService.post(`provider/region`, region)
      .subscribe({
        next: () => {
          this.showAddRegion = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Region successfully added'
          });
          this.getRegions();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save region. See log for details.'
          });
          console.error(err);
        },
        complete: () => { regionSub.unsubscribe(); }
      });
  }

  saveSubregion() {
    let regionGuid = this.providerRegions.find(r => r.providerRegionID == this.branchForm.value.region)?.providerRegionGuid;
    let subregion: ProviderSubRegionUpload = new ProviderSubRegionUpload();
    subregion.customerGuid = this.provider?.customerGuid || '';
    subregion.regionGuid = regionGuid || '';
    subregion.subRegiondesc = this.newSubRegion || '';
    let subregionSub = this.apiService.post(`provider/subregion`, subregion)
      .subscribe({
        next: () => {
          this.showAddSubRegion = false;
          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'SubRegion successfully added'
          });
          this.getRegions();
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to save subregion. See log for details.'
          });
          console.error(err);
        },
        complete: () => { subregionSub.unsubscribe(); }
      });

  }

  getAllyLocations() {
    let ofcSub = this.apiService.get(`provider/${this.provider?.customerGuid}/ally-locations`)
    .subscribe({
      next: () => {
        this.toastService.add({
          severity: 'success',
          detail: 'Request has been successfully submitted. Please allow up to 10 min. for locations to show.'
        });
        this.btnAllyLocationsDisabled = true;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error submitting request. Please see log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { ofcSub.unsubscribe(); }
    })
  }

  setupSettlementTable() {
    this.settlementFilterFields = ['branchName', 'branch_ID', 'bankName', 'accountName', 'bankAccount', 'bankAccountType', 'bankRouting'];
    this.settlementColumns = [
      {
        field: 'branchGUID',
        header: 'Branch Guid',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      }, {
        field: 'branchName',
        header: 'Branch Name',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      }, {
        field: 'branch_ID',
        header: 'Branch ID',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      },
      {
        field: 'accountName',
        header: 'Account Name',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      },
      {
        field: 'bankAccount',
        header: 'Bank Account',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      },
      {
        field: 'bankAccountType',
        header: 'Bank Account Type',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      },
      {
        field: 'bankRouting',
        header: 'Bank Routing',
        show: true,
        sortable: true,
        clickable: false,
        class: null
      },
      {
        field: 'bankWiringInstr',
        header: 'Bank Wiring Instr.',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'branchID',
        header: 'Branch ID',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'branchSettlementGUID',
        header: 'Branch Settlement Guid',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'contactEmail',
        header: 'Contact Email',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'contactName',
        header: 'Contact Name',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'contactPhone',
        header: 'Contact Phone',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'customerBranchSettlementID',
        header: 'Customer Branch Settlement ID',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'customerID',
        header: 'Customer Id',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'isClawback',
        header: 'Clawback',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      },
      {
        field: 'lockBox',
        header: 'Lockbox',
        show: false,
        sortable: false,
        clickable: false,
        class: null
      }
    ];
  }
}
