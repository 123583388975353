<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <form [formGroup]="offerForm" (ngSubmit)="submitOffers();">
        <div class="grid">
            <div class="col-12 font-bold">Loan Amount</div>
            <div class="col-6">
                <covered-dropdown id="ddMinLoanAmt" placeholder="Min" [items]="lookups.loanAmounts" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="minLoanAmt">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxLoanAmt" placeholder="Max" [items]="lookups.loanAmounts" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="maxLoanAmt">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">Procedure value</div>
            <div class="col-6">
                <covered-dropdown id="ddMinProcAmt" placeholder="Min" [items]="lookups.minProcedureAmounts" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_ProcedureAmt">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxProcAmt" placeholder="Max" [items]="lookups.maxProcedureAmounts" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_ProcedureAmt">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">APR</div>
            <div class="col-6">
                <covered-dropdown id="ddMinApr" placeholder="Min" [items]="lookups.aprList" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_APRAmt">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxApr" placeholder="Max" [items]="lookups.aprList" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_APRAmt">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">Down Payment</div>
            <div class="col-6">
                <covered-dropdown id="ddMinDP" placeholder="Min" [items]="lookups.minDownPayments" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_DownPaymentPct">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxDP" placeholder="Max" [items]="lookups.maxDownPayments" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_DownPaymentPct">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">Term</div>
            <div class="col-6">
                <covered-dropdown id="ddMinTerm" placeholder="Min" [items]="lookups.terms" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_Term">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxTerm" placeholder="Max" [items]="lookups.terms" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_Term">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">MDR</div>
            <div class="col-6">
                <covered-dropdown id="ddMinMdr" placeholder="Min" [items]="lookups.mdrList" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_MDR">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxMdr" placeholder="Max" [items]="lookups.mdrList" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_MDR">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">Vantage Score</div>
            <div class="col-6">
                <covered-dropdown id="ddMinVanScore" placeholder="Min" [items]="lookups.minVantages" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_VantageScore">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxVanScore" placeholder="Max" [items]="lookups.maxVantages" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_VantageScore">
                </covered-dropdown>
            </div>
            <div class="col-12 font-bold">Min Payment Amount</div>
            <div class="col-6">
                <covered-dropdown id="ddMinPayAmt" placeholder="Min" [items]="lookups.minPaymentAmounts" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="min_MinPaymentAmount">
                </covered-dropdown>
            </div>
            <div class="col-6">
                <covered-dropdown id="ddMaxPayAmt" placeholder="Max" [items]="lookups.maxPaymentAmounts" optionLabel="desc"
                    optionValue="id" [showClear]="false" [required]="true" formControlName="max_MinPaymentAmount">
                </covered-dropdown>
            </div>
            <div class="col-12 flex flex-row-reverse">
                <covered-button id="btnSaveOffer" buttonType="submit" label="Save Offer Details" [disabled]="offerForm.invalid"></covered-button>
            </div>
        </div>
    </form>
</div>