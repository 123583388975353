<span class="text-2xl font-medium">Accounting Reports</span>
<div class="w-full mt-4 border-1 border-black-alpha-10">
    <div class="p-4 report-item-link" (click)="sendClick()">
        Paper Check Funding
    </div>
    <div class="p-4 report-item-link" (click)="sendClick()">
        Suspense
    </div>
    <div class="p-4 report-item-link" (click)="sendClick()">
        Courtesy Adjustment
    </div>
    <div class="p-4 report-item-link" (click)="sendClick()">
        Cancel / Withdrawals
    </div>
    <div class="p-4 report-item-link" (click)="sendClick()">
        Cancel in Progress
    </div>
    <div class="p-4 report-item-link" (click)="sendClick()">
        Prenote
    </div>
</div>