<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <div class="grid">
        <div *ngIf="showPreviewUrl" class="col-12">
            <div class="text-primary cursor-pointer" (click)="goToPreview()">Preview Current</div>
        </div>

        <div class="col-12">
            <div class="font-bold text-xl">Logo</div>
            <div>PNG or SVG format only. Recommended minimum size of 225 x 60 px. Must be on a transparent background.</div>
            <div>
                <covered-button id="btnLogo" label="Choose File" (buttonClick)="logoFile.click()"></covered-button> 
                {{logoFileName}}
                <input hidden #logoFile id="fileLogo" accept=".svg, .png" type="file" (change)="logoFileUploaded($event)"/>
            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="w-6">
                <covered-dropdown id="ddLogoStrip" placeholder="Logo Strip" [items]="lookups.colorStrips"
                    optionLabel="desc" optionValue="id" [showClear]="false" [required]="true" [(ngModel)]="logoStrip">
                </covered-dropdown>
            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="w-6">
                <covered-dropdown id="ddPallette" placeholder="Color Pallette" [items]="lookups.colorPallettes"
                    optionLabel="desc" optionValue="id" [showClear]="false" [required]="true" [(ngModel)]="pallette">
                </covered-dropdown>
            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="w-6">
                <covered-dropdown id="ddAccent" placeholder="Accent Color" [items]="lookups.colorPallettes"
                    optionLabel="desc" optionValue="id" [showClear]="false" [required]="true" [(ngModel)]="accent">
                </covered-dropdown>
            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="font-bold text-xl">Landing Page</div>
            <div>PNG or SVG format only. Recommended minimum size of 225 x 60 px. Must be on a transparent background.</div>
            <div>
                <covered-button id="btnLanding" label="Choose File" (buttonClick)="landingFile.click()"></covered-button> 
                {{landingFileName}}
                <input hidden #landingFile id="fileLanding" accept=".svg, .png" type="file" (change)="landingFileUploaded($event)"/>
            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="font-bold text-xl">Recap Page</div>
            <div>PNG or SVG format only. Recommended minimum size of 225 x 60 px. Must be on a transparent background.</div>
            <div>
                <covered-button id="btnRecap" label="Choose File" (buttonClick)="recapFile.click()"></covered-button> 
                {{recapFileName}}
                <input hidden #recapFile id="fileRecap" accept=".svg, .png" type="file" (change)="recapFileUploaded($event)"/>
            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="font-bold text-xl">Login Page</div>
            <div>PNG or SVG format only. Recommended minimum size of 225 x 60 px. Must be on a transparent background.</div>
            <div>
                <covered-button id="btnLogin" label="Choose File" (buttonClick)="loginFile.click()"></covered-button> 
                {{loginFileName}}
                <input hidden #loginFile id="fileLogin" accept=".svg, .png" type="file" (change)="loginFileUploaded($event)"/>
            </div>
        </div>
    </div>
    <div class="flex flex-row-reverse">
        <covered-button id="btnSaveBranding" label="Save Branding" (buttonClick)="saveBranding()"></covered-button>
    </div>
</div>