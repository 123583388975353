import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faBriefcase, faLink } from '@fortawesome/free-solid-svg-icons';
import { PortfolioItem } from 'src/app/models/customer-item';
import { AuthService } from 'src/app/services/auth.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { SettingService } from 'src/app/services/setting.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  portfolios: PortfolioItem[] = [];
  displayedColumns: string[] = ['name'];

  rows: number = 15;
  first: number = 0;

  iconBriefcase = faBriefcase;
  iconLink = faLink;
  loadingPortfolios = true;
  error: string = '';
  logoUrl: string = '/assets/images/logo.png';
  searchFilter: string = "";
  currentApplicationVersion = environment.appVersion;
  constructor(private portfolioService: PortfolioService, 
    private route: ActivatedRoute,
    private router: Router,
    private setting: SettingService,
    private userService: UserService,
    private auth: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params && params.action && params.action === 'logout'){
          this.auth.logout();
        }else{
          this.portfolioService.setPortfolios();
          this.portfolioService.portfolios$.subscribe(result => {
            if (result){
              this.loadingPortfolios = result.loading;
              this.error = result.error;
              this.portfolios = result.portfolios;
              if (this.portfolios.length == 1){
                this.gotoAdmin(this.portfolios[0]);
              }
            }
          });
        }
      }
    );
  }

  gotoAdmin(item: PortfolioItem) {
    this.portfolioService.portfolioSelected(item);
    this.userService.subscribeAccess();
    this.router.navigate(['/dashboard']);
    window.scrollTo(0, 0);
  }

  async gotoBop(item: PortfolioItem) {
    this.portfolioService.portfolioSelected(item);
    var url = `${await this.setting.bopAppUrl()}home/${item.customerGuid}`;
    window.open(url,"_self");
  }

  async gotoCS(item: PortfolioItem) {
    this.portfolioService.portfolioSelected(item);
    var url = `${await this.setting.csAppUrl()}home/${item.customerGuid}`;
    window.open(url,"_self");
  }

}
