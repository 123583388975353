import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUserCircle, faBars, faThLarge, faTh, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { IUser } from 'src/app/models/user-interface';
import { AuthService } from 'src/app/services/auth.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  user?:IUser;
  iconUserCircle = faUserCircle as IconProp;
  iconBars = faBars as IconProp;
  iconThLarge = faThLarge as IconProp;
  iconTh = faTh as IconProp;
  iconPowerOff = faPowerOff as IconProp;
  customerGuid: string | null = "";
  settings: any;

  constructor(
    private settingService: SettingService,
    private authService:AuthService, 
    private portfolioService: PortfolioService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.authService.userSubject$.subscribe(u => {
      if (u)
      this.user = u;
    });
    this.settingService.load().then(settings => {
      this.settings = settings;
    });    
    this.portfolioService.portfolio$.subscribe(porfolio => {
      if (porfolio && porfolio.customerGuid) {
        this.customerGuid = porfolio.customerGuid;
      }
    });
  }

  doUserMenu(action: string){
    let url: string | null = null;
    switch (action) {
      case 'admin':
        this.router.navigate(['/home']);
        break;
      case 'cs':
        url = `${this.settings.URL_CS}home/${this.customerGuid}`;
        break;
      case 'logout':
        this.authService.logout();
        break;
      case 'profile':
        //redirect to profile
        break;
      case 'bop':
        url = `${this.settings.URL_BOP}home/${this.customerGuid}`;
        break;
      default:
      // how did I get here?
    }
    if (url && url.length > 0) window.open(url, '_self');
  }

}
