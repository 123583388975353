import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumn } from '@covered/covered-ui';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { CustomGridInfo } from 'src/app/models/custom-grid-info';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { GridDataService } from 'src/app/services/grid-data.service';
import { GuidService } from 'src/app/services/guid.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent implements OnInit {

  @Input() Id: string = '';
  @Input() DataSourceName!: string;
  @Input() data: any[] = [];
  @Input() GridTitle: string | null = null;
  @Input() AllowPaging: boolean = true;
  @Input() SearchParams: any | null = null;
  @Input() SearchXML: string = '';
  @Input() RecordCount: number = 1000;
  @Input() rows: number = 100;
  @Input() selectable: string | null = null;
  @Input() showPointer: boolean = false;
  @Input() showCustomCaption: boolean = false;
  @Input() showCustomHeader: boolean = false;
  @Input() rowSelectable: boolean = false;
  @Input() overrideCustomer: boolean = false;
  @Input() overrideDelete: boolean = false;
  @Input() allowCopy: boolean = false;
  @Input() allowCustomCRUD: boolean = false;
  @Input() customCRUDTitle: string = '';
  @Input() customCRUDClass: string = '';
  @Output() customCRUDSelect = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() editRow = new EventEmitter<any>();
  @Output() copyRow = new EventEmitter<any>();
  @Output() deleteRow = new EventEmitter<any>();
  @Output() columnSelect = new EventEmitter<any>();
  @Output() loadingComplete = new EventEmitter<boolean>();

  loading = true;
  customerGuid: string | null = null;

  info: CustomGridInfo | null = null;
  gridColumns: TableColumn[] = [];

  gridSearchFields: string[] = [];
  first: number = 0;
  selectedCampaign: Campaign | null = null;
  campaignGuid: string | null = null;
  selectedPortfolio: PortfolioItem | null = null;
  hasError: boolean = false;

  constructor(
    private toastService: ToastService,
    private portfolioService: PortfolioService,
    private campaignService: CampaignService,
    private confirmService: ConfirmationService,
    private gridDataService: GridDataService,
    private apiService: ApiService,
    private guidService: GuidService
    ) { }

  ngOnInit(): void {
    if (!this.Id || this.Id.length == 0) {
      this.Id = uuidv4();
    }
    if (!this.overrideCustomer) {      
      let combSub = combineLatest([this.portfolioService.portfolio$, this.campaignService.selectedCampaign$])
      .subscribe({
        next: ([p, c]) => { 
          this.selectedPortfolio = p;
          this.selectedCampaign = c;
          this.campaignGuid = c?.campaignGuid || null;
  
          if (p && c) {
            this.customerGuid = p.customerGuid;
            this.getDataInfo();          
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get customer and/or campaign'
          });
          console.error(err);
        },
       // complete: () => { combSub.unsubscribe(); }
      });
    }

  }

  public setCustomerInfo(customerGuid: string | null, campaignGuid: string | null) {
    this.customerGuid = customerGuid;
    this.campaignGuid = campaignGuid;
    this.getDataInfo();
  }

  getDataInfo(){
    let body = {
      "customerGuid": this.customerGuid,
      "campaignGuid": this.campaignGuid,
      "dataSourceName": this.DataSourceName,
      "remoteIPAddress": ""
    };

    this.apiService.post(`dataGrid/grid-info`, body)
    .subscribe((res: any) => {
      this.info = res;
      this.getDataFromSource();
    }, (err: any) => {
        console.error(err);
        this.loading = false;
        this.loadingComplete.emit(true);
      }
    );
  }

  getDataFromSource(){

    let body = {
      "customerGuid": this.customerGuid,
      "campaignGuid": this.campaignGuid,
      "dataSourceName": this.DataSourceName,
      "searchXML": this.SearchXML,
      "remoteIPAddress": "",
      "columnList": this.info!.columnNames.join(','),
      "pageSize": 3000
    };   

    this.apiService.post(`dataGrid/grid-data`, body)
    .subscribe((res: any) => {      
      this.data = res;
      this.setupColumns();
      this.loading = false;
      this.loadingComplete.emit(true);
    }, (err: any) => {
        console.error(err);
        this.loading = false;
        this.loadingComplete.emit(true);
      }
    );
  }

  setupColumns() {
    this.gridColumns = [];

    if (!this.info?.columnHeaders) {
      this.data = [];
      this.toastService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Column Headers missing. Unable to render table'
      });
      this.setError(new Error("ColumnHeaders missing for display"));
      return;
    }

    let fields = this.info.columnHeaders;
    let headers = this.info.columnHeaders;

    let keys: any[] = this.info.columnHeaders;
    if (this.data.length > 0) {
      let testData = this.data[0];
      keys = Object.keys(testData);
      if (testData.hasOwnProperty(this.info.columnNames[0])) {
        fields = this.info.columnNames;
      }
    }

    this.gridSearchFields = fields;

    let extraFields = keys.filter(k => k.toLowerCase() !== 'rowcount' && k.toLowerCase() !== 'rownumber')
      .filter(ef => fields.indexOf(ef) == -1);

    extraFields.forEach(ef => {
      this.gridColumns.push({ field: ef, header: ef, sortable: false, show: false, clickable: false, class: null });
    });
    
    for (let i = 0; i < headers.length; i++) {
      this.gridColumns.push({ field: fields[i], header: headers[i], sortable: this.info.allowSort, show: true, clickable: false, class:'max-w-20rem break-word' });        
    }   

  }

  getColumnsToShow() {
    return this.gridColumns.filter(g => g.show);
  }

  rowSelected(row: any){
    if (this.rowSelectable) this.onSelect.emit(row);
  }

  findDataItem(key: string, value: any): any {
    return this.data.find(d => d[key] == value);
  }
  
  confirmDelete(row: any) {
    this.confirmService.confirm({
      key: `customGrid${this.Id}`,
      message: 'Are you sure that you want to delete this Record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        if (this.overrideDelete) {
          this.deleteRow.emit(row);
        }
        else {
          this.deleteRecord(row);
        }
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.toastService.add({ severity: 'warn', summary: 'Declined', detail: 'Record has not been deleted.' });
            break;
          case ConfirmEventType.CANCEL:
            this.toastService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Operation cancelled.' });
            break;
        }
      }
    });
  }

  deleteRecord(row: any) {
    if (!this.info || this.info.keyFields.trim().length == 0) {
      this.toastService.add({
        severity: 'error',
        summary: 'Unable to delete',
        detail: 'Unable to delete record. Cannot determine key field(s).'
      });
      return;
    }

    let keyFieldXML: string = '';
    this.info.keyFields.split(',').forEach(kf => {
      keyFieldXML += `<Field Name="${kf}" Value="${row[kf]}" />`;
    });

    let body = {
      customerGuid: this.customerGuid || this.guidService.emptyGuid(),
      campaignGuid: this.campaignGuid || this.guidService.emptyGuid(),
      targetGuid: this.guidService.emptyGuid(), // this is never set so use all zero's since legacy does this.
      dataSourceName: this.DataSourceName,
      keyFieldValues: keyFieldXML,
      remoteIPAddress: ''
    };

    this.gridDataService.deleteOneElement(body)
      .then((result: boolean) => {
        if (result) {
          let index = this.data.indexOf(row);
          this.data.splice(index, 1);

          this.toastService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Record Deleted.'
          });

        } else {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred.  Please retry.'
          });
        }
      }, (err: any) => {
        this.setError(err);
        this.toastService.add({
          severity: 'error',
          summary: 'Record Delete ERROR',
          detail: 'There was an exception'
        }, 'center');
      });
  }

  setError(error: any): any {
    this.hasError = true;
    setTimeout(() => {
      this.hasError = false;
    }, 10000);
    console.error(error);
  }
  
}
