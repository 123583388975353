import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn } from '@covered/covered-ui';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { PortfolioItem } from 'src/app/models/customer-item';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  columns: TableColumn[] = [];
  filterFields: string[] = ['LoginEmail', 'FirstName', 'LastName', 'UserTypeName', 'Company', 'Mobile'];
  bcItems: MenuItem[] = [{ label: 'Roles  > Users' }];
  portfolioItem: PortfolioItem | null | undefined;
  loading = false;
  users: any[] = [];
  first: number = 0;
  constructor(private api: ApiService,
    private router: Router,
    private toast: ToastService,
    private confirmService: ConfirmationService,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.initColumns();
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getUsers();
    });
  }

  getUsers() {
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.loading = true;
      this.api.get(`AdminUser/get-all/${this.portfolioItem.customerGuid}`).subscribe(data => {
        this.users = data;
        this.users.forEach((item: any) => {
          // item.offers = this.hasMultipleOffer(item);
        })
        this.loading = false;
      });
    } else {
      //toast error?
    }
  }

  create() {
    this.router.navigate(['/users/add/']);
  }

  getColumns(): TableColumn[] {
    var cols = this.columns.filter(x => x.show);
    return cols;
  }

  initColumns() {
    this.columns = [
      {
        field: 'UserGUID',
        header: 'UserGUID',
        clickable: false,
        show: false,
        sortable: true,
        class: null
      },
      {
        field: 'LoginEmail',
        header: 'Login Email',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'FirstName',
        header: 'First Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'LastName',
        header: 'Last Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'UserTypeName',
        header: 'User Role',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'Company',
        header: 'Company',
        clickable: true,
        show: false,
        sortable: true,
        class: null
      },
      {
        field: 'Mobile',
        header: 'Mobile',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'ProvidersCount',
        header: '# of Providers',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'BranchesCount',
        header: '# of Branches',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: '',
        header: '',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      }
    ];
  }

  deleteUser(rowData: any) {
    this.confirmService.confirm({
      message: `Are you sure that you want to delete ${rowData.FirstName} ${rowData.LastName}?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.deleteConfirmed(rowData);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      }
    });
  }

  deleteConfirmed(rowData: any) {
    this.api.post(`adminuser/delete-user/${rowData.UserGUID}`, '').subscribe(res => {
      this.toast.addSuccess("User Deleted!");
      var i = this.users.findIndex(x => x.UserGUID == rowData.UserGUID);
      this.users.splice(i, 1);
    }, err => {
      console.error(err);
      this.toast.addError("Invalid action. Server Error occured.");
    });
  }

  edit(rowData: any) {
    this.router.navigate(['/users/edit/' + rowData.UserGUID]);
  }
}
