import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CoveredAutocompleteComponent } from '@covered/covered-ui';
import { PageGroup } from 'src/app/models/campaign-pages';
import { DERuleField } from 'src/app/models/decision/rule';
import { NumValDropDown } from 'src/app/models/dropdown';
import { PgDataDDFormGroup, PgDataOptionGroup, pgDataTextServerValidation } from 'src/app/models/form-groups';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { PageDataFunctionLk, PageDataServerValidation, PageDataDD, PageDataListItem } from 'src/app/models/page-data';
import { Validation } from 'src/app/models/validation';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-page-data-dropdown',
  templateUrl: './page-data-dropdown.component.html',
  styleUrls: ['./page-data-dropdown.component.scss']
})
export class PageDataDropdownComponent implements OnInit, AfterViewInit {

  @Input() data: PageDataDD | null | undefined = null;
  @Input() pageFields: DERuleField[] = [];
  @Input() pages: PageGroup[] = [];
  @Input() functions: PageDataFunctionLk[] = [];
  @Input() validations: Validation[] = [];
  @Input() serverVals: PageDataServerValidation[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<any>();
  
  replaceResults: DERuleField[] = [];
  dbPreSel: NameValuePair[] = [{ name: 'None', value: '0' }, { name: 'Target', value: '1' }, { name: 'CSR', value: '2' }, { name: 'Both Target/CSR', value: '3' }];
  addItemsCount: NumValDropDown[] = [];
  addItemPositions: NumValDropDown[] = [
    { text: 'At Beginning', value: 1 }, { text: 'At End', value: 2 }, { text: 'Insert Before', value: 3 }
  ];

  serverValList: PageDataServerValidation[] = [];
  ddForm: FormGroup<PgDataDDFormGroup> = {} as FormGroup;
  ddFormLoaded: boolean = false;
  emptyDataMsg: boolean = false;
  selectedFunction: PageDataFunctionLk | null = null;

  @ViewChild('acTags') acTags: CoveredAutocompleteComponent = {} as CoveredAutocompleteComponent;
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.emptyDataMsg = false;
    if (!this.data) {
      this.emptyDataMsg = true;
      return;
    }
    this.initAddItemsCount();
    if (!this.emptyDataMsg) {
      this.initForm();
      this.ddFormLoaded = true;
    }
  }

  ngAfterViewInit(): void {
  }

  initAddItemsCount() {
    this.addItemsCount = [];
    for (let i = 1; i < 30; i++) {
      this.addItemsCount.push({
        text: i.toString(),
        value: i
      });
    }
  }

  initForm() {
    this.ddForm = this.formBuilder.group<PgDataDDFormGroup>({
      name: new FormControl<string>(this.data?.alias ?? '', { nonNullable: true }),
      objectId: new FormControl<string>(this.data?.name ?? '', { nonNullable: true, validators: [Validators.required] }),
      version: new FormControl<number | null>(this.data?.versionId ?? null, { nonNullable: true }),
      css: new FormControl<string>(this.data?.class ?? '', { nonNullable: true }),
      charLimit: new FormControl<string>(this.data?.mC ?? '', { nonNullable: true }),
      required: new FormControl<boolean>(this.data?.fReq == "1", { nonNullable: true }),
      label: new FormControl<string>(this.data?.lbl ?? '', { nonNullable: true }),
      dbField: new FormControl<string | null>(this.data?.dn ?? null, { nonNullable: true }),
      dbPreSel: new FormControl<string | null>(this.data?.for ?? null, { nonNullable: true }),
      order: new FormControl<string>(this.data?.cOrder ?? '', { nonNullable: true }),
      errorMessage: new FormControl<string>(this.data?.em ?? '', { nonNullable: true }),
      valType: new FormControl<number | null>(this.data?.v ?? null, { nonNullable: true }),
      valErrMsg: new FormControl<string>(this.data?.reem ?? '', { nonNullable: true }),
      disabled: new FormControl<boolean>(this.data?.disabled == "1", { nonNullable: true }),
      serverVal: new FormControl<boolean>(this.data?.sv == "1", { nonNullable: true }),
      serverValDD: new FormControl<number | null>(null, { nonNullable: true }),
      addItmCnt: new FormControl<number | null>(1, { nonNullable: true }),
      addItmCurr: new FormControl<number | null>(null, { nonNullable: true }),
      addItmPos: new FormControl<number | null>(1, { nonNullable: true }),
      items: this.formBuilder.array([
        this.formBuilder.group<PgDataOptionGroup>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          req: new FormControl<boolean>(false, { nonNullable: true }),
          val: new FormControl<string>('', { nonNullable: true }),
          sel: new FormControl<boolean>(false, { nonNullable: true }),
          npid: new FormControl<number|null>(null, { nonNullable: true }),
          text: new FormControl<string>('', { nonNullable: true })
        })
      ]),
      serverVals: this.formBuilder.array([
        this.formBuilder.group<pgDataTextServerValidation>({
          id: new FormControl<string>('', { nonNullable: true }),
          cId: new FormControl<string>('', { nonNullable: true }),
          valId: new FormControl<string>('', { nonNullable: true }),
          name: new FormControl<string>('', { nonNullable: true }),
          errMsg: new FormControl<string>('', { nonNullable: true }),
        })
      ])
    });

    this.ddForm.controls.items.removeAt(0);
    this.ddForm.controls.serverVals.removeAt(0);
    
    let svrIds: number[] = [];
    if (this.data?.serverValidations && this.data.serverValidations.length > 0) {
      this.data.serverValidations.forEach(svr => {
        svrIds.push(+svr.vId);
        this.ddForm.controls.serverVals.push(
          this.formBuilder.group<pgDataTextServerValidation>({
            id: new FormControl<string>(svr.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(svr.cId ?? '', { nonNullable: true }),
            valId: new FormControl<string>(svr.vId ?? '', { nonNullable: true }),
            name: new FormControl<string>(svr.vName ?? '', { nonNullable: true }),
            errMsg: new FormControl<string>(svr.em ?? '', { nonNullable: true }),
          })
        );
      });
    }
    this.serverValList = this.serverVals.filter(s => !svrIds.includes(s.serverValidationID));

    if (this.data?.ddItems && this.data.ddItems.length > 0) {
      this.data.ddItems.forEach(ddItm => {        
        this.ddForm.controls.items.push(
          this.formBuilder.group<PgDataOptionGroup>({
            id: new FormControl<string>(ddItm.id ?? '', { nonNullable: true }),
            cId: new FormControl<string>(ddItm.cId ?? '', { nonNullable: true }),
            req: new FormControl<boolean>(ddItm.req, { nonNullable: true }),
            val: new FormControl<string>(ddItm.val ?? '', { nonNullable: true }),
            sel: new FormControl<boolean>(ddItm.sel, { nonNullable: true }),
            npid: new FormControl<number|null>(ddItm.npid ?? null, { nonNullable: true }),
            text: new FormControl<string>(ddItm.text ?? '', { nonNullable: true })
          })
        );
      });
    }
  }

  saveDDForm() {
    if (this.data) {
      this.data.alias = this.ddForm.value.name ?? this.data.alias;
      this.data.name = this.ddForm.value.objectId ?? this.data.name;
      this.data.versionId = this.ddForm.value.version ?? this.data.versionId;
      this.data.class = this.ddForm.value.css ?? this.data.class;
      this.data.mC = this.ddForm.value.charLimit ?? this.data.mC;
      this.data.fReq = this.ddForm.value.required ? '1' : '0';
      this.data.lbl = this.ddForm.value.label ?? this.data.lbl;
      this.data.dn = this.ddForm.value.dbField ?? this.data.dn;
      this.data.for = this.ddForm.value.dbPreSel ?? this.data.for;
      this.data.cOrder = this.ddForm.value.order ?? this.data.cOrder;
      this.data.v = this.ddForm.value.valType ?? this.data.v;
      this.data.em = this.ddForm.value.errorMessage ?? this.data.em;
      this.data.disabled = this.ddForm.value.disabled ? '1' : '0';
      this.data.sv = this.ddForm.value.serverVal ? '1' : '0';
      this.data.serverValidations = [];
      this.data.ddItems = [];
    }

    this.ddForm.value.items?.forEach(itm => {
      this.data?.ddItems.push({
        id: itm.id ?? '',
        cId: itm.cId ?? '',
        req: itm.req ?? false,
        val: itm.val ?? '',
        sel: itm.sel ?? false,
        npid: itm.npid ?? null,
        text: itm.text ?? ''
      })
    });

    let idx: number = 0;
    this.ddForm.controls.serverVals.controls.forEach(ctrl => {
      idx++;
      this.data?.serverValidations.push({
        id: idx.toString(),
        cId: ctrl.value.cId ?? '',
        vId: ctrl.value.valId ?? '',
        vName: ctrl.value.name ?? '',
        em: ctrl.value.errMsg ?? ''
      });
    });

    let saveObj = {
      element: 'dropdown',
      data: this.data
    };

    this.onSave.emit(saveObj);
  }

  cancelForm() {
    this.onCancel.emit();
  }

  getCurrentItemCountDD(): NumValDropDown[] {
    let ddItems: NumValDropDown[] = [];

    for (let i = 0; i < this.ddForm.controls.items.controls.length; i++) {
      ddItems.push({
        text: `#${(i + 1)}`,
        value: (i + 1)
      });
    }

    return ddItems;
  }

  ddItemSelChanged(event: any, idx: number) {   
    for (let j: number = 0; j < this.ddForm.controls.items.controls.length; j++) {
      if (j != idx) {
        this.ddForm.controls.items.at(j).patchValue({
          sel: false
        });
      }
    }
  }

  addDDItems() {
    let itemCount: number = this.ddForm.value.addItmCnt ?? 0;
    let itemPosition: number = this.ddForm.value.addItmPos ?? 0;
    let beforeNum: number = this.ddForm.value.addItmCurr ?? 0;

    if (itemCount > 0 && itemPosition > 0) {

      for (let i: number = 0; i < itemCount; i++) {
        
        let newItem = this.formBuilder.group<PgDataOptionGroup>({
          id: new FormControl<string>((this.ddForm.controls.items.controls.length + 1).toString(), { nonNullable: true }),
          cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
          req: new FormControl<boolean>(true, { nonNullable: true }),
          val: new FormControl<string>('', { nonNullable: true }),
          sel: new FormControl<boolean>(false, { nonNullable: true }),
          npid: new FormControl<number|null>(null, { nonNullable: true }),
          text: new FormControl<string>('', { nonNullable: true })
        })
        
        if (itemPosition == 2) {
          this.ddForm.controls.items.push(newItem);
        }
        else {
          let spliceIdx: number = itemPosition == 1 ? 0 : (beforeNum + i - 1);
          this.ddForm.controls.items.controls.splice(spliceIdx, 0, newItem);
        }
      }
    }
  }

  deleteDDItem(i: number) {
    this.ddForm.controls.items.removeAt(i);
  }

  searchTags(event: any) {
    let search = this.pageFields.filter(pf => pf.fieldName.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    if (search && search.length) this.replaceResults = search;
    else this.replaceResults = [];
  }

  insertTag() {
    let tagTxt = this.acTags.value?.fieldName ?? '';
    if (tagTxt.length) {
      let newVal = this.ddForm.value.label + `{!${tagTxt}}`;
      this.ddForm.patchValue({
        label: newVal
      });
    }
    this.acTags.value = null;
    this.acTags.innerValue = null;
  }

  functionSelected(funcId: number) {
    this.selectedFunction = this.functions.find(f => f.pageFunctionID == funcId) ?? null;
  }

  insertFunc() {
    if (this.selectedFunction) {
      let newVal = this.ddForm.value.label + `.${this.selectedFunction.pageFunctionName}()`;
      this.ddForm.patchValue({
        label: newVal
      });
    }
  }

  addServerValidator() {
    let svr = this.serverValList.find(s => s.serverValidationID == this.ddForm.value.serverValDD);
    if (svr) {
      this.ddForm.controls.serverVals.push(
        this.formBuilder.group<pgDataTextServerValidation>({
          id: new FormControl<string>((this.ddForm.controls.serverVals.length + 1).toString(), { nonNullable: true }),
          cId: new FormControl<string>(uuidv4(), { nonNullable: true }),
          valId: new FormControl<string>(svr.serverValidationID.toString() ?? '', { nonNullable: true }),
          name: new FormControl<string>(svr.serverValidationName ?? '', { nonNullable: true }),
          errMsg: new FormControl<string>(svr.errorMessage ?? '', { nonNullable: true }),
        })
      );
      let svrIdx = this.serverValList.indexOf(svr);
      if (svrIdx) this.serverValList.splice(svrIdx, 1);
      this.ddForm.patchValue({
        serverValDD: null
      });
    }
  }

  deleteServerVal(j: number) {
    if (this.ddForm && this.ddForm.value.serverVals?.length) {
      let svr = this.ddForm.value.serverVals[j];
      let mstrSvr = this.serverVals.find(s => s.serverValidationID == +(svr.valId ?? -100));
      if (mstrSvr && this.serverValList.indexOf(mstrSvr) < 0) {
        this.serverValList.splice(this.serverValList.length, 0, mstrSvr);
      }
    }

    this.ddForm.controls.serverVals.removeAt(j);
  }

}
