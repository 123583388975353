<div *ngIf="!guidsLoaded">
    <div class="text-3xl text-red-500">
        You MUST select a Provider and Campaign before accessing this page.
    </div>
</div>

<div *ngIf="guidsLoaded && showPagesCard">
    <span class="text-2xl font-medium">Page List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="flex flex-row-reverse">
                <covered-button id="btnLocation" label="New Page" (buttonClick)="showNewPage()"></covered-button>
                <covered-button id="btnPricing" label="Set Page Actions" class="mr-2"
                    (buttonClick)="showSetPageActions()">
                </covered-button>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPages id="cgPages"
                    DataSourceName="dsAdminCampaignPages" [allowCopy]="true"
                    (copyRow)="copyCamPage($event)" (editRow)="editCamPage($event)" 
                    [overrideDelete]="true" (deleteRow)="deletePage($event)"
                    [allowCustomCRUD]="true" customCRUDClass="pi pi-book cursor-pointer" 
                    customCRUDTitle="Edit Page Data" (customCRUDSelect)="editPageData($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<div *ngIf="guidsLoaded && showActionsCard">
    <span class="text-2xl font-medium">Page Flow</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="flex">
                <span class="text-2xl font-medium">Page Flow</span>
                <div class="flex flex-grow-1 flex-row-reverse mr-4">
                    <covered-button id="btnAddPgFlow" label="Add Page Flow" (buttonClick)="addPageFlow()">
                    </covered-button>
                </div>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPageFlow id="cgPageFlow"
                    DataSourceName="dsAdmin_CampaignPageFlow" [rows]="15" 
                    (editRow)="editPageFlow($event)"
                    [overrideDelete]="true" (deleteRow)="deletePageFlow($event)">
                </app-custom-grid>
            </div>
        </div>
        <div class="cds-card p-4">
            <div class="flex">
                <span class="text-2xl font-medium">Page Block</span>
                <div class="flex flex-grow-1 flex-row-reverse mr-4">
                    <covered-button id="btnAddPgBlock" label="Add Page Block" (buttonClick)="addPageBlock()">
                    </covered-button>
                </div>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPageBlock id="cgPageBlock" 
                    DataSourceName="dsAdmin_CampaignPageBlock" [rows]="15" 
                    (editRow)="editPageBlock($event)"
                    [overrideDelete]="true" (deleteRow)="deletePageBlock($event)">
                </app-custom-grid>
            </div>
        </div>
        <div class="cds-card p-4">
            <div class="flex">
                <span class="text-2xl font-medium">Page Triggers</span>
                <div class="flex flex-grow-1 flex-row-reverse mr-4">
                    <covered-button id="btnAddPgTrigger" label="Add Page Trigger" (buttonClick)="addPageTrigger()">
                    </covered-button>
                </div>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPageTrigger id="cgPageTrigger" 
                    DataSourceName="dsAdmin_CampaignPageTriggers" [rows]="15" 
                    (editRow)="editPageTrigger($event)"
                    [overrideDelete]="true" (deleteRow)="deletePageTrigger($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<div *ngIf="guidsLoaded && showEditCard">
    <span class="text-2xl font-medium">{{editCardTitle}} Page</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <form *ngIf="pageFormLoaded" [formGroup]="pageForm" (ngSubmit)="savePage()">
                <div class="grid">
                    <div class="col-12 lg:col-4">
                        <covered-dropdown id="ddAppStatus" placeholder="Loan App Status" [items]="loanStatuses"
                            optionLabel="loanAppStatusShortDescription" optionValue="loanAppStatusID"
                            [displayFirst]="false" [showClear]="false" [required]="true" formControlName="appStatus">
                        </covered-dropdown>
                    </div>
                    <div class="col-12 lg:col-4">
                        <covered-dropdown id="ddPageType" placeholder="Page Type" [items]="pageTypes" optionLabel="text"
                            optionValue="value" [displayFirst]="false" [showClear]="false" [required]="true"
                            formControlName="pageType">
                        </covered-dropdown>
                    </div>
                    <div class="col-12 lg:col-4">
                        <covered-dropdown id="ddStartPage" placeholder="Is Start Page" [items]="yesNoOptions"
                            optionLabel="text" optionValue="value" [displayFirst]="false" [showClear]="false"
                            [required]="true" formControlName="startPage">
                        </covered-dropdown>
                    </div>
                    <div class="col-12 lg:col-6">
                        <covered-form-text id="txtPageName" placeholder="Page Name" [required]="true"
                            formControlName="pageName"></covered-form-text>
                    </div>
                    <div class="col-12 lg:col-6">
                        <covered-form-text id="txtPageUrl" placeholder="Page Url (file name)" [required]="true"
                            formControlName="pageUrl"></covered-form-text>
                    </div>
                    <div class="col-12 lg:col-6">
                        <covered-input-number id="txtScore" placeholder="Page Score" mode="decimal" [required]="true"
                            formControlName="pageScore">
                        </covered-input-number>
                    </div>
                    <div class="col-12 lg:col-6">
                        <covered-dropdown id="ddScoreMulti" placeholder="Score Added More Than Once"
                            [items]="yesNoOptions" optionLabel="text" optionValue="value" [displayFirst]="false"
                            [showClear]="false" formControlName="multiScore">
                        </covered-dropdown>
                    </div>
                    <div class="col-12 lg:col-6">
                        <span class="font-bold text-black-alpha-90">Dependencies</span>
                        <p-listbox [options]="pageDependcies" [multiple]="true" formControlName="pageDependcies"
                            optionLabel="pageName" optionValue="pageID" listStyleClass="w-full h-20rem">
                            <ng-template pTemplate="footer">
                                <span class="text-sm">Just the previous page. Multiple pages applies "OR"
                                    operand.</span>
                            </ng-template>
                        </p-listbox>
                    </div>
                    <div class="col-12 lg:col-6 grid">
                        <div class="col-12">
                            <span class="font-bold text-black-alpha-90 mr-5">CSR Show</span>
                            <p-radioButton name="csr" [value]="true" label="Yes" formControlName="csr" class="mr-7">
                            </p-radioButton>
                            <p-radioButton name="csr" [value]="false" label="No" formControlName="csr" class="mr-7">
                            </p-radioButton>
                        </div>
                        
                        <div class="col-12 lg:col-6">
                            <covered-dropdown id="ddPageGroup" placeholder="Page Group" [items]="pageGroups"
                                optionLabel="pageName" optionValue="pageID" [displayFirst]="false" [showClear]="false"
                                formControlName="pageGroup" appendTo="body">
                            </covered-dropdown>
                        </div>
                        <div class="col-12 lg:col-6">
                            <covered-dropdown id="ddCampVar" placeholder="Campaign Variable" [items]="campVars"
                                optionLabel="variableName" optionValue="campaignVariableID" [displayFirst]="false" [showClear]="false"
                                formControlName="variable" appendTo="body">
                            </covered-dropdown>
                        </div>
                        <div class="col-12 lg:col-6">
                            <covered-form-text id="txtVarVal" placeholder="Campaign Variable Value"
                                formControlName="varValue"></covered-form-text>
                        </div>
                        <div class="col-12 lg:col-6">
                            <covered-input-number mode="decimal" id="txtVarWeight" placeholder="Campaign Variable Weight"
                                formControlName="varWeight"></covered-input-number>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row-reverse">
                    <covered-button id="btnSavePage" buttonType="submit" label="Submit" [disabled]="!pageForm.valid"></covered-button>
                    <covered-button id="btnCancelPage" label="Cancel" class="mr-4 outlined" (buttonClick)="cancelPageEdit()"></covered-button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="guidsLoaded && showPageDataCard">
    <span class="text-2xl font-medium">Page Data</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
        </div>
    </div>
</div>

<covered-dialog [isVisible]="showCopyModal" header="Copy Page" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showCopyModal=false;copyPageFormLoaded=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="copyPageFormLoaded" [formGroup]="copyPageForm">
            <div class="w-12">
                <covered-form-text id="txtCopyPgName" placeholder="New Page Name" [required]="true"
                    formControlName="pageName">
                </covered-form-text>
            </div>
            <div class="w-12 pt-4">
                <covered-form-text id="txtCopyPgUrl" placeholder="URL" [required]="true" formControlName="pageUrl">
                </covered-form-text>
            </div>
            <div class="w-12 pt-4">
                <covered-form-text id="txtCopyPgFlash" placeholder="Flash Name" [required]="true"
                    formControlName="flashName">
                </covered-form-text>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveCopy" label="Save" (buttonClick)="saveCopyPage();"
                [disabled]="!copyPageForm.valid"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showPageFlowModal" header="{{pageFlowTitle}} Page Flow" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showPageFlowModal=false;pageFlowFormLoaded=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="pageFlowFormLoaded" [formGroup]="pageFlowForm">
            <div class="w-12">
                <covered-dropdown id="ddPage" placeholder="Before this page can be loaded" [items]="pageGroups"
                    optionLabel="pageName" optionValue="pageID" [displayFirst]="false" [showClear]="false"
                    formControlName="pageId" appendTo="body" [required]="true">
                </covered-dropdown>
            </div>
            <div class="w-12 pt-4">
                <covered-dropdown id="ddPageComp" placeholder="This page must be completed" [items]="pageGroups"
                    optionLabel="pageName" optionValue="pageID" [displayFirst]="false" [showClear]="false"
                    formControlName="completedPageId" appendTo="body" [required]="true">
                </covered-dropdown>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveCopy" label="Save" (buttonClick)="savePageFlow();"
                [disabled]="!pageFlowForm.valid"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showPageBlockModal" header="{{pageBlockTitle}} Page Block" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showPageBlockModal=false;pageBlockFormLoaded=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="pageBlockFormLoaded" [formGroup]="pageBlockForm">
            <div class="w-12">
                <covered-dropdown id="ddPage" placeholder="Complete this page" [items]="pageGroups"
                    optionLabel="pageName" optionValue="pageID" [displayFirst]="false" [showClear]="false"
                    formControlName="pageId" appendTo="body" [required]="true">
                </covered-dropdown>
            </div>
            <div class="w-12 pt-4">
                <covered-dropdown id="ddPageComp" placeholder="Block this page from loading" [items]="pageGroups"
                    optionLabel="pageName" optionValue="pageID" [displayFirst]="false" [showClear]="false"
                    formControlName="blockPageId" appendTo="body" [required]="true">
                </covered-dropdown>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveCopy" label="Save" (buttonClick)="savePageBlock();"
                [disabled]="!pageBlockForm.valid"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showPageTriggerModal" header="{{pageTriggerTitle}} Page Trigger" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showPageTriggerModal=false;pageTriggerFormLoaded=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="pageTriggerFormLoaded" [formGroup]="pageTriggerForm">
            <div class="w-12">
                <covered-dropdown id="ddtgPage" placeholder="Page" [items]="triggerPages"
                    optionLabel="text" optionValue="value" [displayFirst]="false" [showClear]="false"
                    formControlName="pageId" appendTo="body" [required]="true">
                </covered-dropdown>
            </div>
            <div class="w-12 pt-4">
                <covered-dropdown id="ddPageEvt" placeholder="Page Event" [items]="pageEvents"
                    optionLabel="text" optionValue="value" [displayFirst]="false" [showClear]="false"
                    formControlName="eventId" appendTo="body" [required]="true">
                </covered-dropdown>
            </div>
            <div class="w-12 pt-4">
                <covered-form-textarea id="txtSql" placeholder="SQL Call" [required]="true"
                    formControlName="sql" [rows]="5" ></covered-form-textarea>
            </div>
            <div class="w-12 pt-4">
                <covered-input-number mode="decimal" id="txtOrder" placeholder="Order"
                    [required]="true" formControlName="order"></covered-input-number>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveCopy" label="Save" (buttonClick)="savePageTrigger();"
                [disabled]="!pageTriggerForm.valid"></covered-button>
        </div>
    </div>
</covered-dialog>