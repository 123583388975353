<span class="text-2xl font-medium">Lead Data Testing</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="cds-card w-screen-fit p-2">
        <div class="flex flex-row-reverse">
            <div class="m-4">
                <covered-button id="btnSaveRecs" label="Add New" (buttonClick)="addPredefined()">
                </covered-button>
            </div>
            <div class="m-4" *ngIf="showEdit">
                <covered-button id="btnbtn" label="Edit" (buttonClick)="editPre()">
                </covered-button>
            </div>
            <div class="m-4 mw-200">
                <covered-dropdown id="ddlled" placeholder="Predefined Lead" [items]="lookups.predefinedLeads"
                    optionLabel="desc" optionValue="id" [showClear]="false" [required]="true"
                    formControlName="predefined" (optionSelected)="predefinedSelected($event);">
                </covered-dropdown>
            </div>
        </div>

        <form *ngIf="leadFormLoaded" [formGroup]="leadForm">
            <div class="grid m-4">
                <div class="col-6">
                    <covered-dropdown id="ddlProvider" [items]="customers" optionLabel="customerName"
                        placeholder="Provider" optionValue="customerGUID" [showClear]="false" [required]="true"
                        formControlName="PartnerGUID" (optionSelected)="partnerSelected($event);">
                    </covered-dropdown>
                </div>
                <div class="col-6">
                    <covered-dropdown id="ddBranch" [items]="lookups.providerBranches" optionLabel="desc"
                        placeholder="Branch" optionValue="id" [showClear]="false" [required]="true"
                        formControlName="BranchLocationGUID" (optionSelected)="branchSelected($event)">
                    </covered-dropdown>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead Guid" formControlName="LeadGuid" [required]="true"
                        [disabled]=true>
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-dropdown id="ddOfrProdTyp" placeholder="Offer Product Type" [items]="offerProductTypes" 
                        optionLabel="offerProductTypeDesc" optionValue="offerProductTypeGuid" formControlName="OfferProductTypeGuid" [required]="true">
                    </covered-dropdown>
                </div>
                <div class="col-4">
                    <covered-dropdown id="ddCountry" [items]="disclosures" optionLabel="desc" placeholder="Disclosures"
                        optionValue="id" [showClear]="false" [required]="true" formControlName="AgreeToSignDisclosures">
                    </covered-dropdown>
                </div>
                <div class="col-6">
                    <div class="">
                        <covered-form-text id="" placeholder="Partner Name" formControlName="PartnerName" inputStyleClass="cds-form-box">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-6">
                    <div class="">
                        <covered-form-text id="" placeholder="Partner Vertical" formControlName="PartnerVertical">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-6">
                    <div class="">
                        <covered-form-text id="" placeholder="Partner Address1" formControlName="PartnerAddress1">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-6">
                    <div class="">
                        <covered-form-text id="" placeholder="Partner Address2" formControlName="PartnerAddress2">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-4">
                    <div class="">
                        <covered-form-text id="" placeholder="Partner City" formControlName="PartnerCity">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-4">
                    <div class="">
                        <covered-form-text id="" placeholder="Partner State" formControlName="PartnerState">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-4">
                    <div class="">
                        <covered-input-number id="" placeholder="Partner ZipCode" formControlName="PartnerZipCode" mode="decimal">
                        </covered-input-number>
                    </div>
                </div>
                <div class="col-6">
                    <covered-form-text id="" placeholder="Lead Patient Id" formControlName="LeadPatientID">
                    </covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="" placeholder="Assign To" formControlName="AssignTo">
                    </covered-form-text>
                </div>
                <div class="col-3">
                    <covered-dropdown id="ddem" [items]="trueFalse" optionLabel="desc" placeholder="Send Email"
                        optionValue="id" [showClear]="false" [required]="true" formControlName="SendEmail">
                    </covered-dropdown>
                </div>
                <div class="col-3">
                    <covered-dropdown id="ddsm" [items]="trueFalse" optionLabel="desc" placeholder="Send SMS"
                        optionValue="id" [showClear]="false" [required]="true" formControlName="SendSMS">
                    </covered-dropdown>
                </div>
                <div class="col-3">
                    <covered-dropdown id="ddapp" [items]="appLinks" optionLabel="desc" placeholder="Send App Link"
                        optionValue="id" [showClear]="false" [required]="true" formControlName="SendAppLink">
                    </covered-dropdown>
                </div>
                <div class="col-3">
                    <covered-dropdown id="ddln" [items]="languages" optionLabel="desc" placeholder="Language"
                        optionValue="id" [showClear]="false" [required]="true" formControlName="Language">
                    </covered-dropdown>
                </div>
                <div class="col-4">
                    <covered-form-text id="txtLFN" placeholder="Lead First Name" [required]="true"
                        formControlName="LeadFirstName" inputStyleClass="cds-form-box">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead Last Name" [required]="true"
                        formControlName="LeadLastName">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-datepicker id="dtLeadDOB" placeholder="Lead DOB" formControlName="LeadDateOfBirth">
                    </covered-datepicker>
                </div>
                <div class="col-6">
                    <covered-form-text id="" placeholder="Lead Address" [required]="true" formControlName="LeadAddress">
                    </covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="" placeholder="Lead Address 2" formControlName="LeadAddress2">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead City" [required]="true" formControlName="LeadCity">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead State" [required]="true" formControlName="LeadState">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead Zip Code" [required]="true" formControlName="LeadZip">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead Email Address" [required]="true"
                        formControlName="LeadEmailAddress">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-form-text id="" placeholder="Lead Phone Number" [required]="true"
                        formControlName="LeadPhone">
                    </covered-form-text>
                </div>
                <div class="col-4">
                    <covered-input-number id="" placeholder="Lead SSN" formControlName="LeadSSN" maxlength=9 [useGrouping]="false" mode="decimal" (blurEvent)="onSsnBlur($event)">
                    </covered-input-number>
                </div>
                <div class="col-6">
                    <covered-form-text id="" placeholder="Loan Requested Amount" formControlName="RequestedAmount">
                    </covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="" placeholder="Loan Type" formControlName="LoanType" [disabled]=true>
                    </covered-form-text>
                </div>
            </div>

        </form>
        <form *ngIf="procedureFormLoaded" [formGroup]="procedureForm">
            <div class="grid m-4">
                <div class="col-12">
                    <div class="w-6">
                        <covered-form-text id="" placeholder="Procedure ID" formControlName="ProcedureId"
                            [disabled]=true>
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-3">
                    <covered-datepicker id="" placeholder="Procedure Date" formControlName="ProcedureDate">
                    </covered-datepicker>
                </div>
                <div class="col-3">
                    <covered-input-number id="" placeholder="Procedure Amount" formControlName="ProcedureAmount">
                    </covered-input-number>
                </div>
                <div class="col-3">
                    <covered-dropdown id="ddlol" [items]="lookups.providerProducts" optionLabel="desc"
                        placeholder="Procedure Name" optionValue="id" [showClear]="false" [required]="true"
                        formControlName="ProcedureName">
                    </covered-dropdown>
                </div>
                <div class="col-3">
                    <covered-form-text id="" placeholder="Procedure Doctor" formControlName="Doctor">
                    </covered-form-text>
                </div>
            </div>

        </form>
        <div class="d-flex mb-1">
            <div class="me-auto p-2">
                <covered-button id="btnSaveRecs" label="POST DATA" (buttonClick)="postLead()"
                    [disabled]="!leadForm.valid || !procedureForm.valid">
                </covered-button>
            </div>
            <div class="p-2">
                <covered-button id="btncs" label="Refresh Grid" (buttonClick)="getRequests()">
                </covered-button>
            </div>
        </div>

        <div *ngIf="loading" class="m-5">
            <div class="text-2xl">Loading...</div>
            <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
        </div>
        <div *ngIf="!loading">
            <p-table #dt [value]="requests" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                [globalFilterFields]="filterFields" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
                [(first)]="first" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                responsiveLayout="scroll" [rows]="100" [rowHover]="true" [columns]="columns">
                <ng-template pTemplate="caption">
                    <div class="d-flex flex-row-reverse mb-3">
                        <div class="p-1">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" aria-hidden="true"></i>
                                <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                    (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                            <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field" class="font-medium"
                                scope="col">{{ col.header }}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                        </ng-template>
                        <th scope="col"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td *ngFor="let c of getColumns()">
                            <span *ngIf="c.field">{{rowData[c.field]}}</span>
                            <span *ngIf="c.header === 'Details'" class="hand" (click)="showDetailsData(rowData)">Show
                                Details</span>
                            <span *ngIf="c.header === 'Loan App'">
                                <span *ngIf="!isMultipleOffer(rowData)"><a [href]="rowData['StartURL']"
                                        target="_blank">Start App</a></span>
                                <span *ngIf="rowData.offers && rowData.offers.length">
                                    <select class="form-select" aria-label="Default select example"
                                        (change)="onOfferChange($event)">
                                        <option selected>Select Offer URL</option>
                                        <option *ngFor="let o of rowData.offers" [value]="o.StartURL">{{o.desc}}
                                        </option>
                                    </select>
                                </span>
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [colSpan]="7">No Offers found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog header="Details" [modal]="true" [(visible)]="showDetails.show" [style]="{width: '90vw', maxWidth: '700px'}"
    [baseZIndex]="10000">
    <div>
        <p><strong>Post Data:</strong></p>
        <div class="xml-details bg-light m-2">
            <pre class="p-2">{{showDetails.post}}</pre>
        </div>
        <p><strong>Response Data:</strong></p>
        <div class="xml-details bg-light m-2">
            <pre class="p-2">{{showDetails.response}}</pre>
        </div>
    </div>
</p-dialog>