<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">Unable to load Offer Matrix Information.
</div>


<div *ngIf="!loading && !hasError && showMatrix">

    <span class="text-2xl font-medium">Decision Engine Offer Matrix</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 flex flex-row-reverse">
                    <covered-button id="btnAddMatrix" label="Add Offer Matrix" (buttonClick)="addOfferMatrix()">
                    </covered-button>
                </div>
                <div class="col-12 pt-4">
                    <app-custom-grid #cgOfrMtx id="cgOfferMatrix" DataSourceName="dsAdminDEOfferMatrixList"
                        [allowCopy]="true" (copyRow)="copyOfferMatrix($event)" (editRow)="editOfferMatrix($event)"
                        [overrideCustomer]="true" [overrideDelete]="false" [allowCustomCRUD]="false">
                    </app-custom-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && !hasError && showMatrixEdit">
    <span class="text-2xl font-medium">Decision Engine Offer Matrix {{matrixEditTitle}}</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4" [ngClass]="leftNavExpanded ? 'max-w-86':'max-w-92'">
            <form *ngIf="matrixNameFormLoaded" [formGroup]="matrixNameForm" (ngSubmit)="saveMatrixName()">
                <div class="grid">
                    <div class="col-12 text-2xl font-bold">{{matrixEditTitle}} Offer Matrix</div>
                    <div class="col-6 py-4">
                        <covered-form-text id="txtMatName" placeholder="Name" [required]="true" formControlName="name">
                        </covered-form-text>
                    </div>
                    <div class="col-6 py-4">
                        <covered-form-text id="txtMatDescription" placeholder="Description" [required]="true"
                            formControlName="description">
                        </covered-form-text>
                    </div>
                </div>
                <div>
                    <covered-button id="btnSaveMatName" label="Save" buttonType="submit"
                        [disabled]="!matrixNameForm.valid"></covered-button>
                </div>
            </form>
            <div *ngIf="selectedMatrix != null" class="pt-6 flex flex-wrap">
                <div class="w-1 text-2xl align-self-center">
                    Matrix Rows
                </div>
                <div class="w-3">
                    <covered-button id="btnTestRows" (buttonClick)="testOfferMatrix()">
                        <span class="text-lg">Test Offer Matrix</span>
                    </covered-button>
                </div>
                <div class="w-8 flex flex-row-reverse">
                    <covered-button id="btnNewRow" label="Add Row" (buttonClick)="addMatrixRow()"></covered-button>
                </div>
                <div class="pt-4 w-12">
                    <app-custom-grid #cgOfrMtxRow id="cgOfferMatrixRow" DataSourceName="dsAdminDEOfferMatrixRows"
                        [allowCopy]="true" (copyRow)="confirmCopyOfferMatrixRow($event)"
                        (editRow)="editOfferMatrixRow($event)" [overrideDelete]="false" [allowCustomCRUD]="false">
                    </app-custom-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!loading && !hasError && showMatrixRowEdit">
    <span class="text-2xl font-medium">Decision Engine Offer Matrix Option {{matrixEditRowTitle}}</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4" [ngClass]="leftNavExpanded ? 'max-w-86':'max-w-92'">
            <form *ngIf="matrixRowFormLoaded" [formGroup]="matrixRowForm" (ngSubmit)="saveMatrixRow()">
                <div class="grid">
                    <div class="col-12 text-2xl font-bold">{{matrixEditRowTitle}} Offer Option</div>

                    <div class="col-6 pt-5">
                        <covered-dropdown id="ddOfrProdType" placeholder="Offer Product Type"
                            [items]="lookups.prodTypeAccesses" optionLabel="offerProductTypeDesc"
                            optionValue="offerProductTypeID" formControlName="productType" [required]="true">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!matrixRowForm.controls.productType.pristine && matrixRowForm.controls.productType.errors">
                                <p *ngIf="matrixRowForm.controls.productType.errors?.required">Product Type is required
                                </p>
                            </div>
                        </covered-dropdown>
                    </div>
                    <div class="col-6">
                        <span class="text-lg font-bold">Marketing Message</span>
                        <div formArrayName="marketingMessages">
                            <div *ngFor="let message of matrixRowForm.controls.marketingMessages.controls; index as i">
                                <div class="grid" [formGroupName]="i">
                                    <span class="col-1 align-self-center">{{message.value.language}}:</span>
                                    <div class="col-11">
                                        <covered-form-text id="txtMarketMsg{{i}}" formControlName="message">
                                        </covered-form-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 py-4 text-xl font-bolder">Segmentation Detail</div>
                    <div class="col-6">
                        <covered-dropdown id="ddCredBand" placeholder="Credit Band" [items]="lookups.loanGrades"
                            optionLabel="loanGradeShortDesc" optionValue="loanGradeID" formControlName="creditBand"
                            [required]="true">
                        </covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddState" placeholder="State" [items]="lookups.offerStates"
                            optionLabel="stateFullName" optionValue="stateid" formControlName="state" [required]="true">
                        </covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddProcedure" placeholder="Procedure" [items]="lookups.providerProducts"
                            optionLabel="providerProductDesc" optionValue="providerProductID" [required]="true"
                            formControlName="procedure">
                        </covered-dropdown>
                    </div>
                    <div class="col-12 py-4 text-xl font-bolder">Offer Detail</div>
                    <div class="col-6">
                        <covered-input-number id="txtMinProcAmt"
                            placeholder="Min Procedure Amt (min: {{lookups.minMaxProcedureAmounts[0].customer_Min_ProcedureAmt | currency}})"
                            formControlName="minProcAmt" [required]="true">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!matrixRowForm.controls.minProcAmt.pristine && matrixRowForm.controls.minProcAmt.errors">
                                <p *ngIf="matrixRowForm.controls.minProcAmt.errors?.required">Minimum procedure amount
                                    is required</p>
                                <p *ngIf="matrixRowForm.controls.minProcAmt.errors?.min">Amount must be greater than
                                    {{lookups.minMaxProcedureAmounts[0].customer_Min_ProcedureAmt | currency}}</p>
                                <p *ngIf="matrixRowForm.controls.minProcAmt.errors?.max">Amount must be less than
                                    {{lookups.minMaxProcedureAmounts[0].customer_Max_ProcedureAmt | currency}}</p>
                            </div>
                        </covered-input-number>
                    </div>
                    <div class="col-6">
                        <covered-input-number id="txtMaxProcAmt"
                            placeholder="Max Procedure Amt (max: {{lookups.minMaxProcedureAmounts[0].customer_Max_ProcedureAmt | currency}})"
                            formControlName="maxProcAmt" [required]="true">
                            <div class="w-12 my-1 p-error text-xs"
                                *ngIf="!matrixRowForm.controls.maxProcAmt.pristine && matrixRowForm.controls.maxProcAmt.errors">
                                <p *ngIf="matrixRowForm.controls.maxProcAmt.errors?.required">Maximum procedure amount
                                    is required</p>
                                <p *ngIf="matrixRowForm.controls.maxProcAmt.errors?.min">Amount must be greater than
                                    {{lookups.minMaxProcedureAmounts[0].customer_Min_ProcedureAmt | currency}}</p>
                                <p *ngIf="matrixRowForm.controls.maxProcAmt.errors?.max">Amount must be less than
                                    {{lookups.minMaxProcedureAmounts[0].customer_Max_ProcedureAmt | currency}}</p>
                            </div>
                        </covered-input-number>
                    </div>
                    <div class="col-12">
                        <div class="w-6 pr-2">
                            <covered-dropdown id="ddApr" placeholder="APR" [items]="lookups.aprs" [required]="true"
                                optionLabel="offerAprDesc" optionValue="offerAprPct" formControlName="apr">
                            </covered-dropdown>
                        </div>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddTerm" placeholder="Term" [items]="lookups.normalTerms" [required]="true"
                            optionLabel="offerTermDesc" optionValue="offerTermDesc" formControlName="term">
                        </covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddCashTerm" placeholder="Same as Cash Term"
                            [items]="lookups.sameAsCashTerms" optionLabel="offerTermDesc" optionValue="offerTermDesc"
                            formControlName="cashTerm" [required]="true"></covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddMinPayType" placeholder="Min Payment Type"
                            [items]="lookups.minPaymentTypes" (optionSelected)="minPayTypeSelected($event)"
                            optionLabel="minPaymentTypeDesc" optionValue="minPaymentTypeID" formControlName="minPayType"
                            [required]="true"></covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-dropdown *ngIf="matrixRowForm.value.downPayType != '='" id="ddMinPayment"
                            placeholder="Min Payment $" [items]="lookups.minPayment" [disabled]="disableMinPayment"
                            optionLabel="offerTermDesc" optionValue="offerTermAmt" formControlName="minPayment"
                            [required]="true"></covered-dropdown>
                        <div *ngIf="matrixRowForm.value.downPayType == '='">&nbsp;</div>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddDwnPayType" placeholder="Down Payment Type" [items]="downPayTypes"
                            (optionSelected)="downPayTypeSelected($event)" optionLabel="name" optionValue="value"
                            formControlName="downPayType" [required]="true"></covered-dropdown>
                    </div>
                    <div class="col-6">
                        <covered-dropdown *ngIf="matrixRowForm.value.downPayType == '%'" id="ddDwnPayPct"
                            placeholder="Down Payment %" [items]="lookups.minDownPercentages"
                            optionLabel="offerTermDesc" optionValue="offerTermAmt" formControlName="downPayPct"
                            [required]="true"></covered-dropdown>
                        <covered-input-number *ngIf="matrixRowForm.value.downPayType == '$'" id="txtDwnPayAmt"
                            placeholder="Down Payment Amt" formControlName="downPayAmt" [required]="true">
                        </covered-input-number>
                        <div *ngIf="matrixRowForm.value.downPayType == '='">&nbsp;</div>
                    </div>
                    <div class="col-6">
                        <covered-dropdown id="ddMctDiscRate" placeholder="Merchant Discount Rate"
                            [items]="lookups.merchantDiscountRates" optionLabel="offerMDRDesc" optionValue="offerMDRPct"
                            formControlName="discountRate" [required]="true"></covered-dropdown>
                    </div>
                </div>
                <div class="flex flex-row-reverse">
                    <covered-button id="btnSaveMatOption" buttonType="submit" [disabled]="!matrixRowForm.valid">
                        <span class="px-4 text-lg">Save</span>
                    </covered-button>

                </div>
            </form>
        </div>
    </div>
</div>

<covered-dialog [isVisible]="showCopyMatrix" header="Copy Offer Matrix" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showCopyMatrix=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="w-12">
            <covered-form-text id="txtCopyMatrix" placeholder="New Offer Matrix Name" [required]="true"
                [(ngModel)]="newOfferMatrixName">
            </covered-form-text>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveCopy" label="Save" (buttonClick)="saveCopyOfferMatrix();"
                [disabled]="!newOfferMatrixName"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showTestMatrix" header="Test Offer" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showTestMatrix=false;" [style]="{'width': '45vw'}">
    <div body>
        <form *ngIf="testOfferFormLoaded" [formGroup]="testOfferForm">
            <div class="grid">
                <div class="col-12">
                    <covered-dropdown id="ddtestCredBand" placeholder="Credit Band" [items]="lookups.loanGrades"
                        optionLabel="loanGradeShortDesc" optionValue="loanGradeLongDesc" formControlName="creditBand"
                        [required]="true">
                    </covered-dropdown>
                </div>
                <div class="col-12">
                    <covered-dropdown id="ddtestState" placeholder="State" [items]="lookups.offerStates"
                        optionLabel="stateFullName" optionValue="stateAbb" formControlName="state" [required]="true">
                    </covered-dropdown>
                </div>
                <div class="col-12">
                    <covered-dropdown id="ddtestProcedure" placeholder="Procedure" [items]="lookups.providerProducts"
                        optionLabel="providerProductDesc" optionValue="providerProductDesc" [required]="true"
                        formControlName="procedure">
                    </covered-dropdown>
                </div>
                <div class="col-12">
                    <covered-input-number id="txtTestAmt" placeholder="Loan Amount" formControlName="amount"
                        [required]="true"></covered-input-number>
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnTestOfferMatrix" label="Test" (buttonClick)="executeTestOfferMatrix();"
                [disabled]="!testOfferForm.valid"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showTestMatrixResults" header="Test Offer Results" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showTestMatrixResults=false;"
    [style]="{'width': '95vw'}">
    <div body class="grid">
        <div *ngIf="!testOfferError" class="col-12">
            <p-table #dt [value]="testOfferResults" styleClass="p-datatable-lg mr-4 mb-4 col-12" [paginator]="true"
                [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                responsiveLayout="scroll" [rows]="15" [rowHover]="true">
                <ng-template pTemplate="header">
                    <tr scope="row">
                        <th>Order</th>
                        <th>Loan amount</th>
                        <th>Loan interest</th>
                        <th>Down payment</th>
                        <th>Min Down Pmt</th>
                        <th>Max Down Pmt</th>
                        <th>Min Payment Amt</th>
                        <th>Down Pmt Min Pct</th>
                        <th>Term</th>
                        <th>Marketing Msg</th>
                        <th>Expires</th>
                        <th>Down Pmt Result</th>
                        <th>MDR</th>
                        <th>Loan Grade</th>
                        <th>Est Monthly Pmt</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                    <tr scope="row">
                        <td>{{row.offerOrder}}</td>
                        <td>{{row.loanAmount | currency }}</td>
                        <td>{{row.loanInterest}}</td>
                        <td>{{row.downPayment | currency }}</td>
                        <td>{{row.minDownPaymentAmt | currency }}</td>
                        <td>{{row.maxDownPaymentAmt | currency }}</td>
                        <td>{{row.minPaymentAmt | currency}}</td>
                        <td>{{row.downPaymentMinPct}}</td>
                        <td>{{row.term}}</td>
                        <td>{{row.marketingMessage}}</td>
                        <td>{{row.expireDate | date: 'MM/dd/yyyy'}}</td>
                        <td>{{row.downPaymentResult}}</td>
                        <td>{{row.loanMDR}}</td>
                        <td>{{row.loanGradeDescription}}</td>
                        <td>{{row.estMonthlyPaymentAmt | currency }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [colSpan]="15">No Offers Returned.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf="testOfferError" class="col-12">
            <span class="text-2xl cds-text-red">ERROR! Offer Matrix could not be tested!</span>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnCloseResults" label="Close" (buttonClick)="showTestMatrixResults=false;">
            </covered-button>
        </div>
    </div>
</covered-dialog>

<p-confirmDialog [key]="deOfrMtxDialKey" [style]="{width: '45vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-outlined" styleClass="text-3xl">
</p-confirmDialog>