<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg">
    <form *ngIf="hrefFormLoaded" [formGroup]="hrefForm" (ngSubmit)="saveHrefForm();">
        <div class="grid pr-4">
            
            <div class="col-5">
                <covered-form-text id="txtNm" placeholder="Name" formControlName="name" [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-5">
                <covered-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId" [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            
            <div class="col-5">
                <covered-form-text id="txtAcopy" placeholder="Copy" formControlName="text"></covered-form-text>
            </div>  
            <div class="col-4">
                <covered-form-text id="txtHref" placeholder="Href" formControlName="href"></covered-form-text>
            </div>      
            <div class="col-2">
                <covered-dropdown id="ddTgt" placeholder="Target" [items]="targets"
                    optionLabel="name" optionValue="value" formControlName="target">
                </covered-dropdown>
            </div>
            <div class="col-1 align-self-center">
                <p-checkbox [formControl]="$any(hrefForm.controls['required'])" [binary]="true" label="Required"></p-checkbox>
            </div>

            <div class="col-5">
                <covered-form-text id="txtclientclick" placeholder="Client Click" formControlName="client"></covered-form-text>
            </div>
            <div class="col-5">
                <covered-form-text id="txtCss" placeholder="Css Class" formControlName="css"></covered-form-text>                
            </div>

            <div class="col-2 flex flex-row-reverse">
                <covered-button id="btnS" label="Save" buttonType="submit"></covered-button>
                <covered-button id="btnCncl" label="Cancel" class="px-4 outline" (buttonClick)="cancelForm()"></covered-button>                
            </div>
        </div>]
    </form>
</div>