import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn } from '@covered/covered-ui';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { PortfolioItem } from 'src/app/models/customer-item';
import { AdminReportPastLog, AdminReportSettlementDownoad } from 'src/app/models/report/report-dashboard';
import { ApiService } from 'src/app/services/api.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-past-settlements',
  templateUrl: './past-settlements.component.html',
  styleUrls: ['./past-settlements.component.scss']
})
export class PastSettlementsComponent implements OnInit {

  loading: boolean = false;
  showDownloadRange: boolean = false;
  first: number = 0;
  today: Date = new Date();
  startDate: Date | null = null;
  endDate: Date | null = null;
  exportFileNameDflt: string = 'report_past_settlements';
  exportFileName: string = '';

  portfolioItem: PortfolioItem | null | undefined;
  
  pastLogs: AdminReportPastLog[] = [];
  reportRows: AdminReportSettlementDownoad[] = [];
  reportColumns: TableColumn[] = [];
  bcItems: MenuItem[] = [{ label: 'Reports', routerLink: ['/report'] }, { label: 'Past Settlements' }];
  columns: TableColumn[] = [];
  filterFields: string[] = ['CreateDateDisplay', 'CreditAmount', 'CreditCount', 'DebitAmount'];

  @ViewChild('dtRpt') dtRpt: Table = {} as Table;
  constructor(private api: ApiService,
    private router: Router,
    private toastService: ToastService,
    private portfolioService: PortfolioService) { }

  ngOnInit(): void {
    this.initColumns();
    this.intiReportColumns();
    this.portfolioService.portfolio$.subscribe(p => {
      this.portfolioItem = p;
      this.getPastLogs();
    });
  }

  getColumns(): TableColumn[] {
    var cols = this.columns.filter(x => x.show);
    return cols;
  }

  getPastLogs() {
    if (this.portfolioItem && this.portfolioItem.customerGuid) {
      this.loading = true;

      let pastSub = this.api.get(`adminreport/settlement-pastlog/${this.portfolioItem.customerGuid}`)
      .subscribe({
        next: (data: AdminReportPastLog[]) => {          
          this.pastLogs = data.filter(d => d.customerID == this.portfolioItem?.customerId);
          this.pastLogs = this.pastLogs.sort((a, b) => new Date(b.createDateDisplay).getTime() - new Date(a.createDateDisplay).getTime());
          this.pastLogs.forEach(p => {p.createDateDisplay = new Date(p.createDateDisplay);})
          this.loading = false;
        },
        error: (err: any) => {
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get report data. See log for details.'
          }, 'center');
          console.error(err);
        },
        complete: () => { pastSub.unsubscribe(); }
    });
    
    }
  }

  initColumns() {
    this.columns = [
      {
        field: 'createDateDisplay',
        header: 'Create Date',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'debitCount',
        header: 'Debit #',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'debitAmount',
        header: 'Debit $',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'creditCount',
        header: 'Credit #',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'creditAmount',
        header: 'Credit $',
        clickable: true,
        show: true,
        sortable: true,
        class: null
      }
    ];
  }

  selectRow(row: AdminReportPastLog){
    this.router.navigate([`/report/settlement-summary`], {
      queryParams: {
        dt: row.createDateDisplay.getTime()
      }
    });
  }

  downloadRow(row: AdminReportPastLog){
    this.startDate = row.createDateDisplay;
    this.downloadReport();
  }

  downloadReport() {
    var post = {
      reportType: 'PastSettlements',
      startDate: this.startDate,
      endDate: this.endDate,
      customerGuid: this.portfolioItem?.customerGuid
    };

    let rptSub = this.api.post(`adminreport/settlement-download`, post)
    .subscribe({
      next: (data: AdminReportSettlementDownoad[]) => {   

        this.exportFileName = `${this.exportFileNameDflt}`;
        if (this.startDate) {
          this.exportFileName += `_${(this.startDate.getMonth()+1)}_${this.startDate.getDate()}_${this.startDate.getFullYear()}`;
        }    
        if (this.endDate) {
          this.exportFileName += ` - ${this.endDate.getMonth()+1}_${this.endDate.getDate()}_${this.endDate.getFullYear()}`
        }
        this.reportRows = data;
        setTimeout(() => {
          this.dtRpt.exportCSV();          
          this.cleanupReport();
        });
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to download report. See log for details.'
        }, 'center');
        console.error(err);
        this.cleanupReport();
      },
      complete: () => { rptSub.unsubscribe(); }
    })
  }

  intiReportColumns() {
    this.reportColumns = [
      {
        field: 'paymentDate',
        header: 'Payment Date',
        clickable: false,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'accountNumber',
        header: 'Account Number',
        clickable: false,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'loanNumber',
        header: 'Loan Number',
        clickable: false,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'name',
        header: 'Name',
        clickable: false,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'debitAmount',
        header: 'Debit Amount',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'creditAmount',
        header: 'Credit Amount',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'payType',
        header: 'Pay Type',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'approvalCode',
        header: 'Approval Code',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      },
      {
        field: 'transRef',
        header: 'Trans Ref',
        clickable: true,
        show: true,
        sortable: false,
        class: null
      }
    ];
  }

  cleanupReport() {
    this.reportRows = [];
    this.cancelReport();
  }

  cancelReport() {
    this.startDate = null;
    this.endDate = null;
    this.showDownloadRange = false;
  }

}
