<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">Unable to load Custom Fields.
</div>

<div *ngIf="!loading && !hasError && showList">

    <span class="text-2xl font-medium">Decision Engine Custom Fields</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 pt-4">
                    <p-table #dt [value]="fields" styleClass="p-datatable-lg mr-4 mb-4 col-12" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        responsiveLayout="scroll" [rows]="100" [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <div class="flex justify-content-start">
                                    <span class="text-2xl font-medium">Custom Fields</span>
                                </div>
                                <div *ngIf="hasEditFeature" class="flex flex-grow-1 justify-content-end">
                                    <covered-button id="btnNewProvider" class="m-2" (buttonClick)="goToAddField()">
                                        <span class="px-4">Add Field</span>
                                    </covered-button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr scope="row">
                                <th>Name</th>
                                <th>Type</th>
                                <th *ngIf="hasEditFeature"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>
                                    <span *ngIf="hasEditFeature" class="cds-font cds-text-color cursor-pointer" (click)="editField(rowData)">
                                        {{rowData.fieldName}}
                                    </span>
                                    <span *ngIf="!hasEditFeature">
                                        {{rowData.fieldName}}
                                    </span>                                    
                                </td>
                                <td>
                                    {{getFieldType(rowData.customFieldDefinition)}}
                                </td>
                                <td *ngIf="hasEditFeature">
                                    <i  class="pi pi-trash cursor-pointer" title="Delete" aria-hidden="true"
                                        (click)="confirmDeleteField(rowData)"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="7">No Custom Fields found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

</div>


<div *ngIf="!loading && !hasError && showEdit">

    <span class="text-2xl font-medium">Provider List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card w-screen-fit p-4">
            <span class="text-2xl font-medium">Custom Field {{customFieldTitle}}</span>
            <div *ngIf="customFieldFormsLoaded">
                <form [formGroup]="customFieldNameForm">
                    <div class="grid pt-4">
                        <div class="col-12 md:col-6">
                            <covered-form-text id="txtName" placeholder="Name" [required]="true" formControlName="name">
                            </covered-form-text>
                        </div>
                        <div class="col-12 md:col-6">
                            <covered-dropdown id="ddType" [items]="fieldTypes" placeholder="Type" [required]="true"
                                optionLabel="name" optionValue="value" formControlName="type">
                            </covered-dropdown>
                        </div>
                    </div>
                </form>
                <form [formGroup]="customFieldMatrixForm" *ngIf="customFieldNameForm.value.type == 'matrix'" (ngSubmit)="saveMatrix()">
                    <div class="grid">
                        <div class="col-12 md:col-6 grid align-self-baseline">
                            <span class="col-12 font-bold text-2xl pt-4">Vertical Axis</span>
                            <div class="col-6">
                                <covered-dropdown id="ddVertDS" placeholder="Data Source" [required]="true"
                                    [items]="lookups.ruleDataSources" optionLabel="dataSourceName"
                                    optionValue="dataSourceID" [showClear]="false" formControlName="vertDataSource">
                                </covered-dropdown>
                            </div>
                            <div class="col-4">
                                <covered-autocomplete id="acVertDataField" placeholder="Field" [required]="true"
                                    emptyMessage="No fields..." [showEmptyMessage]="true" [suggestions]="vertDataFields"
                                    [completeMethod]="searchVertDataFields" optionLabel="fieldName"
                                    [forceSelection]="true" formControlName="vertDataField"></covered-autocomplete>
                            </div>
                            <div class="col-2 pt-5">
                                <p-checkbox [formControl]="$any(customFieldMatrixForm.controls['vertIsNumeric'])"
                                    [binary]="true" label="Numeric">
                                </p-checkbox>
                            </div>

                            <span class="col-12 font-bold">Create Ranges</span>
                            <div class="col-12">
                                <covered-dropdown id="ddVertRanges" placeholder="Select number of rows" [required]="true"
                                    [items]="rangeNums" optionLabel="text" optionValue="value" [showClear]="false"
                                    formControlName="vertRows" (optionSelected)="setVertAxisNumRows($event)">
                                </covered-dropdown>
                            </div>
                            <div class="col-12">
                                <div formArrayName="vertRanges">
                                    <div *ngFor="let range of customFieldMatrixForm.controls.vertRanges.controls; index as i">
                                        <div class="grid" [formGroupName]="i">
                                            <span class="col-2 pt-5">Range {{i + 1}}</span>
                                            <div class="col-5">
                                                <covered-form-text id="txtVRmin{{i}}" placeholder="min" [required]="true"
                                                    formControlName="min"></covered-form-text>
                                            </div>
                                            <div class="col-5">
                                                <covered-form-text id="txtVRmax{{i}}" placeholder="max" [required]="true"
                                                    formControlName="max"></covered-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 md:col-6 md:pl-4 grid align-self-baseline">
                            <span class="col-12 font-bold text-2xl pt-4">Horizontal Axis</span>
                            <div class="col-6">
                                <covered-dropdown id="ddHorzDS" placeholder="Data Source" [required]="true"
                                    [items]="lookups.ruleDataSources" optionLabel="dataSourceName"
                                    optionValue="dataSourceID" [showClear]="false" formControlName="horzDataSource">
                                </covered-dropdown> 
                            </div>
                            <div class="col-4">
                                <covered-autocomplete id="acHorzDataField" placeholder="Field" [required]="true"
                                    emptyMessage="No fields..." [showEmptyMessage]="true" [suggestions]="horzDataFields"
                                    [completeMethod]="searchHorzDataFields" optionLabel="fieldName"
                                    [forceSelection]="true" formControlName="horzDataField"></covered-autocomplete>
                            </div>
                            <div class="col-2 pt-5">
                                <p-checkbox [formControl]="$any(customFieldMatrixForm.controls['horzIsNumeric'])"
                                    [binary]="true" label="Numeric">
                                </p-checkbox>
                            </div>

                            <span class="col-12 font-bold">Create Ranges</span>
                            <div class="col-12">
                                <covered-dropdown id="ddHorzRanges" placeholder="Select number of rows" [required]="true"
                                    [items]="rangeNums" optionLabel="text" optionValue="value" [showClear]="false"
                                    formControlName="horzRows" (optionSelected)="setHorzAxisNumRows($event)">
                                </covered-dropdown>
                            </div>
                            <div class="col-12">
                                <div formArrayName="horzRanges">
                                    <div *ngFor="let range of customFieldMatrixForm.controls.horzRanges.controls; index as i">
                                        <div class="grid" [formGroupName]="i">
                                            <span class="col-2 pt-5">Range {{i + 1}}</span>
                                            <div class="col-5">
                                                <covered-form-text id="txtVRmin{{i}}" placeholder="min" [required]="true"
                                                    formControlName="min"></covered-form-text>
                                            </div>
                                            <div class="col-5">
                                                <covered-form-text id="txtVRmax{{i}}" placeholder="max" [required]="true"
                                                    formControlName="max"></covered-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 flex flex-row-reverse pt-4 pb-4">
                            <covered-button id="btnBuildMatrix" (buttonClick)="buildMatrix()" [disabled]="disableMatrixBtn()">
                                <span class="px-4">Build Matrix</span>
                            </covered-button>
                        </div>
                        <div class="col-12 pt-8" *ngIf="customFieldMatrixForm.valid && showMatrixTable">
                           <div class="grid">
                                <div class="col-offset-2 col-10 header-bg h-4rem flex">
                                    <div class="flex-grow-1 text-center align-self-center">{{getHorizontalFieldName()}}</div>
                                </div>                            
                           </div>                            
                           <div class="grid sticky z-5 top-0 pt-4">
                                <div class="col-offset-2"></div>
                                <div class="col cell-border" *ngFor="let range of customFieldMatrixForm.controls.horzRanges.controls; index as i">
                                    {{range.value.min?.toLowerCase() == "null" ? "Null" : range.value.min + ' - ' + range.value.max}}
                                </div>
                           </div>
                           <div class="grid">
                                <div class="col-1 header-bg write-vertical matrix-vert-height">
                                    {{getVerticalFieldName()}}
                                </div>
                                <div class="col grid pt-4">
                                    <ng-template ngFor let-range [ngForOf]="customFieldMatrixForm.controls.vertRanges.controls" let-x="index">
                                        <div class="col-1 mb-4 text-align-center align-self-center cell-border py-5">
                                            {{range.value.min?.toLowerCase() == "null" ? "Null" : range.value.min + ' - ' + range.value.max }}
                                        </div>
                                        <div class="col-11">
                                            <div formArrayName="vertRanges" class="grid">
                                                <div *ngFor="let result of getMatrixRow(x); index as y" [class]="getMatrixCellClass(result)">
                                                    <div [formGroupName]="y" class="w-12">
                                                        <covered-form-text id="txtResult{{x}}{{y}}" [formControl]="result.controls.result"></covered-form-text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>                                   
                                </div>
                           </div>
                        </div>
                    </div>
                    <div class="flex flex-row-reverse">
                        <covered-button id="btnSaveMatrix" buttonType="submit" 
                            [disabled]="!customFieldNameForm.valid || !customFieldMatrixForm.valid">
                            <span class="px-4">Save</span>
                        </covered-button>
                        <covered-button id="btnCancelMatrix" class="outline mr-4" (buttonClick)="cancelEdit()" label="Cancel"></covered-button>

                    </div>
                </form>
                <form [formGroup]="customFieldCaseForm" *ngIf="customFieldNameForm.value.type == 'case'" (ngSubmit)="saveCase()">
                    <div class="grid">
                        <div class="col-12 md:col-6 grid align-self-baseline">
                            <span class="col-12 font-bold text-2xl pt-4">Definition</span>
                            <div class="col-6">
                                <covered-dropdown id="ddCaseDS" placeholder="Data Source" [required]="true"
                                    [items]="lookups.ruleDataSources" optionLabel="dataSourceName"
                                    optionValue="dataSourceID" [showClear]="false" formControlName="dataSource">
                                </covered-dropdown>
                            </div>
                            <div class="col-4">
                                <covered-autocomplete id="acCaseDataField" placeholder="Field" [required]="true"
                                    emptyMessage="No fields..." [showEmptyMessage]="true" [suggestions]="caseDataFields"
                                    [completeMethod]="searchCaseDataFields" optionLabel="fieldName"
                                    [forceSelection]="true" formControlName="dataField"></covered-autocomplete>
                            </div>
                            <div class="col-2 pt-5">
                                <p-checkbox [formControl]="$any(customFieldCaseForm.controls['isNumeric'])"
                                    [binary]="true" label="Numeric">
                                </p-checkbox>
                            </div>

                            <span class="col-12 font-bold">Create Ranges</span>
                            <div class="col-12">
                                <covered-dropdown id="ddCaseRanges" placeholder="Select number of rows" [required]="true"
                                    [items]="rangeNums" optionLabel="text" optionValue="value" [showClear]="false"
                                    formControlName="rows" (optionSelected)="setCaseNumRows($event)">
                                </covered-dropdown>
                            </div>
                            <div class="col-12">
                                <div formArrayName="results">
                                    <div *ngFor="let result of customFieldCaseForm.controls.results.controls; index as i">
                                        <div class="grid" [formGroupName]="i">
                                            <span class="col-2 pt-5">Range {{i + 1}}</span>
                                            <div class="col-3">
                                                <covered-form-text id="txtCasemin{{i}}" placeholder="Min" [required]="true"
                                                    formControlName="min"></covered-form-text>
                                            </div>
                                            <div class="col-3">
                                                <covered-form-text id="txtCasemax{{i}}" placeholder="Max" [required]="true"
                                                    formControlName="max"></covered-form-text>
                                            </div>
                                            <div class="col-3">
                                                <covered-form-text id="txtCaseResult{{i}}" placeholder="Value" [required]="true"
                                                    formControlName="value"></covered-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="flex flex-row-reverse">
                        <covered-button id="btnSaveCase" buttonType="submit" 
                            [disabled]="!customFieldNameForm.valid || !customFieldCaseForm.valid">
                            <span class="px-4">Save</span>
                        </covered-button>
                        <covered-button id="btnCancelCase" class="outline mr-4" (buttonClick)="cancelEdit()" label="Cancel"></covered-button>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '45vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>