<p class="m-2"><strong>{{title}}</strong></p>
<form [formGroup]="expForm">
    <div class="d-flex mb-3">
        <div class="p-2 flex-fill">
            <covered-dropdown id="dd123" [items]="dsList" optionLabel="desc" placeholder="Data Source" optionValue="id"
                [showClear]="false" [required]="true" (optionSelected)="dsSelected($event, true);" formControlName="dsID">
            </covered-dropdown>
        </div>
        <div class="p-2 flex-fill">
            <covered-autocomplete [id]="id" placeholder="Field" [required]="true"
            emptyMessage="No fields..." [showEmptyMessage]="true" [suggestions]="searchFieldList"
            [completeMethod]="searchFields" optionLabel="desc" optionValue="id" minLength="0" completeOnFocus=true
            [forceSelection]="true" formControlName="field"></covered-autocomplete>
<!-- 
            <covered-dropdown id="dd124" [items]="fieldList" optionLabel="desc" placeholder="Field" optionValue="id"
                [showClear]="false" [required]="true" formControlName="fieldID" (optionSelected)="fieldSelected($event);">
            </covered-dropdown> -->
        </div>
        <div class="p-2 flex-fill">
            <p-checkbox [formControl]="$any(expForm.controls['num'])" [binary]="true" label="Numeric">
            </p-checkbox>
        </div>
    </div>
    <div class="mb-3 pb-3">
        <p class="mb-2"><strong>Create Ranges</strong></p>
        <p class="m-0">Select number of columns</p>
        <covered-dropdown id="dd1277" [items]="rangeList" optionLabel="desc" optionValue="id" [showClear]="false"
            (optionSelected)="initRangeForms($event);" formControlName="dsID" [required]="true" formControlName="ranges">
        </covered-dropdown>
    </div>
    <div class="mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Min</th>
                    <th scope="col">Max</th>
                </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="rangeForms">
                    <ng-container *ngFor="let rng of rangeForms.controls; let i = index">
                        <tr [formGroup]="rng">
                            <th scope="row">{{i+1}}</th>
                            <td>
                                <input class="form-control form-control-sm" type="text" appNumericOnly decimals="6"
                                [ngClass]="rng.controls?.min?.errors ? 'is-invalid' : 'is-valid'"
                                    formControlName="min">
                            </td>
                            <td>
                                <input class="form-control form-control-sm" type="text" appNumericOnly decimals="6"
                                [ngClass]="rng.controls?.max?.errors ? 'is-invalid' : 'is-valid'"
                                    formControlName="max">
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>

            </tbody>
        </table>
    </div>
</form>