import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { OfferFormGroup } from 'src/app/models/form-groups';
import { OfferRanges, OfferRangesUpload } from 'src/app/models/offer-ranges';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-offer',
  templateUrl: './provider-offer.component.html',
  styleUrls: ['./provider-offer.component.scss']
})
export class ProviderOfferComponent implements OnInit {

  private _provider: CustomerProviderModel | null = null;

  @Input() set provider(value: CustomerProviderModel | null) {
    this._provider = value;    
    this.getOffers();
  }
  get provider(): CustomerProviderModel | null { return this._provider; }
  
  lookups: ICustomerLookups = {} as ICustomerLookups;
  offerForm: FormGroup<OfferFormGroup> = {} as FormGroup;
  
  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.providerService.customerLookups$
    .subscribe({
      next: (lookups: ICustomerLookups) => {
        this.lookups = lookups;
        if (!this.offerForm.value) this.createForm();
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Provider information. See log for details'
        }, 'center');
        console.error(err);
      }
    });
  }

  createForm() {
    this.offerForm = new FormGroup<OfferFormGroup>({      
      minLoanAmt: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      maxLoanAmt: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_ProcedureAmt: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_ProcedureAmt: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_APRAmt: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_APRAmt: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_DownPaymentPct: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_DownPaymentPct: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_Term: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_Term: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_MDR: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_MDR: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_VantageScore: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_VantageScore: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      min_MinPaymentAmount: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
      max_MinPaymentAmount: new FormControl<string|null>(null, { nonNullable: true, validators: [Validators.required]}),
    });
  }

  getOffers() { 
    if (!this.offerForm.value) this.createForm();

    let offerSub = this.apiService.get(`provider/offers/${this.provider?.customerGuid}`)
    .subscribe({
      next: (offers: OfferRanges) => {
        if (offers) {
          this.loadFormData(offers);
        }
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Offer ranges. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { offerSub.unsubscribe(); }
    })
  }

  loadFormData(offers: OfferRanges) {
    this.offerForm.patchValue({
      minLoanAmt: this.formatOffer(offers.minLoanAmt, 2),
      maxLoanAmt: this.formatOffer(offers.maxLoanAmt, 2),
      min_ProcedureAmt: this.formatOffer(offers.min_ProcedureAmt, 2),
      max_ProcedureAmt: this.formatOffer(offers.max_ProcedureAmt, 2),
      min_APRAmt: this.formatOffer(offers.min_APRAmt, 4),
      max_APRAmt: this.formatOffer(offers.max_APRAmt, 4),
      min_DownPaymentPct: this.formatOffer(offers.min_DownPaymentPct, 3),
      max_DownPaymentPct: this.formatOffer(offers.max_DownPaymentPct, 3),
      min_Term: offers.min_Term?.toString(),
      max_Term: offers.max_Term?.toString(),
      min_MDR: this.formatOffer(offers.min_MDR, 3),
      max_MDR: this.formatOffer(offers.max_MDR, 3),
      min_VantageScore: offers.min_VantageScore?.toString(),
      max_VantageScore: offers.max_VantageScore?.toString(),
      min_MinPaymentAmount: this.formatOffer(offers.min_MinPaymentAmount, 2),
      max_MinPaymentAmount: this.formatOffer(offers.max_MinPaymentAmount, 2),
    });
  }

  formatOffer(amount: number | undefined, places: number): string | null {
    if (amount == null || amount == undefined) return null;
    return parseFloat(amount.toString()).toFixed(places);
  }
  
  submitOffers() {
    let submitRanges: OfferRangesUpload = new OfferRangesUpload();
    submitRanges.customerGuid = this.provider?.customerGuid || '';
    submitRanges.maxLoanAmt = this.offerForm.value.maxLoanAmt ? +this.offerForm.value.maxLoanAmt : undefined;
    submitRanges.max_APRAmt = this.offerForm.value.max_APRAmt ? +this.offerForm.value.max_APRAmt : undefined;
    // submitRanges.max_DownPayment = this.offerForm.value.max_DownPayment ? +this.offerForm.value.max_DownPayment : undefined;
    submitRanges.max_DownPaymentPct = this.offerForm.value.max_DownPaymentPct ? +this.offerForm.value.max_DownPaymentPct : undefined;
    submitRanges.max_MDR = this.offerForm.value.max_MDR ? +this.offerForm.value.max_MDR : undefined;
    submitRanges.max_MinPaymentAmount = this.offerForm.value.max_MinPaymentAmount ? +this.offerForm.value.max_MinPaymentAmount : undefined;
    submitRanges.max_ProcedureAmt = this.offerForm.value.max_ProcedureAmt ? +this.offerForm.value.max_ProcedureAmt : undefined;
    submitRanges.max_Term = this.offerForm.value.max_Term ? +this.offerForm.value.max_Term : undefined;
    submitRanges.max_VantageScore = this.offerForm.value.max_VantageScore ? +this.offerForm.value.max_VantageScore : undefined;
    submitRanges.minLoanAmt = this.offerForm.value.minLoanAmt ? +this.offerForm.value.minLoanAmt : undefined;
    submitRanges.min_APRAmt = this.offerForm.value.min_APRAmt ? +this.offerForm.value.min_APRAmt : undefined;
    // submitRanges.min_DownPayment = this.offerForm.value.min_DownPayment ? +this.offerForm.value.min_DownPayment : undefined;
    submitRanges.min_DownPaymentPct = this.offerForm.value.min_DownPaymentPct ? +this.offerForm.value.min_DownPaymentPct : undefined;
    submitRanges.min_MDR = this.offerForm.value.min_MDR ? +this.offerForm.value.min_MDR : undefined;
    submitRanges.min_MinPaymentAmount = this.offerForm.value.min_MinPaymentAmount ? +this.offerForm.value.min_MinPaymentAmount : undefined;
    submitRanges.min_ProcedureAmt = this.offerForm.value.min_ProcedureAmt ? +this.offerForm.value.min_ProcedureAmt : undefined;
    submitRanges.min_Term = this.offerForm.value.min_Term ? +this.offerForm.value.min_Term : undefined;
    submitRanges.min_VantageScore = this.offerForm.value.min_VantageScore ? +this.offerForm.value.min_VantageScore : undefined;

    let submitSub = this.apiService.post(`provider/offers`, submitRanges)
    .subscribe({
      next: () => { 
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Offer ranges successfully added / updated.'
        })
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save offer ranges. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { submitSub.unsubscribe(); }
    });


   }
}
