
<span class="text-2xl font-medium">Upcoming {{ header }}</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="cds-card p-4">
        <div class="grid">
            
            <div class="col-12 flex">
                <span class="w-6 text-2xl font-bold">Upcoming {{ header }}</span>
                <span class="w-2 font-bold" [ngClass]="upcomingSelected == 0 ? 'cds-placeholder-color' : 'cds-text-color cursor-pointer'" 
                    (click)="getUpcoming(0)">Today</span>
                <span class="w-2 font-bold" [ngClass]="upcomingSelected == 1 ? 'cds-placeholder-color' : 'cds-text-color cursor-pointer'" 
                    (click)="getUpcoming(1)">Tomorrow</span>
            </div>

            <div class="col-12 pt-8">
                <span class="w-12 pt-5 text-2xl font-bold">Data by Criteria</span>
            </div>
            <div class="col-12">
                <form *ngIf="criteriaFormLoaded" [formGroup]="criteriaForm" (ngSubmit)="submitCriteriaForm()">
                    <div class="grid">
                        <div class="col-6">
                            <covered-dropdown id="State" placeholder="State" [items]="lookups.states"
                                [showClear]="true" optionLabel="desc" optionValue="id"
                                formControlName="state"></covered-dropdown>
                        </div>
                        <div class="col-6">
                            <covered-dropdown id="LegalStatus" placeholder="Legal Status"
                                [items]="lookups.legalStatuses" [showClear]="true" optionLabel="desc"
                                optionValue="id" formControlName="legal"></covered-dropdown>
                        </div>
                        <div class="col-6">
                            <covered-dropdown id="CommentCode" placeholder="Comment Code" [items]="lookups.noteCategories" 
                                [showClear]="true" optionLabel="desc" optionValue="id" formControlName="commentCode">
                            </covered-dropdown>
                        </div>
                        <div class="col-6">
                            <covered-dropdown id="loanStatus" placeholder="Loan Status"
                                [items]="lookups.loanStatuses" [showClear]="true" optionLabel="desc"
                                optionValue="id" formControlName="loanStatus"></covered-dropdown>
                        </div>

                        <div class="col-12 pt-4 flex flex-wrap align-self-center">
                            <div class="w-12 text-lg font-bold">Date Range</div>
                            <div class="w-4">
                                <covered-datepicker id="dtFrom" placeholder="Start" panelStyleClass="dp-max"
                                    [maxDate]="today" formControlName="startDate">
                                    <div class="w-12 my-1 p-error text-xs"
                                        *ngIf="!criteriaForm.controls.startDate.pristine && criteriaForm.controls.startDate.errors">                                            
                                        <p *ngIf="criteriaForm.controls.startDate.errors?.invalidDateRange">Start Date must be before End Date.</p>
                                    </div>
                                </covered-datepicker>
                            </div>
                            <div class="w-2 px-4 align-self-center text-center">
                                <span class="font-bold text-lg">TO</span>
                            </div>
                            <div class="w-4">
                                <covered-datepicker id="dtTo" placeholder="End" panelStyleClass="dp-max"
                                    [maxDate]="today" formControlName="endDate">
                                    <div class="w-12 my-1 p-error text-xs"
                                        *ngIf="!criteriaForm.controls.startDate.pristine && criteriaForm.controls.startDate.errors">                                            
                                        <p *ngIf="criteriaForm.controls.startDate.errors?.invalidDateRange">Start Date must be before End Date.</p>
                                    </div>
                                </covered-datepicker>
                            </div>
                            <div class="w-2 pl-5 align-self-center">
                                <covered-button id="btnFormSubmit" buttonType="submit" class="outline"
                                    label="Search" [disabled]="!criteriaForm.valid"></covered-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-12 pt-8">
                <span class="w-12 text-2xl font-bold">Data by Loan</span>
            </div>
            <div class="col-6">
                <covered-form-text id="txtLoanSrch" placeholder="Loan Number(s)" [(ngModel)]="loanNumsSearch">
                </covered-form-text>
            </div>
            <div class="col-2 align-self-center">
                <covered-button id="btnLoanSrch" label="Search" class="px-4 outline"
                    [disabled]="!loanNumsSearch.length" (buttonClick)="searchLoans()"></covered-button>
            </div>
            
            <div class="col-12 pt-4">
                <app-custom-grid #cgComplianceData 
                    id="cgComplianceData" 
                    [overrideCustomer]="true">
                </app-custom-grid>                    
            </div>

        </div>
    </div>
</div>