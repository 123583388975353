<span class="text-2xl font-medium">User Role List</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="cds-card w-screen-fit p-2">
        <div class="flex flex-row-reverse">
            <div class="m-4">
                <covered-button id="btnSaveRecs" label="ADD USER ROLE" (buttonClick)="create()">
                </covered-button>
            </div>
            <div class="m-4">
                <covered-button id="btnSaveRecs" label="ENTITIES" (buttonClick)="entities()">
                </covered-button>
            </div>
        </div>
        <div *ngIf="loading" class="m-5">
            <div class="text-2xl">Loading...</div>
            <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
        </div>
        <div *ngIf="!loading">
            <p-table #dt [value]="userTypes" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                [globalFilterFields]="filterFields" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
                [(first)]="first" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                responsiveLayout="scroll" [rows]="100" [rowHover]="true" [columns]="columns">
                <ng-template pTemplate="caption">
                    <div class="d-flex flex-row-reverse mb-3">
                        <div class="p-1">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" aria-hidden="true"></i>
                                <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                    (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <ng-template ngFor let-col [ngForOf]="columns" let-i="index">
                            <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field" class="font-medium"
                                scope="col">{{ col.header }}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                        </ng-template>
                        <th scope="col"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td *ngFor="let c of getColumns()">
                            <span *ngIf="c.field">{{rowData[c.field]}}</span>
                            <p *ngIf="c.header === ''" class="m-0 p-0">
                                <i class="pi pi-pencil cursor-pointer mr-4" title="Edit" aria-hidden="true"
                                    (click)="edit(rowData)"></i>
                            </p>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [colSpan]="7">No Role found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
