import { Component, Input, OnInit } from '@angular/core';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { NameValuePair } from 'src/app/models/name-value-pair';
import { ProviderInfo } from 'src/app/models/provider-info';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-branding',
  templateUrl: './provider-branding.component.html',
  styleUrls: ['./provider-branding.component.scss']
})
export class ProviderBrandingComponent implements OnInit {

  private _provider: CustomerProviderModel | null = null;
  @Input() set provider(value: CustomerProviderModel | null) { this._provider = value; }
  get provider(): CustomerProviderModel | null { return this._provider; }
  
  private readonly noFileStr: string = 'No file chosen';
  private readonly keyNames: string = "AdminCampaignBasePath,AdminCampaignBaseURL";

  lookups: ICustomerLookups = {} as ICustomerLookups; 
  showPreviewUrl: boolean = false;
  firstStepPreviewURL: string = '';
  configKeys: NameValuePair[] = [];
  
  providerInfo: ProviderInfo | null = null;
  logoFileName: string = this.noFileStr;
  landingFileName: string = this.noFileStr;
  recapFileName: string = this.noFileStr;
  loginFileName: string = this.noFileStr;

  logoFile: File | null = null;
  landingFile: File | null = null;
  recapFile: File | null = null;
  loginFile: File | null = null;

  logoStrip: string = '';
  pallette: string = '';
  accent: string = '';

  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void { 
    this.getConfigKeys();
    this.providerService.customerLookups$
    .subscribe({
      next: (lookups: ICustomerLookups) => {
        this.lookups = lookups;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Provider information. See log for details'
        }, 'center');
        console.error(err);
      }
    });
    let provInfoSub = this.providerService.infoSectionProvider$
    .subscribe({
      next: (provider: ProviderInfo | null) => { 
        this.providerInfo = provider;
        if (provider?.firstStepPreviewURL) {
          this.showPreviewUrl = true;
          this.firstStepPreviewURL = provider.firstStepPreviewURL;
        }
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to get Provider information. See log for details'
        }, 'center');
        console.error(err);
      },
      complete: () => { provInfoSub.unsubscribe(); }
    });

  }

  getConfigKeys() {
    let body = {
      customerGuid: this.provider?.customerGuid,
      keyNames: this.keyNames
    }
    let configSub = this.apiService.post(`provider/configKeys`, body)
    .subscribe({
      next: (data: NameValuePair[]) => { 
        this.configKeys = data;
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => { configSub.unsubscribe(); }
    });
  }

  goToPreview() {
    window.open(this.firstStepPreviewURL, "_blank");
  }

  logoFileUploaded(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.logoFileName = file.name;
      this.logoFile = file;
    }
    else {
      this.logoFileName = this.noFileStr;
      this.logoFile = null;
    }
  }

  landingFileUploaded(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.landingFileName = file.name;
      this.landingFile = file;
    }
    else {
      this.landingFileName = this.noFileStr;
      this.landingFile = null;
    }
  }

  recapFileUploaded(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.recapFileName = file.name;
      this.recapFile = file;
    }
    else {
      this.recapFileName = this.noFileStr;
      this.recapFile = null;
    }
  }

  loginFileUploaded(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.loginFileName = file.name;
      this.loginFile = file;
    }
    else {
      this.loginFileName = this.noFileStr
      this.loginFile = null;
    }
  }

  saveBranding() {
    this.toastService.underConstruction();
    // let basePath: string = this.configKeys ? this.configKeys[0].value : '';
    // let campaignPath: string = `${basePath}\\${this.providerInfo?.campaignFolder}`;
    // let savePath: string = `${campaignPath}\\images`;
    // let campaignImagesBaseURL: string = `${this.configKeys[1].value}/${this.providerInfo?.campaignFolder}/images/`;

    // let cssChangesXML = `<logoStrip>${this.logoStrip}</logoStrip>
    // <colorPallette>${this.pallette}</colorPallette>
    // <accentColor>${this.accent}</accentColor>
    // <logoimage>       upLoadLogoFileName     </logoimage>
    // <loginimage>      upLoadLoginFileName     </loginimage>
    // <recapimage>      upLoadRecapFileName     </recapimage>
    // <landingimage>    upLoadLandingFileName     </landingimage>`;

    // let body: any = {
    //   customerGuid: this.provider?.customerGuid,
    //   fupLogoURL: '',
    //   fupLandingPageURL: '',
    //   fupRecapPageURL: '',
    //   fupLoginPageURL: '',
    //   cssChangesXML: cssChangesXML
    // };

    // let brandSub = this.apiService.post(`provider/branding`, body)
    // .subscribe({
    //   next: () => {
    //     this.toastService.add({
    //       severity: 'success',
    //       summary: 'Success',
    //       detail: 'Provider branding successfully saved.'
    //     });
    //   },
    //   error: (err: any) => { 
    //     this.toastService.add({
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: 'Unable to save provider branding. See log for details.'
    //     });
    //     console.error(err);
    //   },
    //   complete: () => { brandSub.unsubscribe(); }
    // });

  }

}
