
<div>
    <span class="text-2xl font-medium">Manage Offer Lookup</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card w-screen-fit p-2">
            <p-tabView>
              <p-tabPanel header="Amt">
                <div *ngIf="loading">
                    <div class="text-2xl">Loading...</div>
                    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
                </div>
                <div *ngIf="!loading">
                    <app-offer-lookup-table 
                    [offers]="offers.offerAmounts" 
                    offerType="amt"
                    title="Amount"
                    [columns]="amtColumns">
                </app-offer-lookup-table>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Apr">
                <div *ngIf="loading">
                    <div class="text-2xl">Loading...</div>
                    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
                </div>
                <div *ngIf="!loading">
                    <app-offer-lookup-table 
                    [offers]="offers.aprPercents" 
                    offerType="apr"
                    title="Offer APR"
                    [columns]="aprColumns"></app-offer-lookup-table>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Merchant Discount Rate">
                <div *ngIf="loading">
                    <div class="text-2xl">Loading...</div>
                    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
                </div>
                <div *ngIf="!loading">
                    <app-offer-lookup-table [offers]="offers.merchantDiscountRates"
                    offerType="mdr"
                    title="Merchant Discount Rate"
                     [columns]="mdrColumns"></app-offer-lookup-table>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Min Down Pct">
                <div *ngIf="loading">
                    <div class="text-2xl">Loading...</div>
                    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
                </div>
                <div *ngIf="!loading">
                    <app-offer-lookup-table [offers]="offers.minDownPercents"
                    offerType="mdr"
                    title="Min Down Pct"
                     [columns]="minColumns"></app-offer-lookup-table>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Term">
                <div *ngIf="loading">
                    <div class="text-2xl">Loading...</div>
                    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
                </div>
                <div *ngIf="!loading">
                    <app-offer-lookup-table [offers]="offers.terms"
                    offerType="term"
                    title="Term"
                     [columns]="termColumns"></app-offer-lookup-table>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
    </div>
</div>
