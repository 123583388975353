<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider && showProcessors" class="pt-4">    
    <div class="w-12">
        <app-custom-grid #cgProcessors DataSourceName="dsAdminProcessorVendorServices"
            [overrideCustomer]="true" [rowSelectable]="false" (editRow)="editProcessor($event)">
        </app-custom-grid>
    </div>
</div>

<covered-dialog [isVisible]="showProcessorEdit" header="Edit Processor Vendor Service" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showProcessorEdit=false;" [style]="{'width': '45vw'}">
    <div body class="grid">
        <div class="col-12">
            <covered-form-text id="txtService" placeholder="Service Name" [(ngModel)]="serviceName" [disabled]="true">
            </covered-form-text>
        </div>
        <div class="col-12">
            <covered-form-textarea id="txtSecret" [rows]="5" placeholder="Payliance Card API Token Secret"
                labelStyleClass="font-medium" [(ngModel)]="apiSecret">
            </covered-form-textarea>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveRegion" label="Save" (buttonClick)="saveProcessor();" [disabled]="!apiSecret"></covered-button>
        </div>
    </div>
</covered-dialog>