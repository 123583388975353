<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && noType" class="text-5xl font-medium text-red-500">Unable to determine Report type to display.
</div>

<div *ngIf="!loading && !noType && showSummary">
    <span *ngIf="rptFull" class="text-2xl font-medium">Settlement Report - Full</span>
    <span *ngIf="rptProvider" class="text-2xl font-medium">Settlement Report - Provider</span>
    <span *ngIf="rptDay" class="text-2xl font-medium">Settlement Report - Day</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <span *ngIf="hasError" class="text-3xl font-medium text-red-500">Error! Unable to load report. Please try
        again.</span>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div *ngIf="rptFull" class="col-12 text-2xl">Full Summary Report</div>
                <div *ngIf="rptDay" class="col-12 text-2xl">Day Summary Report</div>
                <div *ngIf="rptProvider" class="col-12">
                    <div class="col-6 text-2xl">Provider Summary Report</div>
                    <div class="col-3 pt-3">
                        <covered-dropdown id="ddProvider" placeholder="Select Provider" [items]="portfolios"
                            optionLabel="name" [displayFirst]="false" [showClear]="false"
                            [(ngModel)]="selectedPortfolio" (ngModelChange)="onChange($event)">
                        </covered-dropdown>
                    </div>
                </div>
            </div>
            <div *ngIf="(rptFull || rptDay || selectedPortfolio) && !settlementsLoaded">
                <div class="text-2xl">Loading...</div>
                <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
            </div>
            <div *ngIf="(rptFull || rptDay || selectedPortfolio) && settlementsLoaded" class="grid">
                <div *ngIf="rptFull && rowApproved" class="col-12">
                    <div *ngIf="showBankFileBtn()" class="flex flex-row-reverse p-4">
                        <covered-button id="btnSaveRecs" label="Create Bank Files" (buttonClick)="showRemitSummary()">
                        </covered-button>
                    </div>
                </div>
                <div class="col-12 pt-4">
                    <p-table #dt [value]="settlements" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                        [globalFilterFields]="summaryHeaders" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [showCurrentPageReport]="false" [(first)]="first"
                        responsiveLayout="scroll" [rows]="settlementRows" [rowHover]="true" [columns]="summaryColumns"
                        stateStorage="local" [stateKey]="settlementsTblStoreKey">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <div class="w-2">
                                    <covered-button id="btnExport" label="Download CSV" class="outlined"
                                        (buttonClick)="dt.paginator=false;dt.exportCSV();dt.paginator=true;"
                                        [disabled]="!settlements.length">
                                    </covered-button>
                                </div>
                                <div class="w-8">
                                    <div>
                                        <span class="cursor-pointer" #spanAll id="spanAll"
                                            (click)="apprFilter = 'all';dt.clear();"
                                            [ngClass]="apprFilter == 'all' ? 'font-bold' : 'font-light'">Show All
                                            ({{settlements.length}})</span>
                                    </div>
                                    <div>
                                        <span class="cursor-pointer" #spanPending id="spanPending"
                                            (click)="apprFilter = 'pending';dt.filter('all', 'approvalStatus', 'notEquals');"
                                            [ngClass]="apprFilter == 'pending' ? 'font-bold' : 'font-light'">Pending
                                            Approval ({{getSettlementsCount('pending')}})</span>
                                    </div>
                                    <div>
                                        <span class="cursor-pointer" #spanApproved id="spanApproved"
                                            (click)="apprFilter = 'approved';dt.filter('all', 'approvalStatus', 'equals')"
                                            [ngClass]="apprFilter == 'approved' ? 'font-bold' : 'font-light'">Approved
                                            ({{getSettlementsCount('approved')}})</span>
                                    </div>
                                </div>
                                <div class="w-2 flex-grow-1 align-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" aria-hidden="true"></i>
                                        <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                            (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <ng-template ngFor let-col [ngForOf]="summaryColumns" let-i="index">
                                    <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                                        class="font-medium" scope="col">{{ col.header }}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                                </ng-template>
                                <th scope="col"></th>
                            </tr>
                            <tr>
                                <th *ngIf="rptFull" scope="col">
                                    <p-columnFilter type="text" field="customerName"></p-columnFilter>
                                </th>
                                <th *ngIf="rptProvider" scope="col"></th>
                                <th scope="col">
                                    <p-columnFilter type="date" field="settlementDate"></p-columnFilter>
                                </th>
                                <th *ngIf="rptDay" scope="col"></th>
                                <th colspan="11" scope="colgroup"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-pointer" (click)="getSummaryRecords(rowData)">
                                <td *ngIf="!rptDay">{{ rowData.customerName }}</td>
                                <td>{{ rowData.settlementDate | date: 'MM/dd/yyyy' }}</td>
                                <td *ngIf="rptDay">{{ rowData.numberProviders }}</td>
                                <td>{{ rowData.transactionCount }}</td>
                                <td>{{ rowData.totalAmount | currency }}</td>
                                <td>{{ rowData.adjustedCount }}</td>
                                <td>{{ rowData.adjustedAmount | currency }}</td>
                                <td>{{ rowData.canceledCount }}</td>
                                <td>{{ rowData.canceledAmount | currency }}</td>
                                <td>{{ rowData.payToProvider | currency }}</td>
                                <td>{{ rowData.clawbackCount }}</td>
                                <td>{{ rowData.clawbackFromProvider | currency }}</td>
                                <td>
                                    <p-badge [severity]="getApproveBadge(rowData.approvalStatus)"></p-badge> / <p-badge
                                        [severity]="rowData.isMissingBankInfo ? 'danger' : 'success'"></p-badge>
                                </td>
                                <td>
                                    <covered-button
                                        *ngIf="!rowData.isMissingBankInfo && rowData.approvalStatus != 'all'"
                                        id="btnApp{{rowData.dailySettlementID}}" label="Approve" class="outlined"
                                        (click)="$event.stopPropagation();approveSummary(rowData)"></covered-button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="12">No Settlements found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="rptFull && rowApproved" class="col-12">
                        <div class="flex flex-row-reverse p-4">
                            <covered-button *ngIf="showBankFileBtn()" id="btnSaveRecs" label="Create Bank Files"
                                (buttonClick)="showRemitSummary()"></covered-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && !noType && showRecords">
    <span class="text-2xl font-medium">Settlement Records</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <span *ngIf="hasError" class="text-3xl font-medium text-red-500">Error! Unable to load report. Please try
        again.</span>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="grid">
                <div class="col-12 text-2xl">Settlement Records</div>
            </div>
            <div *ngIf="!recordsLoaded">
                <div class="text-2xl">Loading...</div>
                <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
            </div>
            <div *ngIf="recordsLoaded" class="grid">
                <div class="col-12">
                    <div class="flex flex-row-reverse p-4">
                        <covered-button id="btnSaveRecs" label="Save Approvals" (buttonClick)="saveApprovals()"
                            [disabled]="allRecordsApproved || !selectedRecords || selectedRecords.length < 1">
                        </covered-button>
                    </div>
                </div>
                <div class="col-12 pt-4">
                    <p-table #dt2 [value]="settlementRecords" styleClass="p-datatable-lg mr-4 mb-4"
                        [columns]="recordColumns" [(selection)]="selectedRecords" [rowSelectable]="isRowSelectable"
                        dataKey="dailySettlementID" [globalFilterFields]="recordsHeaders" [paginator]="true"
                        [rowsPerPageOptions]="[15, 30, 50, 100]" [showCurrentPageReport]="false" [(first)]="firstRec"
                        responsiveLayout="scroll" [rows]="recordsRows" [rowHover]="true" stateStorage="local"
                        [stateKey]="recordsTblStoreKey">
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <div class="flex-grow-1 justify-content-start">
                                    <covered-button id="btnExport" label="Download CSV" class="outlined"
                                        (buttonClick)="dt2.paginator=false;dt2.exportCSV();dt2.paginator=true;"
                                        [disabled]="!settlementRecords.length">
                                    </covered-button>
                                </div>
                                <div class="flex flex-grow-1 justify-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" aria-hidden="true"></i>
                                        <input #txtSearchRec type="text" class="w-15rem" pInputText placeholder="Search"
                                            (input)="dt2.filterGlobal(txtSearchRec.value, 'contains')" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="w-3rem checkbox-table-center" scope="col">
                                    Approve?
                                    <p-tableHeaderCheckbox [disabled]="allRecordsApproved || allRecordsMissingBank">
                                    </p-tableHeaderCheckbox>
                                </th>
                                <ng-template ngFor let-col [ngForOf]="recordColumns" let-i="index">
                                    <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                                        class="font-medium" scope="col">{{ col.header }}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                                </ng-template>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-pointer" (click)="displayFullRecord(rowData)">
                                <td class="checkbox-table-center" (click)="$event.stopPropagation();">
                                    <p-tableCheckbox [value]="rowData"
                                        [disabled]="rowData.approved || rowData.isMissingBankInfo"></p-tableCheckbox>
                                </td>
                                <td>{{ rowData.customerName }}</td>
                                <td>{{ rowData.branchName }}</td>
                                <td>{{ rowData.loanNumber }}</td>
                                <td>{{ rowData.originationDate | date: 'MM/dd/yyyy' }}</td>
                                <td *ngIf="rowData.bankRouting">{{ rowData.bankRouting }}</td>
                                <td *ngIf="!rowData.bankRouting" class="text-red-500"
                                    (click)="$event.stopPropagation();editBankInfo(rowData);">N/A</td>
                                <td *ngIf="rowData.bankAccount">{{ rowData.bankAccount }}</td>
                                <td *ngIf="!rowData.bankAccount" class="text-red-500"
                                    (click)="$event.stopPropagation();editBankInfo(rowData);">N/A</td>
                                <td>{{ rowData.procedureServiceDate | date: 'MM/dd/yyyy' }}</td>
                                <td>{{ rowData.dueToProvider | currency }}</td>
                                <td>
                                    <p-badge [severity]="rowData.approved ? 'success' : 'warning'"></p-badge>
                                </td>
                                <td *ngIf="!rowData.approved" (click)="$event.stopPropagation();editRecord(rowData)">
                                    <div class="flex">
                                        <i class="pi pi-pencil cursor-pointer mr-2" title="Edit" aria-hidden="true"></i>
                                    </div>
                                </td>
                                <td *ngIf="rowData.approved"></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [colSpan]="recordsHeaders.length">No Records found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="p-4 flex flex-row-reverse">
                        <covered-button id="btnSaveRecs" label="Save Approvals" (buttonClick)="saveApprovals()"
                            [disabled]="allRecordsApproved || !selectedRecords || selectedRecords.length < 1">
                        </covered-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loading && !noType && showFinal">
    <span class="text-2xl font-medium">Settlement Summary</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="flex flex-row-reverse">
                <covered-button id="btnNachaTop" label="Create Nacha File(s)" (buttonClick)="createNacha()"
                    [disabled]="!activeNachaBtn"></covered-button>
            </div>
            <div class="grid">
                <div class="col-12 text-3xl font-bold">
                    Pay To Provider
                </div>
                <div class="col-offset-1 col-2 text-xl font-medium">Provider</div>
                <div class="col-1 text-xl font-medium"># of Loans</div>
                <div class="col-2 text-xl font-medium">Total Amount Today</div>
                <div class="col-6 text-xl font-medium">Total Amount Back</div>
                <ng-template ngFor let-row [ngForOf]="RemitPayTo" let-i="index">
                    <div class="col-offset-1 col-2">{{ row.providerName }}</div>
                    <div class="col-1">{{ row.loanCount }}</div>
                    <div class="col-2">{{ row.totalAmountOut | currency }}</div>
                    <div class="col-6">{{ row.totalAmountBack | currency }}</div>
                </ng-template>
                <div class="col-offset-1 col-2 py-3 font-medium">TOTAL</div>
                <div class="col-1 py-3">{{ payToProvTotLoans }}</div>
                <div class="col-8 py-3">{{ payToProvTotAmt | currency }}</div>

                <div class="col-12 text-3xl font-bold">
                    Clawback
                </div>
                <div class="col-offset-1 col-2 text-xl font-medium">Provider</div>
                <div class="col-1 text-xl font-medium"># of Loans</div>
                <div class="col-8 text-xl font-medium">Total Amount Today</div>
                <ng-template ngFor let-row [ngForOf]="RemitClawback" let-i="index">
                    <div class="col-offset-1 col-2">{{ row.providerName }}</div>
                    <div class="col-1">{{ row.loanCount }}</div>
                    <div class="col-8">{{ row.totalAmountBack | currency }}</div>
                </ng-template>
                <div class="col-offset-1 col-2 py-3 font-medium">TOTAL</div>
                <div class="col-1 py-3">{{ clawTotLoans }}</div>
                <div class="col-8 py-3">{{ clawTotAmt | currency }}</div>

                <div class="col-12 text-3xl font-bold">
                    Net Out
                </div>
                <div class="col-offset-1 col-2 text-xl font-medium">Provider</div>
                <div class="col-offset-1 col-2 text-xl font-medium">Total Amount Today</div>
                <div class="col-6 text-xl font-medium">Remaining Balance</div>
                <ng-template ngFor let-row [ngForOf]="RemitNetOut" let-i="index">
                    <div class="col-offset-1 col-2">{{ row.providerName }}</div>
                    <div class="col-offset-1 col-2">{{ row.totalAmountOut | currency }}</div>
                    <div class="col-6">{{ row.totalAmountBack | currency }}</div>
                </ng-template>
                <div class="col-offset-1 col-2 py-3 font-medium">TOTAL</div>
                <div class="col-offset-1 col-2 py-3">{{ netTotAmt | currency }}</div>
                <div class="col-6 py-3">{{ netTotBack | currency }}</div>
            </div>
            <div class="flex flex-row-reverse">
                <covered-button id="btnNachaBtm" label="Create Nacha File(s)" (buttonClick)="createNacha()"
                    [disabled]="!activeNachaBtn"></covered-button>
            </div>
        </div>
    </div>ß
</div>

<covered-dialog [isVisible]="showFullRecord" header="Settlement Record"
    headerClass="w-full text-2xl font-medium p-4 border-bottom-1 border-black-alpha-10" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showFullRecord=false;" [style]="{'width': '50vw'}">
    <div body class="px-4">
        <div class="grid">
            <div class="col-4">Customer Name:</div>
            <div class="col-8">{{ recordToShow?.customerName ?? '' }}</div>

            <div class="col-4">Target Id:</div>
            <div class="col-8">{{ recordToShow?.targetID ?? '' }}</div>

            <div class="col-4">Patient Id:</div>
            <div class="col-8">{{ recordToShow?.patientID ?? '' }}</div>

            <div class="col-4">Loan Number:</div>
            <div class="col-8">{{ recordToShow?.loanNumber ?? '' }}</div>

            <div class="col-4">Branch Id:</div>
            <div class="col-8">{{ recordToShow?.branchID ?? '' }}</div>

            <div class="col-4">Branch_ID:</div>
            <div class="col-8">{{ recordToShow?.branch_ID ?? '' }}</div>

            <div class="col-4">BranchName:</div>
            <div class="col-8">{{ recordToShow?.branchName ?? '' }}</div>

            <div class="col-4">Bank Model:</div>
            <div class="col-8">{{ recordToShow?.bankModel ? 'yes' : 'no' }}</div>

            <div class="col-4">Origination Date:</div>
            <div class="col-8">{{ recordToShow?.originationDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">MDR:</div>
            <div class="col-8">{{ recordToShow?.mdr ?? '' }}</div>

            <div class="col-4">Purchase Price Percent:</div>
            <div class="col-8">{{ recordToShow?.purchasePricePct | percent }}</div>

            <div class="col-4">Procedure Service Date:</div>
            <div class="col-8">{{ recordToShow?.procedureServiceDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Total Procedure Amount:</div>
            <div class="col-8">{{ recordToShow?.totalProcedureAmount | currency }}</div>

            <div class="col-4">Down Payment:</div>
            <div class="col-8">{{ recordToShow?.downPaymentAmount | currency }}</div>

            <div class="col-4">Due to Provider:</div>
            <div class="col-8">{{ recordToShow?.dueToProvider | currency }}</div>

            <div class="col-4">Provider Purchase Date:</div>
            <div class="col-8">{{ recordToShow?.providerPurchaseDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Discount:</div>
            <div class="col-8">{{ recordToShow?.discount | currency }}</div>

            <div class="col-4">Cancel:</div>
            <div class="col-8">{{ recordToShow?.cancelFlag ? 'yes' : 'no' }}</div>

            <div class="col-4">Cancel Date:</div>
            <div class="col-8">{{ recordToShow?.cancelDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Contract Adjustment:</div>
            <div class="col-8">{{ recordToShow?.contractAdjustment | currency }}</div>

            <div class="col-4">Contract Adjustment Date:</div>
            <div class="col-8">{{ recordToShow?.contractAdjustmentDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Settlement Date:</div>
            <div class="col-8">{{ recordToShow?.settlementDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Created Date:</div>
            <div class="col-8">{{ recordToShow?.createdDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Bank file date:</div>
            <div class="col-8">{{ recordToShow?.bankFileProducedDate ?? '' }}</div>

            <div class="col-4">Settlement File date:</div>
            <div class="col-8">{{ recordToShow?.settlementFileProducedDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Approved:</div>
            <div class="col-8">{{ recordToShow?.approved ? 'yes' : 'no' }}</div>

            <div class="col-4">Approved Date:</div>
            <div class="col-8">{{ recordToShow?.approvedDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Approver Id:</div>
            <div class="col-8">{{ recordToShow?.approverID ?? '' }}</div>

            <div class="col-4">Modified</div>
            <div class="col-8">{{ recordToShow?.modified ? 'yes' : 'no' }}</div>

            <div class="col-4">Modified Date:</div>
            <div class="col-8">{{ recordToShow?.modifiedDate | date: 'MM/dd/yyy' }}</div>

            <div class="col-4">Mofifier Id:</div>
            <div class="col-8">{{ recordToShow?.modifierID ?? '' }}</div>

            <div class="col-4">Modifier Notes:</div>
            <div class="col-8">{{ recordToShow?.modifiedNotes ?? '' }}</div>

        </div>
    </div>
    <div footer>
        <covered-button id="btnDialogViewOk" label="Close" class="p-button-outlined w-4"
            (buttonClick)="showFullRecord=false;">
        </covered-button>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showEditRecord" header="Edit / Delete Settlement Record"
    headerClass="w-full text-2xl font-medium p-4 border-bottom-1 border-black-alpha-10" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="cancelEditRecord(false)" [style]="{'width': '35vw'}">
    <div body class="p-4">
        <div class="flex flex-auto flex-wrap">
            <div class="w-12 pb-4">
                <p-checkbox name="deleteRecord" [binary]="true" label="Delete Settlement Record?"
                    ariaLabel="Delete Settlement Record" [(ngModel)]="deleteChecked"></p-checkbox>
            </div>
            <div class="w-12 pb-4">
                <covered-input-number id="amount" placeholder="Due to Provider" [(ngModel)]="dueToProviderEdit"
                    [disabled]="deleteChecked"></covered-input-number>
            </div>
            <div class="w-12">
                <span class="w-12 font-medium">Note: (required {{minNoteLength}} character minimum)</span>
            </div>
            <div class="w-12 align-content-middle justify-content-center">
                <textarea pInputTextarea [(ngModel)]="updateReason" [rows]="5"
                    class="w-full border-1 border-black-alpha-10 border-noround"></textarea>
            </div>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btneditDialogSave" label="Save" (buttonClick)="finalizeEditRecord();"
                [disabled]="updateReason.trim().length < minNoteLength">
            </covered-button>
            <covered-button id="btneditDialogCancel" label="Cancel" class="p-button-outlined"
                (buttonClick)="cancelEditRecord(true)">
            </covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showEditBankInfo" header="Edit Bank Information"
    headerClass="w-full text-2xl font-medium p-4 border-bottom-1 border-black-alpha-10" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="cancelEditBank(false)" [style]="{'width': '35vw'}">
    <div body class="p-4">
        <form *ngIf="bankFormLoaded" [formGroup]="bankForm">
            <div class="flex flex-auto flex-wrap">
                <div class="w-12 pb-4">
                    <covered-form-text id="txtBankRouting" placeholder="Bank Routing Number"
                        formControlName="bankRouting">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!bankForm.controls['bankRouting'].pristine && bankForm.controls['bankRouting'].errors">
                            <p *ngIf="bankForm.controls['bankRouting'].errors?.required"> Bank Routing number is
                                required</p>
                            <p *ngIf="bankForm.controls['bankRouting'].errors?.pattern">Bank Routing must be 9 digits long and only contain numbers.</p>
                        </div>
                    </covered-form-text>
                </div>
                <div class="w-12 pb-4">
                    <covered-form-text id="txtBankAccount" placeholder="Bank Account Number"
                        formControlName="bankAccount">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!bankForm.controls['bankAccount'].pristine && bankForm.controls['bankAccount'].errors">
                            <p *ngIf="bankForm.controls['bankAccount'].errors?.required"> Bank Account number is
                                required</p>
                            <p *ngIf="bankForm.controls['bankAccount'].errors?.pattern">Bank Account must be numbers
                                only.</p>
                        </div>
                    </covered-form-text>
                </div>
                <div class="w-12 pb-4">
                    <covered-dropdown id="ddAccountType" placeholder="Select Account Type" [items]="accountList"
                        optionLabel="name" optionValue="name" [displayFirst]="false" [showClear]="false"
                        formControlName="bankAccountType">
                    </covered-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btneditDialogSave2" label="Save" (buttonClick)="finalizeEditBank();"
                [disabled]="bankForm.invalid">
            </covered-button>
            <covered-button id="btneditDialogCancel2" label="Cancel" class="p-button-outlined"
                (buttonClick)="cancelEditBank(true)">
            </covered-button>
        </div>
    </div>
</covered-dialog>