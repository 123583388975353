<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider" class="pt-4">
    <form [formGroup]="commForm" (ngSubmit)="saveComms();">
    <div class="grid">
        <div class="col-12 font-bold text-2xl">Contact Configuration</div>
        <div class="col-6">
            <covered-form-text id="txtfromEmail" placeholder="Notification From Email"
                formControlName="notificationFromEmail"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtfromDisplay" placeholder="Notification From Display Name"
                formControlName="notificationFromDisplayName"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtReplyEmail" placeholder="Notification Reply Email"
                formControlName="notificationReplyEmail"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtReplyDisplay" placeholder="Notification Reply Display Name"
                formControlName="notificationReplyDisplayName"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtContactEmail" placeholder="Contact Email"
                formControlName="contactEmail"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtSupportEmail" placeholder="Support Email"
                formControlName="supportEmail"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtContactPhone" placeholder="Contact Phone"
                formControlName="contactPhone"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtContactFax" placeholder="Contact Fax"
                formControlName="contactFax"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtPayMailAdd" placeholder="Payment Mailing Address"
                formControlName="paymentMailingAddress"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-form-text id="txtSupportHrs" placeholder="Support hours"
                formControlName="supportHours"></covered-form-text>
        </div>
        <div class="col-6">
            <covered-dropdown id="ddStartTm" placeholder="Communication Start Time (CST)" 
                [items]="times" optionLabel="display" optionValue="value"
                formControlName="commStartTime"></covered-dropdown>
        </div>
        <div class="col-6">
            <covered-dropdown id="ddEndTm" placeholder="Communication End Time (CST)" 
                [items]="times" optionLabel="display" optionValue="value"
                formControlName="commEndTime"></covered-dropdown>
        </div>
        <div class="col-1">
            Enable SMS
        </div>
        <div class="col-11">
            <p-selectButton [options]="smsOptions" optionLabel="label" optionValue="value" 
                formControlName="enableSMS"></p-selectButton>
        </div>
    </div>    
    <div *ngIf="commForm.value.enableSMS" class="grid pt-4">
        <div class="col-12 font-bold text-2xl">SMS</div>
        <div class="col-2">
            <covered-form-text id="txtApiKey" placeholder="Api Key"
                formControlName="apiKey"></covered-form-text>
        </div>
        <div class="col-2">
            <covered-form-text id="txtOrgCode" placeholder="Org Code"
                formControlName="orgCode"></covered-form-text>            
        </div>
        <div class="col-2">
            <covered-form-text id="txtOrgMobile" placeholder="Org Code Mobile"
                formControlName="orgCodeMobile"></covered-form-text>            
        </div>
        <div class="col-2">
            <covered-form-text id="txtCodeComms" placeholder="Org Code Comms"
                formControlName="orgCodeComm"></covered-form-text>            
        </div>
        <div class="col-2">
            <covered-form-text id="txt" placeholder="SBT Org Code Help"
                formControlName="orgCodeHelp"></covered-form-text>            
        </div>        
    </div>
    <div class="flex flex-row-reverse">
        <covered-button id="btnSaveComm" buttonType="submit" label="Save Communication"></covered-button>
    </div>
</form>
</div>