<span class="text-2xl font-medium">User Role List</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="cds-card w-screen-fit p-2">
        <p class="h3 m-3">Edit User Role</p>
        <div *ngIf="loading" class="m-5">
            <div class="text-2xl">Loading...</div>
            <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
        </div>
        <div class="m-3" *ngIf="!loading">
            <form [formGroup]="roleForm">
                <div class="grid m-4">
                    <div class="col-6">
                        <div class="p-3">
                            <covered-form-text id="" placeholder="Role Name" formControlName="UserTypeName" [required]="true">
                            </covered-form-text>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="p-3">
                            <covered-form-text id="" placeholder="Hierarchy Order" formControlName="HierarchyOrder" [required]="true">
                            </covered-form-text>
                        </div>
                    </div>
            
                </div>
            </form>

            <div class="mb-4">
                <p class="h5">Hierarchy</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <table class="table table-hover table-bordered table-sm mb-0">
                            <thead>
                              <tr>
                                <th scope="col">User Role</th>
                                <th scope="col">Order</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let h of userType.hierarchy">
                                <td>{{h.UserTypeName}}</td>
                                <td>{{h.hierarchyOrder}}</td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <p class="h5">Tabs Access</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="card">
                            <table class="table table-hover table-bordered table-sm mb-0">
                                <thead>
                                  <tr class="table-info">
                                    <th scope="col">#</th>
                                    <th scope="col">Tab Name</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let t of userType.tabs;let i=index;trackBy:trackByIndex;">
                                    <td>{{i+1}}</td>
                                    <td>{{t.SiteTabName}}</td>
                                    <td>
                                        <div class="d-flex flex-row">
                                            <div class="p-1">
                                                <div class="p-field-checkbox">
                                                    <p-radioButton [binary]="true" [name]="i +'allowAccess'" 
                                                    [value]="false" [(ngModel)]="userType.tabs[i].AllowAccess" label="Blocked"></p-radioButton>
                                                </div>
                                            </div>
                                            <div class="p-1">
                                                <div class="p-field-checkbox">
                                                    <p-radioButton [binary]="true" [name]="i +'allowAccess'" 
                                                    [value]="true" [(ngModel)]="userType.tabs[i].AllowAccess" label="Access"></p-radioButton>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mb-4">
                <p class="h5">Pages Access</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="card">
                            <table class="table table-hover table-bordered table-sm mb-0">
                                <thead>
                                  <tr class="table-info">
                                    <th scope="col">#</th>
                                    <th scope="col">Tab Name</th>
                                    <th scope="col">Page</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let p of userType.pages;let i=index;trackBy:trackByIndex;">
                                    <td>{{i+1}}</td>
                                    <td>{{p.SiteTabName}}</td>
                                    <td>{{p.SitePageName}}</td>
                                    <td>
                                        <div class="d-flex flex-row">
                                            <div class="p-1">
                                                <div class="p-field-checkbox">
                                                    <p-radioButton [binary]="true" [name]="i +'allowAccess'" 
                                                    [value]="false" [(ngModel)]="userType.pages[i].AllowAccess" label="Blocked"></p-radioButton>
                                                </div>
                                            </div>
                                            <div class="p-1">
                                                <div class="p-field-checkbox">
                                                    <p-radioButton [binary]="true" [name]="i +'allowAccess'" 
                                                    [value]="true" [(ngModel)]="userType.pages[i].AllowAccess" label="Access"></p-radioButton>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mb-4">
                <p class="h5">Features Access</p>
                <div class="card">
                    <table class="table table-hover table-bordered table-sm mb-0">
                        <thead>
                          <tr class="table-info">
                            <th scope="col">#</th>
                            <th scope="col">Page Name</th>
                            <th scope="col">Feature</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let f of userType.features;let i=index;trackBy:trackByIndex;">
                            <td>{{i+1}}</td>
                            <td>{{f.SitePageName}}</td>
                            <td>{{f.SiteFeatureName}}</td>
                            <td>
                                <div class="d-flex flex-row">
                                    <div class="p-1">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="access" value="hide" [(ngModel)]="userType.features[i].access" inputId="hide1" label="Hide"></p-radioButton>
                                        </div>
                                    </div>
                                    <div class="p-1">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton [disabled]="isReadDisabled(f.SiteFeatureCode)" name="access" value="read" [(ngModel)]="userType.features[i].access" inputId="read1" label="Read"></p-radioButton>
                                        </div>
                                    </div>
                                    <div class="p-1">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="access" value="write" [(ngModel)]="userType.features[i].access" inputId="write1" label="Write"></p-radioButton>
                                        </div>
                                    </div>
                                </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </div>

            </div>
            
            <div class="d-flex flex">
                <div class="m-3">
                    <covered-button id="btncs" label="SAVE" (buttonClick)="save()" [disabled]="saving">
                    </covered-button>
                </div>
                <div class="m-3">
                    <covered-button id="btncs" label="CANCEL" (buttonClick)="cancel()" class="outline" [disabled]="saving">
                    </covered-button>
                </div>
              </div>
        </div>
    </div>
</div>
