<span class="text-2xl font-medium">Create User</span>
<p-breadcrumb [model]="bcItems"></p-breadcrumb>
<div class="mt-4">
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="cds-card w-screen-fit p-2">
                <p class="h2 m-4">Create User - Step {{step}}</p>
                <div *ngIf="step == 1">
                    <form [formGroup]="stepForm1">
                        <div class="grid m-4">
                            <div class="col-6">
                                <div class="m-2">
                                    <covered-form-text id="" placeholder="First Name" formControlName="FirstName" [required]="true">
                                    </covered-form-text>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="m-2">
                                    <covered-form-text id="" placeholder="Last Name" formControlName="LastName" [required]="true">
                                    </covered-form-text>

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="m-2">
                                    <covered-form-text id="" placeholder="Email" formControlName="LoginEmail" [required]="true">
                                    </covered-form-text>

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="m-2">
                                    <covered-dropdown id="ddlol" [items]="timeZones" optionLabel="desc" placeholder="Time Zone" optionValue="id" [showClear]="false" [required]="true"
                                    formControlName="TimeZoneID">
                                </covered-dropdown>

                                </div>
                            </div>
                    
                        </div>
                    </form>
                </div>
                <div *ngIf="step == 2">
                    <form [formGroup]="stepForm2">
                        <div class="grid m-4">
                            <div class="col-12">
                                <div class="m-2">
                                    <covered-dropdown id="ddlolk" [items]="customers" optionLabel="desc" placeholder="Customer" optionValue="value" [showClear]="false" [required]="true"
                                     formControlName="CustomerID">
                                    </covered-dropdown>

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="m-2">
                                    <covered-dropdown id="ddlol" [items]="userTypes" optionLabel="desc" placeholder="User Type" optionValue="id" [showClear]="false" [required]="true"
                                    formControlName="UserTypeID">
                                </covered-dropdown>

                                </div>
                            </div>
                    
                        </div>
                    </form>
                </div>
                <div *ngIf="step == 3">
                    <form [formGroup]="stepForm3">
                        <div class="grid m-4">
                            <div class="col-12">
                                <div class="m-2">
                                    <covered-form-password id="pwd1" placeholder="Password" formControlName="Password1" [required]="true"></covered-form-password>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="m-2">
                                    <covered-form-password id="pwd2" placeholder="Re-type Password" formControlName="Password2" [required]="true"></covered-form-password>

                                </div>
                            </div>
                            <div class="col-12">
                                <div class="p-field-checkbox m-2">
                                    <p-checkbox [formControl]="stepForm3.controls['SendWelcomeEmail']" binary="true" inputId="welcome"></p-checkbox>
                                    <label for="welcome" class="ms-2"> Send welcome email</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="d-flex flex-row-reverse">
                    <div *ngIf="step == 3" class="m-3">
                        <covered-button id="btncs" label="CREATE USER" (buttonClick)="createUser()" [disabled]="saving">
                        </covered-button>
                    </div>
                    <div *ngIf="step != 3" class="m-3">
                        <covered-button id="btncs" label="NEXT" (buttonClick)="next()" [disabled]="saving">
                        </covered-button>
                    </div>
                    <div *ngIf="step != 1" class="m-3">
                        <covered-button id="btncs" label="PREVIOUS" (buttonClick)="previous()" [disabled]="saving">
                        </covered-button>
                    </div>
                    <div class="m-3">
                        <covered-button id="btncs" label="CANCEL" (buttonClick)="cancel()" [disabled]="saving">
                        </covered-button>
                    </div>
                  </div>
            </div>
        </div>
    </div>

</div>
