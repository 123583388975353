<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>


<div *ngIf="provider && showProcedure" class="pt-4">
    <div class="w-12 flex flex-row-reverse">
        <covered-button id="btnNewLocation" label="Add New" (buttonClick)="addNewProcedure();"></covered-button>
    </div>
    <div class="w-12">
        <app-custom-grid #cgProcedures DataSourceName="dsAdminProviderProducts" [rowSelectable]="true"
            [overrideCustomer]="true" [rowSelectable]="false">
        </app-custom-grid>
    </div>
</div>

<covered-dialog [isVisible]="showAddProcedure" header="Add New Procedure" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAddProcedure=false;" [style]="{'width': '50vw'}">
    <div body class="p-4">
        <form *ngIf="procedureFormLoaded" [formGroup]="procedureForm">
            <div class="grid">
                <div class="col-12">
                    <covered-form-text id="txtDesc" placeholder="Product Description" [required]="true"
                        formControlName="description"></covered-form-text>
                </div>
                <div class="col-12">
                    <covered-form-text id="txtSearch" placeholder="DE Search Value" [required]="true"
                        formControlName="searchValue"></covered-form-text>
                </div>
                <div class="col-12">
                    <covered-form-text id="txtSku" placeholder="Product SKU" [required]="true" 
                        formControlName="sku"></covered-form-text>
                </div>
                <div class="col-12">
                    <covered-input-number id="txtMSRP" placeholder="Product MSRP" [required]="true" 
                        formControlName="msrp"></covered-input-number>
                </div>
                <div class="col-12">
                    <p-checkbox [formControl]="$any(procedureForm.controls['showBop'])" [binary]="true"
                        label="Show in BOP">
                    </p-checkbox>
                </div>
            </div>
        </form>
    </div>
    <div footer class="flex flex-row-reverse">
        <covered-button id="btnSaveProcedure" [disabled]="procedureForm.invalid" (buttonClick)="saveProcedure()">
            <span class="px-2">Save</span>
        </covered-button>
    </div>
</covered-dialog>