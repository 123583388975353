<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg">
    <form *ngIf="textFormLoaded" [formGroup]="textForm" (ngSubmit)="saveTextBoxForm();">
        <div class="grid pr-4">
            <div class="col-4">
                <covered-form-text id="txtNm" placeholder="Name" formControlName="name"
                    [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-4">
                <covered-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId"
                    [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-4">
                <covered-dropdown id="ddDupOf" placeholder="Duplicate of" [items]="dupItems" appendTo="body"
                    optionLabel="name" optionValue="value" formControlName="duplicateId"
                    [showClear]="true"></covered-dropdown>
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-12">
                    <covered-form-text id="txtAcopy" placeholder="Copy" formControlName="copy"></covered-form-text>
                </div>
                <div class="w-6">
                    <covered-form-text id="txtLabel" placeholder="Label" formControlName="label"></covered-form-text>
                </div>
                <div class="w-6 pl-2">
                    <covered-form-text id="txtCss" placeholder="Css Class" formControlName="css"></covered-form-text>
                </div>
            </div>
            <div class="col-4 flex flex-wrap align-items-center">
                <div class="w-10">
                    <span class="p-float-label">
                        <p-autoComplete #acTags inputId="replTagAC" [showEmptyMessage]="true"
                            [suggestions]="replaceResults" (completeMethod)="searchTags($event)" field="fieldName"
                            styleClass="w-12" inputStyleClass="border-noround w-12" [dropdown]="false"
                            [completeOnFocus]="true" [forceSelection]="true"></p-autoComplete>
                        <label for="replTagAC">Replace Tags</label>
                    </span>
                </div>
                <div class="w-2 pl-2 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertTag()">Insert</span>
                </div>
                <div class="w-10">
                    <covered-dropdown id="ddJSFunc" placeholder="Functions" [items]="functions"
                        optionLabel="pageFunctionName" optionValue="pageFunctionID" appendTo="body"
                        (optionSelected)="functionSelected($event)"></covered-dropdown>
                </div>
                <div class="w-2 pl-2 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertFunc()">Insert</span>
                </div>
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-6">
                    <covered-dropdown id="ddDBFld" placeholder="DB Field" [items]="pageFields" optionLabel="fieldName"
                        optionValue="fieldGUID" [showClear]="true" formControlName="dbField"
                        appendTo="body"></covered-dropdown>
                </div>
                <div class="w-6 pl-2">
                    <covered-dropdown id="ddShowDBVal" placeholder="Pre-Select DB Value" [items]="dbPreSel"
                        optionLabel="name" optionValue="value" [showClear]="true" formControlName="dbPreSel"
                        appendTo="body"></covered-dropdown>
                </div>
                <div class="w-6">
                    <covered-input-number id="txtLimit" placeholder="Max Length" formControlName="charLimit"
                        mode="decimal" [useGrouping]="false"></covered-input-number>
                </div>
                <div class="w-6 pl-2">
                    <covered-input-number id="txtOrder" placeholder="Order" formControlName="order" mode="decimal"
                        [useGrouping]="false"></covered-input-number>
                </div>
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-4">
                    <covered-dropdown id="ddTM" placeholder="Text Mode" [items]="textModes" optionLabel="name"
                        optionValue="value" formControlName="textMode" appendTo="body">
                    </covered-dropdown>
                </div>
                <div class="w-4 pl-2" *ngIf="textForm.value.textMode == '0'">
                    <covered-dropdown id="ddTxtType" placeholder="Type" [items]="textTypes" optionLabel="name"
                        optionValue="value" formControlName="type" appendTo="body">
                    </covered-dropdown>
                </div>
                <div class="w-4 pl-2 align-self-center">
                    <p-checkbox [formControl]="$any(textForm.controls['encrypt'])" [binary]="true"
                        label="Encrypt"></p-checkbox>
                </div>
            </div>
            <div class="col-4 flex">
                <div class="w-6">
                    <covered-form-text id="txtErrMsg" placeholder="Error Message"
                        formControlName="errorMessage"></covered-form-text>
                </div>
                <div class="w-6 pl-2">
                    <covered-form-text id="txtNoMatchErr" placeholder="No Match Error"
                        formControlName="noMatchErr"></covered-form-text>
                </div>
            </div>
            <div class="col-4 flex">
                <div class="w-6">
                    <covered-dropdown id="ddValType" placeholder="Validation Type" [items]="validations"
                        optionValue="validationID" optionLabel="validationName" formControlName="valType"
                        appendTo="body" [showClear]="true"></covered-dropdown>
                </div>
                <div class="w-6 pl-2">
                    <covered-form-text id="txtValErr" placeholder="Validation Error"
                        formControlName="valErrMsg"></covered-form-text>
                </div>
            </div>

            <div class="col-2 align-self-center">
                <p-checkbox [formControl]="$any(textForm.controls['required'])" [binary]="true"
                    label="Required"></p-checkbox>
            </div>
            <div class="col-2 align-self-center">
                <p-checkbox [formControl]="$any(textForm.controls['disabled'])" [binary]="true"
                    label="Disabled"></p-checkbox>
            </div>
            <div class="col-4 flex">
                <div class="w-6 align-self-center">
                    <p-checkbox [formControl]="$any(textForm.controls['compVal'])" [binary]="true"
                        label="Compare To Value Validation"></p-checkbox>
                </div>
                <div class="w-6 pl-2" *ngIf="textForm.value.compVal">
                    <covered-form-text id="txtCompValValue" placeholder="Validation Value"
                        formControlName="compValValue"></covered-form-text>
                </div>
            </div>
            <div class="col-12 flex flex-wrap">
                <div class="w-3 align-self-center">
                    <p-checkbox [formControl]="$any(textForm.controls['rgxVal'])" [binary]="true"
                        label="Custom Regex Validation"></p-checkbox>
                </div>
                <div class="w-3" *ngIf="textForm.value.rgxVal">
                    <covered-form-text id="txtHdrTxt" placeholder="Header Text"
                        formControlName="headerText"></covered-form-text>
                </div>
                <div class="w-3 pl-2" *ngIf="textForm.value.rgxVal">
                    <covered-form-text id="txtHldrCss" placeholder="Holder Css Class"
                        formControlName="holderCss"></covered-form-text>
                </div>
                <div class="w-6 grid" *ngIf="textForm.value.rgxVal">
                    <div class="col-6">
                        <covered-dropdown id="ddRgxVals" placeholder="Regex Validators" [items]="regexValList"
                            optionLabel="name" optionValue="id" formControlName="regexDDVal"></covered-dropdown>
                    </div>
                    <span class="pl-2 cds-text-color cursor-pointer align-self-center "
                        (click)="addRegexValidator()">Add Validator</span>
                </div>
                <div class="w-12 p-5" *ngIf="textForm.value.rgxVal">
                    <table>
                        <tr>
                            <th></th>
                            <th>Regular Expression</th>
                            <th>Error Message</th>
                            <th>Valid Css Class</th>
                            <th>Invalid Css Class</th>
                            <th></th>
                        </tr>
                        <ng-container formArrayName="rgxVals">
                            <tr *ngFor="let rgx of textForm.controls.rgxVals.controls; index as i">
                                <ng-container [formGroupName]="i">
                                    <td>#{{rgx.value.id}}</td>
                                    <td>
                                        <covered-form-text id="txtRgxRE{{i}}"
                                            formControlName="regex"></covered-form-text>
                                    </td>
                                    <td>
                                        <covered-form-text id="txtRgxErr{{i}}"
                                            formControlName="errMsg"></covered-form-text>
                                    </td>
                                    <td>
                                        <covered-form-text id="txtRgxValCss{{i}}"
                                            formControlName="validCss"></covered-form-text>
                                    </td>
                                    <td>
                                        <covered-form-text id="txtRgxInvalCss{{i}}"
                                            formControlName="invalidCss"></covered-form-text>
                                    </td>
                                    <td class="pl-2">
                                        <i class="cursor-pointer pi pi-trash" (click)="deleteRegexVal(i)"></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12 flex flex-wrap">
                <div class="w-3">
                    <p-checkbox [formControl]="$any(textForm.controls['serverVal'])" [binary]="true"
                        label="Server Validation"></p-checkbox>
                </div>
                <div class="w-8 grid">&nbsp;</div>
                <div class="w-6 grid" *ngIf="textForm.value.serverVal">
                    <div class="col-6">
                        <covered-dropdown id="ddSvrVals" placeholder="Select Validation" [items]="serverValList"
                            optionLabel="serverValidationName" optionValue="serverValidationID"
                            formControlName="serverValDD"></covered-dropdown>
                    </div>
                    <span class="pl-2 cds-text-color cursor-pointer align-self-center "
                        (click)="addServerValidator()">Add Server Validator</span>
                </div>
                <div class="w-12 p-5" *ngIf="textForm.value.serverVal">
                    <table>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Error Message</th>
                            <th></th>
                        </tr>
                        <ng-container formArrayName="serverVals">
                            <tr *ngFor="let svr of textForm.controls.serverVals.controls; index as j">
                                <ng-container [formGroupName]="j">
                                    <td>#{{svr.value.id}}</td>
                                    <td>
                                        {{svr.value.name}}
                                    </td>
                                    <td>
                                        <covered-form-text id="txtSvrErr{{j}}"
                                            formControlName="errMsg"></covered-form-text>
                                    </td>
                                    <td class="pl-2">
                                        <i class="cursor-pointer pi pi-trash" (click)="deleteServerVal(j)"></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12 flex flex-row-reverse">
                <covered-button id="btnS" label="Save" buttonType="submit"></covered-button>
                <covered-button id="btnCncl" label="Cancel" class="px-4 outline"
                    (buttonClick)="cancelForm()"></covered-button>
            </div>
        </div>
    </form>
</div>