import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfigs } from 'src/app/configs/app-config';
import { SettlementUpload } from 'src/app/models/branch-settlement';
import { ICustomerLookups } from 'src/app/models/customer-lookups';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { ProviderSettlementFormGroup } from 'src/app/models/form-groups';
import { ProviderInfo } from 'src/app/models/provider-info';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-settlement',
  templateUrl: './provider-settlement.component.html',
  styleUrls: ['./provider-settlement.component.scss']
})
export class ProviderSettlementComponent implements OnInit {

  private _provider: CustomerProviderModel | null = null;
  @Input() set  provider(value: CustomerProviderModel | null) {
    this._provider = value;
    this.loadSettlement();
  }
  get provider(): CustomerProviderModel | null { return this._provider; }
  
  settlementForm: FormGroup<ProviderSettlementFormGroup> = {} as FormGroup;
  lookups: ICustomerLookups = {} as ICustomerLookups;
  providerInfo: ProviderInfo | null = null;
  formInStorage: boolean = false;

  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadFromStorage();
    this.setSubscriptions();

  }

  setSubscriptions() {

    this.settlementForm.valueChanges
    .subscribe(value => { 
      localStorage.setItem(AppConfigs.settlementForm, JSON.stringify(this.settlementForm.value));
    });

    let infoSub = this.providerService.infoSectionLookups$
    .subscribe({
      next: (lookups: ICustomerLookups | null) => {
        if (lookups) {
          this.lookups = lookups;          
        }
        else this.lookups = {} as ICustomerLookups;
      },
      error: (err: any) => {

      },
      complete: () => { infoSub.unsubscribe(); }
    });

    let provInfoSub = this.providerService.infoSectionProvider$
      .subscribe({
        next: (provider: ProviderInfo | null) => { 
          this.providerInfo = provider;
          if (!this.formInStorage) {
            this.loadSettlement();
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Provider information. See log for details'
          });
          console.error(err);
        },
        complete: () => { provInfoSub.unsubscribe(); }
      });
  }

  createForm() {
    this.settlementForm = new FormGroup<ProviderSettlementFormGroup>({
      facility: new FormControl<string>('0', {nonNullable: true, validators: [Validators.required]}),
      bankName: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      bankAccount: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      bankRouting: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      wireInst: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      contName: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      contPhone: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      contEmail: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
      fullAddress: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    })
  }

  loadFromStorage() {
    this.formInStorage = false;
    let storage = localStorage.getItem(AppConfigs.settlementForm);
    if (storage) {
      this.formInStorage = true;
      this.settlementForm.setValue(JSON.parse(storage));
    }    
  }

  loadSettlement() {
    if (!this.settlementForm.value) this.createForm();

    this.settlementForm.patchValue({
      facility: this.providerInfo?.settlementDetails_CustomerTrancheID?.toString() || '0',
      bankName: this.providerInfo?.settlementDetails_BankName || '',
      bankAccount: this.providerInfo?.settlementDetails_BankAccount || '',
      bankRouting: this.providerInfo?.settlementDetails_BankRouting || '',
      wireInst: this.providerInfo?.settlementDetails_BankWiringInstr || '',
      contName: this.providerInfo?.settlementDetails_ContactName || '',
      contPhone: this.providerInfo?.settlementDetails_ContactPhone || '',
      contEmail: this.providerInfo?.settlementDetails_ContactEmail || '',
      fullAddress: this.providerInfo?.settlementDetails_LockBox || ''
    });
  }

  saveSettlement() {
    let settlement: SettlementUpload = new SettlementUpload();
    settlement.bankAccount = this.settlementForm.value.bankAccount || '';
    settlement.bankName = this.settlementForm.value.bankName || '';
    settlement.routingNumber = this.settlementForm.value.bankRouting || '';
    settlement.wireInstruction = this.settlementForm.value.wireInst || '';
    settlement.contactDetailsEmail = this.settlementForm.value.contEmail || '';
    settlement.contactDetailsName = this.settlementForm.value.contName || '';
    settlement.contactDetailsPhone = this.settlementForm.value.contPhone || '';
    settlement.customerGuid = this.provider?.customerGuid || '';
    settlement.customerTrancheId = +(this.settlementForm.value.facility || 0);
    settlement.LockBoxFullAddress = this.settlementForm.value.fullAddress || '';
   
    let settleSub = this.apiService.post(`provider/settlement`, settlement)
    .subscribe({
      next: () => {
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Settlement successfully added / updated'
        });        
      },
      error: (err: any) => { 
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save settlement. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { settleSub.unsubscribe(); }
    });
  }

}
