<div *ngIf="showOPTCard">
    <span class="text-2xl font-medium">Manage Pricing</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="flex flex-row-reverse">
                <covered-button id="btnLocation" label="Location" (buttonClick)="showLocations()"></covered-button>
                <covered-button id="btnPricing" label="Pricing" class="mr-2" (buttonClick)="showPricing()">
                </covered-button>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPricing DataSourceName="dsAdminManagePricing" [rowSelectable]="true"
                    (onSelect)="offerProdTypeSelected($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showPricingCard">
    <span class="text-2xl font-medium">Pricing List</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="flex flex-row-reverse">
                <covered-button id="btnNewPricing" label="Add New" (buttonClick)="showAddPricingModal()">
                </covered-button>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPricing DataSourceName="dsAdminPricingList" [rowSelectable]="true"
                    (onSelect)="pricingSelected($event)">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showLocationCard">
    <span class="text-2xl font-medium">Manage Location</span>
    <p-breadcrumb [model]="bcItems"></p-breadcrumb>
    <div class="mt-4">
        <div class="cds-card p-4">
            <div class="flex flex-row-reverse">
                <covered-button id="btnAddPricing" label="Add Pricing" (buttonClick)="showAddLocationModal()">
                </covered-button>
            </div>
            <div class="w-12">
                <app-custom-grid #cgPricing DataSourceName="dsAdminManageLocation">
                </app-custom-grid>
            </div>
        </div>
    </div>
</div>

<covered-dialog [isVisible]="showEditOffer" header="Assign Offer Product Type" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showEditOffer=false;showCancelToast();"
    [style]="{'width': '25vw'}">
    <div body>
        <div class="w-12">
            <covered-form-text id="txtProvider" placeholder="Provider" [(ngModel)]="providerName" [disabled]="true">
            </covered-form-text>
        </div>
        <div class="w-12 pt-4">
            <covered-dropdown id="ddOffer" appendTo="body" placeholder="Offer Product Type" [items]="offerProducts"
                optionLabel="offerProductTypeDesc" optionValue="offerProductTypeGuid" [(ngModel)]="selectedOfferProdType"
                [required]="true">
            </covered-dropdown>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveOffer" label="Assign" (buttonClick)="assignOffer();" [disabled]="!selectedOfferProdType"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showEditPrice" header="{{editPriceHeader}} Offer Product Type" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showEditPrice=false;showCancelToast();"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="offerProductFormLoaded" [formGroup]="offerProductForm">
            <div class="w-12">
                <covered-form-text id="txtProduct" placeholder="Descripton" formControlName="productDesc"
                    [required]="true">
                </covered-form-text>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveOffer" (buttonClick)="addEditPricingType();" [disabled]="!offerProductForm.valid">
                <span>{{offerProdBtnTxt}}</span>
            </covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showAddBranchOffer" header="Add Pricing" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAddBranchOffer=false;showCancelToast();"
    [style]="{'width': '25vw'}">
    <div body>
        <form *ngIf="pricingFormLoaded" [formGroup]="addPricingForm">
            <div class="w-12">
                <covered-form-text id="txtAddPricingProvider" placeholder="Provider" formControlName="provider"
                    [disabled]="true">
                </covered-form-text>
            </div>
            <div class="w-12 pt-4">
                <covered-dropdown id="ddProviderBranch" appendTo="body" placeholder="Branch" [items]="ddlProvBranches"
                    optionLabel="desc" optionValue="id" formControlName="branch" [required]="true">
                </covered-dropdown>
            </div>
            <div class="w-12 pt-4">
                <covered-dropdown id="ddPriceProduct" appendTo="body" placeholder="Offer Product Type"
                    [items]="ddlOffers" optionLabel="offerProductTypeDesc" optionValue="offerProductTypeGuid"
                    formControlName="productType" [required]="true"></covered-dropdown>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnAddPricing" label="Add" (buttonClick)="addLocationPricing();"
                [disabled]="!addPricingForm.valid"></covered-button>
        </div>
    </div>
</covered-dialog>