import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PortfolioItem } from '../models/customer-item';
import { SitePageAccess, UserSiteFeature } from '../models/user-site-features';
import { ApiService } from './api.service';
import { PortfolioService } from './portfolio.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _siteFeatures = new BehaviorSubject<UserSiteFeature[]>([]);
  private userAdminPages = new BehaviorSubject<SitePageAccess[] | null>([]);
  siteFeatures$ = this._siteFeatures.asObservable();
  userAdminPages$ = this.userAdminPages.asObservable();

  portSub: Subscription | null = null;
  portfolio: PortfolioItem | null = null;
  pageAccessLoaded: boolean = false;
  siteFeaturesLoaded: boolean = false;
  constructor(
    private portfolioService: PortfolioService,
    private toastService: ToastService,
    private apiService: ApiService
  ) {
  }

  initPortSub() {
    this.portSub = this.portfolioService.portfolio$.subscribe({
      next: (p: PortfolioItem | null) => {
        this.portfolio = p;        
      },
      error: (err: any) => {
        console.error('UserService.initPortSub error: ', err);
      }
    });
  }

  async subscribeAccess() {
    if (this.portfolio) {
      this.pageAccessLoaded = false;
      this.siteFeaturesLoaded = false;
      await this.loadUserFeatures();
      await this.loadUserAdminPages();
    }
    else {
      console.error('UserService.subscribeAccess portfolio not set!');
    }

  }

  async loadUserFeatures(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`csr/user-site-features/${this.portfolio?.customerGuid}`).toPromise()
      .then((res: UserSiteFeature[]) => {
        this._siteFeatures.next(res);
        this.siteFeaturesLoaded = true;
        resolve(true);      
      }, (err: any) => {
        console.error('UserService.loadUserFeatures error: ', err);
        reject(false);
      }).catch((ex: any) => {
        console.error('UserService.loadUserFeatures exception: ', ex);
        reject(false);
      });
    });
  }

  async loadUserAdminPages(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`admin/get-pages/${this.portfolio?.customerGuid}`).toPromise()
      .then((res: SitePageAccess[]) => {
        this.userAdminPages.next(res || []);
        this.pageAccessLoaded = true;
        resolve(true);      
      }, (err: any) => {
        console.error('UserService.loadUserAdminPages error: ', err);
        reject(false);
      }).catch((ex: any) => {
        console.error('UserService.loadUserAdminPages exception: ', ex);
        reject(false);
      });
    });
  }

  hasTabAccess(tabName: string): boolean {
    if (!tabName || tabName.length == 0) return true;
    let pages = this.userAdminPages.getValue()?.filter(p => p.siteTabName.indexOf(tabName) > -1);
    if (!pages || pages.length == 0) return true;

    let allowAccess = pages.find(p => !p.allowAccess)?.allowAccess;
    return allowAccess ?? true;
  }

  hasPageAccess(pageId: number): boolean {
    if (!pageId) return false;

    let pages = this.userAdminPages.getValue();
    let item = pages?.find(item => item.sitePageID === pageId);
    if (!item) return false;

    return item.allowAccess;
  }

  hasFeature(feature: string): boolean {
      if (!feature) return false;
      var features = this._siteFeatures.getValue();
      if (!features || !features.length) return false;
      var item = features.find(item => item.code === feature);
      if (!item) return false;

      return item.allowRead || item.allowWrite;
  }

  hasRead(feature: string): boolean {
      if (!feature) return false;
      var features = this._siteFeatures.getValue();
      if (!features || !features.length) return false;
      var item = features.find(item => item.code === feature);
      if (!item) return false;

      return item.allowRead;
  }

  hasWrite(feature: string, attempts: number = 0): boolean {
      if (!feature) return false;
      var features = this._siteFeatures.getValue();
      if (!features || !features.length) return false;
      var item = features.find(item => item.code === feature);
      if (!item) return false;

      return item.allowWrite;
    }
  }