import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfigs } from 'src/app/configs/app-config';
import { CustomerProviderModel } from 'src/app/models/customer-provider-model';
import { LoanFormGroup } from 'src/app/models/form-groups';
import { ProviderInfo } from 'src/app/models/provider-info';
import { ApiService } from 'src/app/services/api.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-provider-loan',
  templateUrl: './provider-loan.component.html',
  styleUrls: ['./provider-loan.component.scss']
})
export class ProviderLoanComponent implements OnInit {

  private _provider: CustomerProviderModel | null = null;
  @Input() set provider(value: CustomerProviderModel | null) { this._provider = value; }
  get provider(): CustomerProviderModel | null { return this._provider; }

  providerInfo: ProviderInfo | null = null;
  formInStorage: boolean = false;
  
  loanForm: FormGroup<LoanFormGroup> = {} as FormGroup;
  constructor(
    private apiService: ApiService,
    private providerService: ProviderService,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadFromStorage();

    let provInfoSub = this.providerService.infoSectionProvider$
      .subscribe({
        next: (provider: ProviderInfo | null) => { 
          this.providerInfo = provider;
          if (!this.formInStorage) {
            this.loadProviderLoanApp();
          }
        },
        error: (err: any) => { 
          this.toastService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Unable to get Provider information. See log for details'
          });
          console.error(err);
        },
        complete: () => { provInfoSub.unsubscribe(); }
      });


    this.loanForm.valueChanges
    .subscribe(value => { 
      localStorage.setItem(AppConfigs.providerLoanForm, JSON.stringify(this.loanForm.value));
    });
  }

  createForm() {
    this.loanForm = new FormGroup<LoanFormGroup>({
      nlsLoanPortfolioName: new FormControl<string>('', { nonNullable: true }),
      loanTemplatePrefix: new FormControl<string>('', { nonNullable: true }),
      loanNumberPrefix: new FormControl<string>('', { nonNullable: true }),
      loanAmountTemplate: new FormControl<string>('', { nonNullable: true, validators: [Validators.required]}),
      campaignName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required]}),
      domain: new FormControl<string>('', { nonNullable: true, validators: [Validators.required]}),
      campaignCustomFolder: new FormControl<string>('', { nonNullable: true, validators: [Validators.required]}),
      startURL: new FormControl<string>('', { nonNullable: true}),
      campaignFolder: new FormControl<string>('', { nonNullable: true}),
      campaignMyAccountDomain: new FormControl<string>('', { nonNullable: true}),
      campaignInvitationPageURL: new FormControl<string>('', { nonNullable: true}),
      campaignWebInviteDomain: new FormControl<string>('', { nonNullable: true}),
      campaignMyAccountDefaultPage: new FormControl<string>('', { nonNullable: true}),
      adminDomain: new FormControl<string>('', { nonNullable: true}),
      logoutTimeout: new FormControl<number>(0, {nonNullable: true}),
      campaignMouseFlowTag: new FormControl<string|null>(null, { nonNullable: true }),
    });
  }
  
  loadFromStorage() {    
    this.formInStorage = false;
    let storage = localStorage.getItem(AppConfigs.providerLoanForm);
    if (storage) {
      this.formInStorage = true;
      this.loanForm.setValue(JSON.parse(storage));
    }    
  }

  loadProviderLoanApp() {
    this.loanForm.patchValue({
      nlsLoanPortfolioName: this.providerInfo?.nLSLoanPortfolioName,
      loanTemplatePrefix: this.providerInfo?.loanTemplatePrefix,
      loanNumberPrefix: this.providerInfo?.loanNumberPrefix,
      loanAmountTemplate: this.providerInfo?.loanAmountTemplate,
      campaignName: this.providerInfo?.campaignName,
      domain: this.providerInfo?.domain,
      campaignCustomFolder: this.providerInfo?.customerFolderName || undefined,
      startURL: this.providerInfo?.startURL,
      campaignFolder: this.providerInfo?.campaignFolder,
      campaignMyAccountDomain: this.providerInfo?.campaignMyAccountDomain || undefined,
      campaignInvitationPageURL: this.providerInfo?.campaignInvitationPageURL || undefined,
      campaignWebInviteDomain: this.providerInfo?.campaignWebInviteDomain || undefined,
      campaignMyAccountDefaultPage: this.providerInfo?.campaignMyAccountDefaultPage || undefined,
      adminDomain: this.providerInfo?.adminDomain || undefined,
      logoutTimeout: this.providerInfo?.logoutTimeout,
      campaignMouseFlowTag: this.providerInfo?.campaignMouseFlowTag
    });
  }

  saveLoanApp() { 

    let provider = {...this.providerInfo};
    provider.nLSLoanPortfolioName = this.loanForm.value.nlsLoanPortfolioName;
    provider.loanTemplatePrefix = this.loanForm.value.loanTemplatePrefix;
    provider.loanNumberPrefix = this.loanForm.value.loanNumberPrefix;
    provider.loanAmountTemplate = this.loanForm.value.loanAmountTemplate;
    provider.campaignName = this.loanForm.value.campaignName;
    provider.domain = this.loanForm.value.domain;
    provider.customerFolderName = this.loanForm.value.campaignCustomFolder;
    provider.startURL = this.loanForm.value.startURL;
    provider.campaignFolder = this.loanForm.value.campaignFolder;
    provider.myAccountDomain = this.loanForm.value.campaignMyAccountDomain;
    provider.campaignInvitationPageURL = this.loanForm.value.campaignInvitationPageURL;
    provider.campaignWebInviteDomain = this.loanForm.value.campaignWebInviteDomain;
    provider.campaignMyAccountDefaultPage = this.loanForm.value.campaignMyAccountDefaultPage;
    provider.adminDomain = this.loanForm.value.adminDomain;
    provider.campaignMouseFlowTag = this.loanForm.value.campaignMouseFlowTag || undefined;
    provider.logoutTimeout = this.loanForm.value.logoutTimeout;

    let loanSub = this.apiService.post('provider/loan-app', provider)
    .subscribe({
      next: () => { 
        this.toastService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Provider Loan App successfully updated.'
        });
        this.providerService.loadInfoSectionProvider((this.provider?.customerGuid || ''));
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to save Provider Loan App. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { loanSub.unsubscribe(); }
    });
  }
}
