<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>

<div *ngIf="!loading && info && !hasError" class="flex flex-wrap flex-auto">
    <div *ngIf="GridTitle && GridTitle.length > 0" class="col-12 text-2xl">{{ GridTitle }}</div>    
    <div class="w-full pt-4">
        <covered-table 
            id="customGridTbl{{Id}}"
            [data]="data" 
            [globalFilterFields]="gridSearchFields"
            [showCurrentPageReport]="true" 
            [showColToggle]="info.allowHideColumns"
            [allowCustomCRUD]="allowCustomCRUD"
            [customCRUDTitle]="customCRUDTitle"
            [customCRUDClass]="customCRUDClass"
            [columns]="gridColumns"
            [rows]="rows"
            [allowDownload]="info.allowDownloads"
            [allowSearch]="info.allowSearch"
            [allowCopy]="allowCopy"
            [allowEdit]="info.allowEdit" 
            [allowDelete]="info.allowDelete"
            [allowRowSelect]="rowSelectable"
            [allowCustomCRUD]="allowCustomCRUD"
            [customCRUDClass]="customCRUDClass"
            [customCRUDTitle]="customCRUDTitle"
            (customCRUDRow)="customCRUDSelect.emit($event)"
            (rowClicked)="rowSelected($event)"
            (columnClicked)="columnSelect.emit($event)"
            (editRow)="editRow.emit($event)"
            (copyRow)="copyRow.emit($event)"
            (deleteRow)="confirmDelete($event)">
            <div *ngIf="showCustomCaption" caption>
                <ng-content select="[caption]"></ng-content>
            </div>
            <div *ngIf="showCustomHeader" header>
                <ng-content select="[header]"></ng-content>
            </div>
        </covered-table>        
    </div>
</div>

<div *ngIf="!loading && hasError" class="text-5xl font-medium text-red-500">
    Unable to load Data.
</div>

<p-confirmDialog key="customGrid{{Id}}" [style]="{width: '45vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>