<div *ngIf="!provider" class="pt-4">
    Please select or create Provider before updating this section.
</div>

<div *ngIf="provider && showBranchList" class="pt-4">
    <div class="w-12 flex flex-row-reverse">
        <covered-button id="btnNewLocation" label="Add Location" (buttonClick)="addNewLocation();"></covered-button>
        <covered-button id="btnAllSettlements" label="All Settlements" class="mr-3" (buttonClick)="showAllSettlements();"></covered-button>
        <covered-button *ngIf="allyEnabled" id="btnAllySites" label="Get Ally Locations" class="mr-3" 
            (buttonClick)="getAllyLocations();" [disabled]="btnAllyLocationsDisabled"></covered-button>
    </div>
    <div class="w-12">
        <app-custom-grid #cgBranches 
            DataSourceName="dsAdminCustomerBranches" 
            [rowSelectable]="true"
            [overrideCustomer]="true" 
            [allowCustomCRUD]="true"
            customCRUDClass="pi pi-dollar"
            customCRUDTitle="Edit Settlement detail"
            (customCRUDSelect)="rowSelected($event)"
            (onSelect)="editLocation($event)" 
            (editRow)="editLocation($event)">
        </app-custom-grid>
    </div>
</div>

<div *ngIf="provider && showSettlementsTable">
    <div class="w-12 pt-4">
        Provider: <span class="font-bold cursor-pointer" (click)="backToBranchList()">{{provider.customerName}}</span>
    </div>
    <div class="w-12">
        <covered-table
            [data]="customerSettlements"
            [rows]="100"
            [globalFilterFields]="settlementFilterFields"
            [columns]="settlementColumns"
            [allowRowSelect]="false"
            [allowDelete]="false"
            (editRow)="editSettlementRecord($event)">
        </covered-table>
    </div>
</div>

<covered-dialog [isVisible]="showBranch" header="{{branchTitle}} Location" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showBranch=false;" [style]="{'width': '75vw'}">
    <div body class="p-4">
        <form *ngIf="branchFormLoaded" [formGroup]="branchForm">
            <div class="grid">
                <div class="col-12">
                    <div class="w-6">
                        <covered-dropdown id="ddCountry" placeholder="Country" [items]="lookups.countries"
                            optionLabel="desc" optionValue="id" [showClear]="false" [required]="true" appendTo="body"
                            formControlName="country" (optionSelected)="toggelShowState($event);">
                        </covered-dropdown>
                    </div>
                </div>
                <div class="col-6">
                    <div class="w-12">
                        <covered-dropdown id="ddRegions" placeholder="Region" [items]="providerRegions"
                            optionLabel="providerRegionDesc" optionValue="providerRegionID" [showClear]="false"
                            appendTo="body" formControlName="region" (optionSelected)="setSubRegions($event);">
                        </covered-dropdown>
                    </div>
                    <div class="w-12">
                        <covered-button id="btnNewRegion" label="Add" class="p-button-sm"
                            (buttonClick)="openRegionAdd();"></covered-button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="w-12">
                        <covered-dropdown id="ddSubRegions" placeholder="SubRegion" [items]="subRegions"
                            optionLabel="providerSubRegionDesc" optionValue="providerSubRegionID" [showClear]="false"
                            appendTo="body" formControlName="subRegion">
                        </covered-dropdown>
                    </div>
                    <div class="w-12">
                        <covered-button id="btnNewSubRegion" label="Add" class="p-button-sm"
                            (buttonClick)="openSubRegionAdd();"></covered-button>
                    </div>
                </div>
                <div class="col-6 pt-4">
                    <covered-form-text id="txtLocationId" placeholder="Location Id" formControlName="locationId"
                        [required]="true">
                    </covered-form-text>
                </div>
                <div class="col-6 pt-4">
                    <covered-form-text id="txtLocationName" placeholder="Location Name" formControlName="locationName"
                        [required]="true">
                    </covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtAddress1" placeholder="Address" formControlName="address1"
                        [required]="true">
                    </covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtAddress2" placeholder="Address 2" formControlName="address2">
                    </covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtCity" placeholder="City" formControlName="city"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-dropdown *ngIf="showStateDD" id="ddState" placeholder="State" [items]="states"
                        optionLabel="name" optionValue="abbr" [showClear]="false" [required]="true"
                        formControlName="state">
                    </covered-dropdown>
                    <covered-form-text *ngIf="!showStateDD" id="txtState" placeholder="State / Province / Region"
                        [required]="true" formControlName="state">
                    </covered-form-text>
                </div>
                <div class="col-12">
                    <div class="w-6">
                        <covered-form-text id="txtZip" placeholder="Zip" formControlName="zip" [required]="true">
                        </covered-form-text>
                    </div>
                </div>
                <div class="col-12">
                    <div class="w-6">
                        <covered-form-text id="txtContact" placeholder="Contact" formControlName="contactName"
                            [required]="true"></covered-form-text>
                    </div>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtPhone" placeholder="Phone" formControlName="contactPhone"
                        [required]="true"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtEmail" placeholder="Email" formControlName="contactEmail"
                        [required]="true"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtWebsite" placeholder="Website" formControlName="website">
                    </covered-form-text>
                </div>
                <div class="col-6" *ngIf="providerInfo?.isAllyEnabled">
                    <covered-form-text id="txtAllyOfcId" placeholder="Ally Office Id" formControlName="allyOfficeId" [required]="true">
                        <div class="w-12 my-1 p-error text-xs"
                            *ngIf="!branchForm.controls.allyOfficeId.pristine && branchForm.controls.allyOfficeId.errors">
                            <p *ngIf="branchForm.controls.allyOfficeId.errors?.required">Ally Office Id is required.</p>
                            <p *ngIf="branchForm.controls.allyOfficeId.errors?.maxlength">Ally Office Id must be 100 characters or less.</p>
                        </div>
                    </covered-form-text>
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveBranch" label="Save" (buttonClick)="saveBranch();" [disabled]="branchForm.invalid"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showAddRegion" header="Add New Region" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAddRegion=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="w-12">
            <covered-form-text id="txtNewRegion" placeholder="Region" [required]="true" [(ngModel)]="newRegion">
            </covered-form-text>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveRegion" label="Save" (buttonClick)="saveRegion();" [disabled]="!newRegion"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showAddSubRegion" header="Add New Subregion" headerClass="text-2xl" [showHeader]="true"
    [isModal]="true" [isClosable]="true" (HideClick)="showAddSubRegion=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="w-12">
            <covered-form-text id="txtNewSubRegion" placeholder="Subregion" [required]="true"
                [(ngModel)]="newSubRegion"></covered-form-text>
        </div>
    </div>
    <div footer>
        <div class="flex flex-row-reverse">
            <covered-button id="btnSaveSubregion" label="Save" (buttonClick)="saveSubregion();" [disabled]="!newSubRegion"></covered-button>
        </div>
    </div>
</covered-dialog>

<covered-dialog [isVisible]="showSettlement" header="{{branchSettlementTitle}} Branch Settlement" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showSettlement=false;"
    [style]="{'width': '75vw'}">
    <div body class="p-4">
        <form *ngIf="settlementFormLoaded" [formGroup]="branchSettlementForm">
            <div class="grid">
                <div class="col-12">
                    <covered-form-text id="txtLocation" placeholder="Location" [required]="true"
                        formControlName="location" [disabled]="true"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtBankName" placeholder="Bank Name" [required]="true"
                        formControlName="bankName"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtAccountName" placeholder="Account Name" [required]="true"
                        formControlName="accountName"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtBankAccount" placeholder="Bank Account" [required]="true"
                        formControlName="bankAccount"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtAccountType" placeholder="Bank Account Type" [required]="true"
                        formControlName="bankAccountType"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtBankRouting" placeholder="Bank Routing" [required]="true"
                        formControlName="bankRouting"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtWireInst" placeholder="Bank Wiring Instr." [required]="true"
                        formControlName="bankWireInst"></covered-form-text>
                </div>
                <div class="col-12">
                    <covered-form-text id="txtContactName" placeholder="Contact Name" [required]="true"
                        formControlName="contactName"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtContactPhone" placeholder="Contact Phone" [required]="true"
                        formControlName="contactPhone"></covered-form-text>
                </div>
                <div class="col-6">
                    <covered-form-text id="txtContactEmail" placeholder="Contact Email" [required]="true"
                        formControlName="contactEmail"></covered-form-text>
                </div>
                <div class="col-12">
                    <covered-form-text id="txtLockbox" placeholder="Lockbox" [required]="true"
                        formControlName="lockbox"></covered-form-text>
                </div>
            </div>
        </form>
    </div>
    <div footer class="flex flex-row-reverse">
        <covered-button id="btnSaveSettlement" [disabled]="branchSettlementForm.invalid" (buttonClick)="saveSettlement()">
            <span class="px-2">Save Settlement</span>
        </covered-button>
    </div>
</covered-dialog>