<div *ngIf="emptyDataMsg">
    <div class="text-2xl cds-error-text">ERROR! Unable to find data.</div>
</div>

<div *ngIf="!emptyDataMsg && radioFormLoaded">
    <form *ngIf="radioFormLoaded" [formGroup]="radioForm">
        <div class="grid pr-4">
            <div class="col-4">
                <covered-form-text id="txtNm" placeholder="Name" formControlName="name"
                    [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-4">
                <covered-form-text id="txtObjId" placeholder="Object ID" formControlName="objectId"
                    [disabled]="data.versionId != 0"></covered-form-text>
            </div>
            <div class="col-4">
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-6">
                    <covered-form-text id="txtLabel" placeholder="Label" formControlName="label"></covered-form-text>
                </div>
                <div class="w-6 pl-2">
                    <covered-form-text id="txtCss" placeholder="Css Class" formControlName="css"></covered-form-text>
                </div>
            </div>
            <div class="col-4 flex flex-wrap align-items-center">
                <div class="w-10">
                    <span class="p-float-label">
                        <p-autoComplete #acTags inputId="replTagAC" [showEmptyMessage]="true"
                            [suggestions]="replaceResults" (completeMethod)="searchTags($event)" field="fieldName"
                            styleClass="w-12" inputStyleClass="border-noround w-12" [dropdown]="false"
                            [completeOnFocus]="true" [forceSelection]="true"></p-autoComplete>
                        <label for="replTagAC">Replace Tags</label>
                    </span>
                </div>
                <div class="w-2 pl-2 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertTag()">Insert</span>
                </div>
                <div class="w-10">
                    <covered-dropdown id="ddJSFunc" placeholder="Functions" [items]="functions"
                        optionLabel="pageFunctionName" optionValue="pageFunctionID" appendTo="body"
                        (optionSelected)="functionSelected($event)"></covered-dropdown>
                </div>
                <div class="w-2 pl-2 flex">
                    <span class="cds-text-color cursor-pointer" (click)="insertFunc()">Insert</span>
                </div>
            </div>
            <div class="col-4 flex flex-wrap">
                <div class="w-6">
                    <covered-dropdown id="ddDBFld" placeholder="DB Field" [items]="pageFields" optionLabel="fieldName"
                        optionValue="fieldGUID" [showClear]="true" formControlName="dbField"
                        appendTo="body"></covered-dropdown>
                </div>
                <div class="w-6 pl-2">
                    <covered-dropdown id="ddShowDBVal" placeholder="Pre-Select DB Value" [items]="dbPreSel"
                        optionLabel="name" optionValue="value" [showClear]="true" formControlName="dbPreSel"
                        appendTo="body"></covered-dropdown>
                </div>
                <div class="w-6">
                    <covered-input-number id="txtLimit" placeholder="Max Length" formControlName="charLimit"
                        mode="decimal" [useGrouping]="false"></covered-input-number>
                </div>
                <div class="w-6">
                    <covered-input-number id="txtOrder" placeholder="Order" formControlName="order" mode="decimal"
                        [useGrouping]="false"></covered-input-number>
                </div>
            </div>
            <div class="col-4 flex">
                <div class="w-6">
                    <covered-form-text id="txtErrMsg" placeholder="Error Message"
                        formControlName="errorMessage"></covered-form-text>
                </div>
            </div>
            <div class="col-4 flex">
                <div class="w-6">
                    <covered-dropdown id="ddValType" placeholder="Validation Type" [items]="validations"
                        optionValue="validationID" optionLabel="validationName" formControlName="valType"
                        appendTo="body" [showClear]="true"></covered-dropdown>
                </div>
                <div class="w-6 pl-2">
                    <covered-form-text id="txtValErr" placeholder="Validation Error"
                        formControlName="valErrMsg"></covered-form-text>
                </div>
            </div>

            <div class="col-2 align-self-center">
                <p-checkbox [formControl]="$any(radioForm.controls['required'])" [binary]="true"
                    label="Required"></p-checkbox>
            </div>
            <div class="col-2 align-self-center">
                <p-checkbox [formControl]="$any(radioForm.controls['disabled'])" [binary]="true"
                    label="Disabled"></p-checkbox>
            </div>
            <div class="col-12 flex flex-wrap">
                <div class="w-2">
                    <p-checkbox [formControl]="$any(radioForm.controls['horizontal'])" [binary]="true"
                        label="Horizontal"></p-checkbox>                    
                </div>
                <div class="w-2 align-self-center text-align-end">
                    <span class="cursor-pointer cds-text-color" (click)="addRdoItems()">Add Items</span>
                </div>
                <div class="w-1 pl-2">
                    <covered-dropdown id="ddAddNum" placeholder="Num" [items]="addItemsCount" optionLabel="text"
                        optionValue="value" formControlName="addItmCnt">
                    </covered-dropdown>
                </div>                
                <div class="w-12 p-5">
                    <table>
                        <tr>
                            <th></th>
                            <th>Text</th>
                            <th>Value</th>
                            <th>Go To Page</th>
                            <th>Select</th>
                            <th></th>
                        </tr>
                        <ng-container formArrayName="radios">
                            <tr *ngFor="let rdo of radioForm.controls.radios.controls; index as i">
                                <ng-container [formGroupName]="i">
                                    <td>#{{rdo.value.id}}</td>
                                    <td class="pr-4">
                                        <covered-form-text id="txt{{i}}"
                                            formControlName="text"></covered-form-text>
                                    </td>
                                    <td class="pr-4">
                                        <covered-form-text id="txtVal{{i}}"
                                            formControlName="val"></covered-form-text></td>
                                    <td class="pr-4">
                                        <covered-dropdown id="goTo{{i}}" placeholder="Select" [items]="pages"
                                            optionLabel="pageName" optionValue="pageID" formControlName="npid"
                                            appendTo="body" [showClear]="true">
                                        </covered-dropdown>
                                    </td>
                                    <td class="pr-4">
                                        <p-radioButton [value]="true" formControlName="sel"
                                            (onClick)="rdoItemSelChanged($event, i)" class="mr-7"></p-radioButton>
                                        </td>
                                    <td>
                                        <i class="cursor-pointer pi pi-trash" (click)="deleteRdoItem(i)"></i>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12 flex flex-row-reverse">
                <covered-button id="btnS" label="Save" (buttonClick)="saveRdoForm()"></covered-button>
                <covered-button id="btnCncl" label="Cancel" class="px-4 outline" (buttonClick)="cancelForm()"></covered-button>
            </div>
        </div>
    </form>
</div>